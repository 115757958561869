import { ObjectAny } from "../utilities/interfaces"
import { relative_duration } from "../utilities/methods";

export enum WorkerClass {
  Standard = "standard",
  Reserved = "reserved",
  SelfHosted = "self-hosted",
}

export enum WorkerStatus {
	Paused = "paused",
	Queued = "queued",
	Provisioning = "provisioning",
	Starting = "starting",
	Online = "online",
	Offline = "offline",
	TimedOut = "timed_out",
}

export enum WorkerProvider {
  Hetzner = "hetzner",
	DigitalOcean = "digital_ocean",
	Kamatera = "kamatera",
	Vultr = "vultr",
	Linode = "linode",
	Self = "self",
}

export enum WorkerRegion {
	Any = "",
  Australia = "australia", // Australia
  Brazil = "brazil", // Brazil
  Canada = "canada", // Canada
  France = "france", // France
  Germany = "germany", // Germany
  India = "india", // India
  Mexico = "mexico", // Mexico
  Singapore = "singapore", // Singapore
  SouthKorea = "south-korea", // South Korea
  Spain = "spain", // Spain
  Sweden = "sweden", // Sweden
  UnitedKingdom = "united-kingdom", // United Kingdom
  UnitedStatesCentral = "united-states-central", // United States (Central)
  UnitedStatesEast = "united-states-east", // United States (East)
  UnitedStatesWest = "united-states-west", // United States (West)
}

export const RegionMap : { [key: string]: string; } = {
  'australia': 'Australia',
  'brazil': 'Brazil',
  // 'canada': 'Canada',
  // 'france': 'France',
  'germany': 'Germany',
  'india': 'India',
  // 'mexico': 'Mexico',
  'singapore': 'Singapore',
  // 'south-korea': 'South Korea',
  // 'spain': 'Spain',
  // 'sweden': 'Sweden',
  'united-kingdom': 'United Kingdom',
  // 'united-states-central': 'United States (Central)',
  'united-states-east': 'United States (East)',
  'united-states-west': 'United States (West)',
}

export type WorkerMap = { [key: string]: Workr; }

export class Workr {
  id: string
  status: WorkerStatus
  class: WorkerClass
  provider: WorkerProvider
  machine_id: string
  region: WorkerRegion
  error?: string
  token: string
  last_heartbeat: number
  project_id?: string
  connect_time: string
  version: string
  hostname: string
  public_ip: string
  active_executions: number
  cpu_count: number
  cpu_pct: number
  ram_pct: number
  ram_total: number
  reconnects: number
  
  constructor(data: ObjectAny = {}) {
    this.id = data.id
    this.status = data.status
    this.class = data.class
    this.provider = data.provider
    this.machine_id = data.machine_id
    this.region = data.region
    this.error = data.error
    this.token = data.token
    this.last_heartbeat = data.last_heartbeat
    this.project_id = data.project_id
    this.connect_time = data.connect_time
    this.version = data.version
    this.hostname = data.hostname
    this.public_ip = data.public_ip
    this.active_executions = data.active_executions
    this.cpu_count = data.cpu_count
    this.cpu_pct = data.cpu_pct
    this.ram_pct = data.ram_pct
    this.ram_total = data.ram_total
    this.reconnects = data.reconnects
  }
  get last_heartbeat_date() {
    if(this.last_heartbeat) {
      return new Date(this.last_heartbeat * 1000).toLocaleTimeString()
    }
    return '-'
  }
  get last_heartbeat_relative() {
    if(this.last_heartbeat) {
      return relative_duration(new Date(this.last_heartbeat * 1000))
    }
    return '-'
  }
}

export interface WorkerHeartbeat {
	id: number;
	worker_id: string;
	hostname: string;
	public_ip: string;
	active_executions: number;
	row_rate: number;
	cpu_count: number;
	cpu_pct: number;
	cpu_time: number;
	ram_pct: number;
	ram_rss: number;
	ram_total: number;
	disk_pct: number;
	disk_free: number;
	tx_bytes: number;
	rc_bytes: number;
	timestamp: number;
}