import { none, State } from "@hookstate/core";
import _ from "lodash";
import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { sling, useQuery } from "../App";
import { ExecutionDialog } from "../components/ExecutionDialog";
import { Message, MessageBatcher, MessageType } from "../core/api/ws";
import { ExecStatus, Execution, ExecutionHeartbeat, ExecutionStatus, toBytes } from "../core/execution";
import { useHookState, useVariable } from "../core/store";
import { get_duration, itemsFiltered, jsonClone, relative_duration, setFilterRefresh, toastInfo, useIsMounted, useWindowFocus } from "../utilities/methods";
import Chart from "react-apexcharts";
import { getDurationChartOptions, DurationRecord } from "../core/stats";
import { ObjectAny } from "../utilities/interfaces";
import { Tooltip } from 'primereact/tooltip';
import { ProgressBar } from "primereact/progressbar";
import { Link, useHistory } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { Mutex } from 'async-mutex';
import { ReplicationJobDialog } from "../components/ReplicationJobDialog";
import { rudderstack, rudderTrack } from "..";

interface Props {
  loading: State<boolean>;
  refresh: State<number>;
}


export const HistoryView: React.FC<Props> = (props) => {
  const query = useQuery()
  const history = useHistory()
  const loading = useHookState(false)
  const live = useHookState(true)
  const executions = useHookState<Execution[]>([])
  const currExecution = useHookState(new Execution())
  const showExecution = useHookState(false)
  const filter = useHookState('');
  const durationChartRef = React.useRef(null)
  const durationChartOptions = useVariable<ObjectAny>(getDurationChartOptions(false)) 
  const durationChartSeries = useVariable<any[]>([])
  const jobDialog = useHookState({show: false, name: '', replicationId: 0})
  const batcher = React.useRef(new MessageBatcher())
  const mutex = React.useRef(new Mutex())
  const isMounted = useIsMounted()
  const windowFocus = useWindowFocus(60*10)
  const refreshDebounce = React.useRef<any>(null)

  ///////////////////////////  HOOKS  ///////////////////////////
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    document.title = 'Sling - History'
    rudderstack.page(document.title, '', sling.rudderProperties())
    let view_filter = sling.state.temp.history_view_filter?.get()
    if(view_filter) {
      document.getElementById("history-search")?.setAttribute('value', view_filter)
      filter.set(view_filter)
      sling.state.temp.history_view_filter.set('') // clear
    }

    // check query params 
    let exec_id = query.get('eid')
    let pid = query.get('pid')

    if(pid && !sling.checkProject(pid)) return 
    if(exec_id) {
      showExecutionDialog(parseInt(exec_id))
      sling.state.temp.exec_id.set(none)
      history.push('/history') // clear query params
    }
    // set debounce
    refreshDebounce.current = _.debounce(() => {
      refreshExecutions()
      refreshCharts()
    }, 400)
  }, []); // eslint-disable-line

  React.useEffect(() => { 
    refreshDebounce.current()
    
    if(live.get()) {
      sling.wsCallbacks.history = processWsMessage
      return () => {
        delete sling.wsCallbacks.history
      }
    }
  }, [live.get()]); // eslint-disable-line

  React.useEffect(() => { 
    if(windowFocus && live.get()) {
      // disabled for now, excessive CPU
      // reconnect if window is refocused
      // sling.connectWebsocket().then(
      //   reconnected => {
      //     if(reconnected && isMounted.current) {
      //       refreshDebounce.current()
      //     }
      //   }
      // )
      refreshDebounce.current()
    } 
  }, [windowFocus]); // eslint-disable-line
  
  const refreshExecutions = async () => {
    loading.set(true)
    await mutex.current.runExclusive(async () => {
      let where : string[] = ['replication_not_null']
      where = where.concat(filter.get() ? ['execution_matches', `%${filter.get()}%`] : [])
      let execs = await sling.loadExecutions({ where, last: 200 })
      execs = _.orderBy(execs, ['rank', 'id'], ['asc', 'desc'])
      execs = execs.filter(e => e.job_name !== '-') // exclude executions tied to deleted jobs
      if(!isMounted.current) return
      executions.set(execs)
    })
    if(!isMounted.current) return
    loading.set(false)
    setTimeout(() => {
      sling.connectWebsocket() // reconnect in case of disconect
    }, 200);
  }

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const processWsMessage = async (msg: Message) => {
    const process = async () => {
      if(!isMounted.current) return
      await processWsMessageHistory(executions, batcher.current.drain(500))
    }

    batcher.current.add(msg)
    mutex.current.runExclusive(process)

    // takes care of remnant messages
    setTimeout(() => { mutex.current.runExclusive(process) }, 500);

    if(msg.type === MessageType.ExecutionHeartbeat) {
      let ehb = msg.data as ExecutionHeartbeat
      if (ehb.end_time) refreshCharts()
    }
  }

  const executionsFiltered = () => {
    return itemsFiltered<Execution>(
      Object.values(executions.get()),
      filter.get(),
      (v) => new Execution(v)
    )
  }

  const showExecutionDialog = async (id: number) => {
    let execs = await sling.loadExecutions({ids: [id]})
    if(execs.length === 0) return
    currExecution.set(new Execution(jsonClone(execs[0])))
    showExecution.set(true)
  }

  const refreshCharts = async () => {
    let durationRecords = await sling.getStats('duration', 'minute', 60) as DurationRecord[]

    let task_duration_map : ObjectAny = {}
    for(let record of durationRecords) {
      if(!(record.task_name in task_duration_map)) {
        task_duration_map[record.task_name] = []
      }
      task_duration_map[record.task_name].push({
        id: record.id,
        runs: [
          record.start_time * 1000,
          record.end_time * 1000,
        ],
      })
    }
    
    durationChartSeries.set(Object.keys(task_duration_map)
    .map(task_name => {
      return {
        name: task_name,
        data: task_duration_map[task_name].map((data: any) => {
          return {
            x: 'Run',
            y: data.runs,
            onClick: () => showExecutionDialog(data.id),
          }
        })
      }
    }))
    const durationChart : any = durationChartRef.current

    let hasData = Object.keys(task_duration_map).length > 0
    durationChartOptions.set(getDurationChartOptions(hasData))
    durationChart?.render()
  }

  ///////////////////////////  JSX  ///////////////////////////
  
  const statusBody = (execution: Execution, column: ColumnBodyOptions) => {
    return <span className={'status ' + execution.status}> {execution.status} </span>
  }

  const actionTemplate = (execution: Execution, column: ColumnBodyOptions) => {
    return <div>
          {
            execution.is_running() ?

            <Button
              type="button"
              disabled={!sling.userAtLeastPower}
              icon="pi pi-times"
              className="p-button-danger p-button-sm small-button"
              tooltip="Terminate Execution"
              tooltipOptions={{position: 'top'}}
              style={{ marginRight: '.5em' }}
              onClick={async () => {
                await sling.terminateExecution(execution.id)
                refreshDebounce.current()
                rudderTrack('history', 'task-terminate', sling.rudderProperties({execution_id: execution, stream_id: execution.stream_id}))
              }}
            />
            :
            <Button
              type="button"
              disabled={!sling.userAtLeastPower}
              icon="pi pi-play"
              className="p-button-success p-button-sm small-button"
              tooltip="Execute"
              tooltipOptions={{position: 'top'}}
              style={{ marginRight: '.9em' }}
              onClick={async () => {
                if(await sling.executeJob(execution.stream_id)) {
                  toastInfo(`Triggered "${execution.job_name}"`)
                }
                refreshDebounce.current()
                rudderTrack('history', 'task-execute', sling.rudderProperties({execution_id: execution, stream_id: execution.stream_id}))
              }}
            />
          }
          <Button
            type="button"
            icon="pi pi-eye"
            className="p-button-info p-button-sm small-button"
            tooltip="View Log"
            tooltipOptions={{position: 'top'}}
            style={{ marginRight: '.5em' }}
            onClick={async () => { showExecutionDialog(execution.id) }}
          />
      </div>;
  }
  
  const nameBody = (execution: Execution, column: ColumnBodyOptions) => {
    const cell_id = `exec-name-${execution.id}`

    return <>
      {nameBodyTooltip(execution, cell_id)}
      <a
        href={window.location.href}
        onClick={async () => {
          jobDialog.name.set(execution.job_name)
          jobDialog.replicationId.set(execution.replication_id)
          await sling.loadReplications(execution.replication_id)
          jobDialog.show.set(true)
        }}
      >
        <span id={cell_id} style={{overflowX: "scroll"}}> {execution.job_name} </span>
      </a>
    </>
  }

  return (
    <>
      {
        showExecution.get() ?
        <ExecutionDialog
          show={showExecution}
          execution={currExecution}
        />
        :
        null
      }

      {
        jobDialog.show.get() ?
        <ReplicationJobDialog
          show={jobDialog.show}
          name={jobDialog.name}
          detached={true}
          replication={sling.state.replications[jobDialog.replicationId.get()]}
        />
        : null
      }

      <div
        className="grid"
        style={{
          maxHeight: `${window.innerHeight - 70}px`,
          overflowY: 'scroll',
        }}
      >
        {/* HEADER */}
        <div className="col-4"/>
        <div className="col-4">
          <h2 style={{ textAlign: "center", marginBottom: 0 }}> {'HISTORY'}</h2>
        </div>
        <div className="col-4 flex justify-content-end align-content-center">
          <div style={{
            paddingTop: '5px',
            paddingLeft: '7px',
            paddingRight: '7px',
          }}>             
            <InputSwitch
              checked={ live.get() }
              tooltip="Live Mode (Auto-Refresh)"
              tooltipOptions={{position: 'left'}}
              onChange={async (e) => { live.set(e.value) }}
            />
          </div>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                id='history-search'
                className="p-inputtext-sm"
                onChange={(e:any) => {
                  setFilterRefresh(filter, e.target.value, refreshExecutions)
                }}
                onKeyDown={(e) => {
                  if(e.key === 'Escape') { filter.set('') } 
                }}
                placeholder="Search"
                size={15}
              />
              <Button
                icon={loading.get() ? 'pi pi-spin pi-spinner' :"pi pi-refresh"}
                className="p-button-info p-button-sm"
                style={{marginLeft: '-40px'}}
                onClick={async (e) => { 
                  filter.set('')
                  refreshDebounce.current()
                }}
              />
              {
                executions.get().filter(e => e.status === ExecStatus.Queued).length > 1 ?
                <Button
                  icon={loading.get() ? 'pi pi-spin pi-spinner' :"pi pi-times"}
                  className="p-button-danger p-button-sm"
                  tooltip="Terminate all queued items"
                  tooltipOptions={{position: 'top'}}
                  style={{marginLeft: '-0px'}}
                  onClick={async (e) => { 
                    loading.set(true)
                    let promises : Promise<boolean>[] = []
                    let execs = executions.get().filter(e => e.status === ExecStatus.Queued)
                    for(let exec of execs) promises.push(sling.terminateExecution(exec.id))
                    for(let promise of promises) await promise
                    loading.set(false)
                    refreshDebounce.current()
                  }}
                />
                  : null
              }
          </span>
        </div>
      
        {/* TODO: HISTORY of EXECUTIONS, REUSE */}

        <div className="col-12" style={{marginBottom: '-15px'}}>
          <div className="card" style={{
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 15,
          }} >
            <Chart
              ref={durationChartRef}
              type="rangeBar"
              height='155px'
              series={durationChartSeries.get()}
              options={durationChartOptions.get()}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <DataTable
              className="history-table"
              value={executionsFiltered()} 
              // header={ header()}
              loading={loading.get()}
              responsiveLayout="scroll"
              scrollable
              scrollHeight={`${window.innerHeight - 290 - 170}px`}
              dataKey="id"
              emptyMessage="No records found."
              style={{fontSize: '12px'}}
              lazy
            >
              <Column body={replicationBody} style={{overflowX: 'hidden'}} className="flex justify-content-center column-wrap" header="Replication" sortable filter />
              <Column body={nameBody} style={{overflowX: 'hidden'}} className="flex justify-content-center column-wrap" field="job_name" header="Name" sortable filter />
              <Column body={dateBody} headerStyle={{maxWidth: '15em'}} bodyStyle={{maxWidth: '15em'}} className="flex justify-content-center" field="start_date" header="Start Time" sortable/>
              {/* <Column body={dateBody} headerStyle={{maxWidth: '14em'}} bodyStyle={{maxWidth: '14em'}} className="flex justify-content-center" field="end_date" header="End Time" sortable /> */}
              <Column body={durationBody} headerStyle={{maxWidth: '7em'}} bodyStyle={{maxWidth: '7em'}} className="flex justify-content-center" field="duration" header="Duration" />
              <Column body={rowsBody} headerStyle={{maxWidth: '6em'}} bodyStyle={{maxWidth: '6em'}} className="flex justify-content-center" field="rows" header="Rows" />
              <Column body={bytesBody} headerStyle={{maxWidth: '6em'}} bodyStyle={{maxWidth: '6em'}} className="flex justify-content-center" field="bytes" header="Bytes" />
              <Column body={statusBody} headerStyle={{maxWidth: '8em'}} bodyStyle={{maxWidth: '8em'}}  field="status" className="flex justify-content-center" header="Status" sortable filter/>
              <Column body={actionTemplate} headerStyle={{maxWidth: '9em'}} bodyStyle={{maxWidth: '9em'}} className="flex justify-content-center" header="Actions" />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
};



export const processWsMessageHistory = async (executions : State<Execution[]>, msgs: Message[], replication_id?: number) => {
  if(msgs.length === 0) return
  
  // if execution does not exist in list, get it
  let exec_id_map : { [key: number]: any; } = {}
  for(let id of executions.get().map(e => e.id)) exec_id_map[id] = true

  let ids : number[] = [] // ids to get
  for(let msg of msgs) {
    if([MessageType.ExecStatus, MessageType.ExecutionHeartbeat].includes(msg.type)) {
      let exec_id = msg.data.exec_id as number
      let repl_id = msg.data.replication_id as number
      if(replication_id && repl_id !== replication_id) continue
      if(!(exec_id in exec_id_map)) ids.push(exec_id)
    }
  }

  let new_execs : Execution[] = []
  if (ids.length > 0) new_execs = await sling.loadExecutions({ids})
  
  executions.set(execs => {
    let reorder = false
    // add new ones
    for(let exec of new_execs) execs.unshift(exec)
    for(let msg of msgs) {
      if(msg.type === MessageType.ExecutionHeartbeat) {
        let ehb = msg.data as ExecutionHeartbeat
        if(replication_id && ehb.replication_id !== replication_id) continue
        reorder = true

        for (let i = 0; i < execs.length; i++) {
          if(execs[i].id === ehb.exec_id) {
            execs[i].status = ehb.status
            execs[i].rows = ehb.rows
            execs[i].bytes = ehb.bytes
            execs[i].start_time = ehb.start_time
            execs[i].end_time = ehb.end_time
            break
          }
        }
      }

      if(msg.type === MessageType.ExecStatus) {
        let es = msg.data as ExecutionStatus

        // update row state
        for (let i = 0; i < execs.length; i++) {
          if(execs[i].id === es.exec_id) {
            execs[i].status = es.status
            execs[i].rows = es.rows
            execs[i].bytes = es.bytes
            execs[i].data.duration = es.duration
            execs[i].data.percent = es.percent
            execs[i].data.avg_duration = es.avg_duration
            break
          }
        }
      }
    }
    
    if(reorder) {
      execs = _.orderBy(execs, ['rank', 'start_time', 'id'], ['asc', 'desc', 'desc'])
    }
    return execs
  })
}

  
export const nameBodyTooltip = (execution: Execution, cell_id: string) => {
  let next = execution.next_execution_time ? relative_duration(new Date(execution.next_execution_time*1000)) : '-'

  return <Tooltip
    target={`#${cell_id}`}
    style={{fontSize: '10px', fontFamily: 'monospace'}}
    autoHide={false}
  >
      <div>
          <p style={{minWidth: '5rem', marginBottom: 0}}><strong>Name:</strong> { execution.job_name }</p>
          <p style={{minWidth: '5rem', marginBottom: 0}}><strong>Duration:</strong> { get_duration(execution.duration) }</p>
          <p style={{minWidth: '5rem', marginBottom: 0}}><strong>Size:</strong> { toBytes(execution.bytes) }</p>
          <p style={{minWidth: '5rem', marginBottom: 0}}><strong>Rows:</strong> { execution.rows?.toLocaleString() || '-' }</p>
          <p style={{minWidth: '5rem', marginBottom: 0}}><strong>Next:</strong> { next }</p>
      </div>
  </Tooltip>
}
  
export const replicationBody = (execution: Execution, column: ColumnBodyOptions) => {
  let value = `${execution.src_conn} -> ${execution.tgt_conn}`

  return <Link to={`/replication?rid=${execution.replication_id}`}>
    <div> { value } </div>
  </Link>
}
  
  
export const bytesBody = (execution: Execution, column: ColumnBodyOptions) => {
  let bytes = toBytes(execution.bytes || 0, true)
  if(execution.status === ExecStatus.Interrupted) {
    bytes = '-'
  }
  return <div style={{fontSize: '11px'}}>
    <div> { bytes } </div>
  </div> 
}
  
export const rowsBody = (execution: Execution, column: ColumnBodyOptions) => {
  return <div style={{fontSize: '11px'}}>
    <div> {execution.rows?.toLocaleString() || 0 } </div>
  </div> 
}


export const durationBody = (execution: Execution, column: ColumnBodyOptions) => {
  const cell_id = `exec-duration-${execution.id}`
  const duration = get_duration(execution.duration)

  if (column.field === 'duration-simple') {
    return <span> {duration} </span>
  }

  const displayValueTemplate = (value: number) => {
    return duration
  }  

  if(execution.status === ExecStatus.Running) {
    const color : string|undefined = '#c8c9e6'
    const mode = execution.percent && execution.percent < 100 ? 'determinate' : 'indeterminate'
    return <div style={{width: '100%', fontSize: '10px'}}>
        <ProgressBar
          style={{fontSize: '10px'}}
          value={execution.percent}
          mode={mode}
          color={color}
          displayValueTemplate={displayValueTemplate}
          showValue
        />
    </div> 
  }
  
  return <div>
    {
      execution.end_time ?
      <Tooltip target={`#${cell_id}`} position='bottom'>
          <div>
            Ended { relative_duration(new Date(execution.end_time*1000)) }
          </div>
      </Tooltip>
      : null
    }
    <div id={cell_id}> { duration } </div>
  </div> 
}

export const dateBody = (execution: Execution, column: ColumnBodyOptions) => {
  const cell_id = `exec-${column.field}-${execution.id}`
  let date = (column.field === 'start_date' ? execution.start_date_str : 
              column.field === 'end_date' ? execution.end_date_str : '-') || '-'

  let time = (column.field === 'start_date' ? execution.start_time : 
              column.field === 'end_date' ? execution.end_time : 0) || 0

  if(column.field === 'end_date' && execution.status === ExecStatus.Running) {
    const color : string|undefined = '#c8c9e6'
    const mode = execution.percent && execution.percent < 100 ? 'determinate' : 'indeterminate'
    return <div style={{width: '100%'}}>
        <ProgressBar value={execution.percent} mode={mode} color={color}/>
    </div> 
  }

  return <div>
    <Tooltip target={`#${cell_id}`} position='bottom'>
        <div>
          { relative_duration(new Date(time*1000)) }
        </div>
    </Tooltip>
    <span id={cell_id} > {date} </span>
  </div>
}