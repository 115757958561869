import _ from "lodash";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { TabView, TabPanel } from "primereact/tabview";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { sling, useQuery } from "../App";
import { blankConnSpec, connRef } from "../core/connection";
import { Replication } from "../core/replication";
import { useHookState } from "../core/store";
import { jsonClone, toastInfo } from "../utilities/methods";
import { ReplicationPanelStreams } from "../components/ReplicationPanelStreams";
import { ReplicationPanelSettings } from "../components/ReplicationPanelSettings";
import { ReplicationPanelStatus } from "../components/ReplicationPanelStatus";

export const ReplicationOneView: React.FC<{}> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const query = useQuery()
  const history = useHistory()
  const connections = useHookState(sling.state.connections)
  const replication = useHookState(new Replication())
  const loading = useHookState(false)
  const refresh = useHookState(0)
  const isModified = useHookState(false)
  const origReplication = React.useRef(new Replication())
  const [activeIndex, setActiveIndex] = React.useState(0)
  const connSpecs = sling.state.settings.app.connection_specs.get()
  ///////////////////////////  EFFECTS  ///////////////////////////
  // Load initial data
  React.useEffect(() => { 
    let rid = query.get('rid')
    let pid = query.get('pid')
    let job_config_name = sling.state.temp.job_config_name?.get()
    
    if(pid && !sling.checkProject(pid)) return 
    if(!rid) history.push(`/replications`)
    else if(job_config_name) setActiveIndex(1) // schema panel to view

    setReplication()
  }, []); // eslint-disable-line

  React.useEffect(() => { 
    // console.log('ReplicationOneView')
  }, [replication.get()]); // eslint-disable-line

  React.useEffect(() => { 
    // console.log('ReplicationOneView')
    let modified = 
      !_.isEqual(origReplication.current.config, jsonClone(replication.config.get()))
      || !_.isEqual(origReplication.current.settings, jsonClone(replication.settings.get()))
    if(modified !== isModified.get()) isModified.set(modified)
  }, [replication.config.get(), replication.settings.get()]); // eslint-disable-line

  React.useEffect(() => { 
    document.title = 'Sling - Replication'
    // rudderstack.page(document.title, '', sling.rudderProperties())
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////

  const setReplication = async () => {
    if(sling.state.replications.keys.length === 0) await sling.loadReplications()
    let replicationId = query.get('rid')
    if(replicationId) {
      origReplication.current = new Replication(
        jsonClone(sling.state.replications[parseInt(replicationId)].get())
      )
      replication.set(new Replication(jsonClone(origReplication.current)))
    }
  }

  const getTitle = () => { 
    const source = connRef(replication.source_id.get())
    const target = connRef(replication.target_id.get())
    const sourceSpec = connSpecs[source.type] || blankConnSpec(source.type)
    const targetSpec = connSpecs[target.type] || blankConnSpec(target.type)
    const iconHeight = "24px"
    return <>
      <div className="flex  justify-content-center">
        <span className="mr-2">
          <img
            src={'assets/connections/' + sourceSpec.icon}
            alt={source.type}
            height={iconHeight}
            width={iconHeight}
            style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
          />
        </span>
        {source.name}
        <span className="ml-2 mr-2"> {'->'} </span>
        <span className="mr-2">
          <img
            src={'assets/connections/' + targetSpec.icon}
            alt={target.type}
            height={iconHeight}
            width={iconHeight}
            style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
          />
        </span>
        {target.name}
      </div>
    
    </>
  }

  ///////////////////////////  JSX  ///////////////////////////

  if(!replication.id.get() || connections.keys.length === 0) return <></>
  
  return (
    <div
      id="replication-panel"
      style={{
        position: 'relative',
      }}
    >
      <h2 style={{ textAlign: "center" }}> { getTitle() }</h2>
      <TabView
        style={{ textAlign: "center" }}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Status">
            <ReplicationPanelStatus replication={ replication } loading={ loading } refresh = { refresh }/>
        </TabPanel>
        <TabPanel header="Streams" disabled={ !sling.userAtLeastPower }>
            <ReplicationPanelStreams replication={ replication } loading={ loading } refresh = { refresh } isModified={ isModified }/>
        </TabPanel>
        <TabPanel header="Settings" disabled={ !sling.userAtLeastPower }>
            <ReplicationPanelSettings replication={ replication } loading={ loading } refresh = { refresh }/>
        </TabPanel>
      </TabView>


      <span
        style={{
          position: 'absolute',
          left: 30,
          top: 60,
          // marginTop: '10px',
        }}
      >
        <InputSwitch
          disabled={!sling.userAtLeastPower}
          checked={ replication.active.get() }
          tooltip={ replication.active.get() ? 'Turn Schedule Off' : 'Turn Schedule On'}
          tooltipOptions={{position: 'top'}}
          onChange={async (e) => { 
            if(sling.isDemoUser) return toastInfo('Want to toggle schedules?', 'Please go to https://slingdata.io and sign up to toggle all the schedules you want!', 6000)
            replication.active.set(e.value)
            await sling.saveReplication(replication)
            await sling.loadReplications()
            refresh.set(v => v + 1)
          }}
        />
      </span>

      <span
        style={{
          position: 'absolute',
          right: 30,
          top: 55,
          // marginTop: '10px',
        }}
      >
        {
          isModified.get()?
          <>
            <Button
              icon={loading.get()? 'pi pi-spin pi-spinner':'pi pi-check'}
              label="Save"
              tooltip="Save Changes"
              tooltipOptions={{ position: 'top' }}
              loading={loading.get()}
              className="p-button-success p-button-sm mr-2"
              onClick={async () => {
                if(sling.isDemoUser) return toastInfo('Want to edit replications?', 'Please go to https://slingdata.io and sign up to edit all the replications you want!', 6000)
      
                loading.set(true)

                if(sling.state.project.is_free.get()) {
                  replication.config.defaults.schedule.set('')
                }

                if(await sling.saveReplication(replication)) {
                  let replicationId = replication.id.get() as number
                  origReplication.current = new Replication(jsonClone(replication.get()))
                  replication.set(new Replication(jsonClone(replication.get()))) // apply trimConfig
                  sling.state.replications[replicationId].set(origReplication.current)
                  replication.id.set(v => v) // refresh
                }

                refresh.set(v => v + 1)
                loading.set(false)
              }}
            />
            <Button
              icon="pi pi-times"
              disabled={loading.get()}
              label="Revert"
              tooltip="Revert Changes"
              tooltipOptions={{ position: 'top' }}
              className="p-button-danger p-button-sm"
              onClick={(e) => {
                replication.set(new Replication(jsonClone(origReplication.current)))
                refresh.set(v => v + 1)
              }}
            />
          </>
          :
          null
        }
      </span>
    </div>
  )
};