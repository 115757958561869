import { get_duration } from "../utilities/methods";

export type StatNameType = 'volume' | 'duration' | 'dashboard' | 'usage'
export type StatPeriodType = 'minute' | 'hour' | 'day' | 'week' | 'month'

export interface VolumeRecord {
  date: number;
  total_executions: number;
  total_duration: number;
  total_rows: number;
  total_bytes: number;
}

export interface DurationRecord {
  id: number;
  task_name: string;
  start_time: number;
  end_time: number;
  total_rows: number;
  total_bytes: number;
}

export interface UsageRecord {
  week_bytes: number;
  month_bytes: number;
  usage_bytes: number;
}

export interface DashboardRecord {
	project_id:      string;
	unique_objects:  number;
	objects_ok:      number;
	objects_failing: number;
	total_bytes:     number;
	total_rows:      number;
	total_bytes_prev: number;
	total_rows_prev:  number;
	running_count:   number;
	failure_count:   number;
}

export const getPeriodMultiplier = (period: StatPeriodType) => {
  let multiplier = 1
	if(period === "hour") multiplier = 60 * 60
	else if(period === "day") multiplier = 60 * 60 * 24
	else if(period === "week") multiplier = 60 * 60 * 24 * 7
	else if(period === "month") multiplier = 60 * 60 * 24 * 30
  return multiplier
}

// https://apexcharts.com/docs/options
export const getDurationChartOptions = (hasData: boolean, showToolbar = false) => {
  return {
    chart: {
      height: 450,
      type: 'rangeBar',
      toolbar: {
        show: showToolbar,
      },
      events: {
        click: function(event: any, chartContext: any, config: any) {
          let seriesIndex = config.seriesIndex
          let dataPointIndex = config.dataPointIndex
          let data = config.config?.series[seriesIndex]?.data[dataPointIndex]
          let onClick = data?.onClick
          if(onClick) onClick()
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%'
      }
    },
    xaxis: {
      type: 'datetime',
      min: new Date().getTime() - 60*60*1000,
      max: new Date().getTime(),
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    noData: {
      text: 'No recent executions...',
    },
    stroke: {
      width: 1
    },
    fill: {
      type: 'solid',
      opacity: 0.6
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'left'
    },
    grid: {
      show: true,
      borderColor: '#969b9e',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: hasData
        }
      }, 
    },
    tooltip: {
      custom: function(params: any) {
        // console.log(params)
        // let { series, seriesIndex, dataPointIndex, w, y1, y2 } = params
        let { seriesIndex, w, y1, y2 } = params
        let duration = get_duration((y2-y1)/1000)
        return (
          '<div class="arrow_box">' +
          "<span>" +
          w.globals.seriesNames[seriesIndex] +
          ": " +
          duration + 
          "</span>" +
          "</div>"
        );
      }
    }
  }
}