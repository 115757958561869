import { ObjectAny } from "../../utilities/interfaces";
import { new_ts_id } from "../../utilities/methods";
import { makeHeaders } from "./api";
import { apiPost, masterURL } from "./http";
import { Route } from "./routes";


export interface Message {
  req_id: string;
  type: MessageType;
  data: ObjectAny;
  error?: string;
  orig_req_id: string;
  from?: string;
}

export enum MessageType {
  NoReply = "no_reply",
  MsgReceivedOK = "receipt_ok",
  Err = "error",
  ClientHeartbeat = "client_heartbeat",
  LogEvent = "log_event",
  LogStreamReq = "log_stream_request",
  LogStreamTimedOutMsg = "log_stream_timed_out",
  WorkerErr = "error_worker",
  TaskSpawnErr = "error_task_spawn",
  ProjectStatus = "project_status",
  ExecStatus = "execution_status",
  ExecutionHeartbeat = "execution_heartbeat",
  ServerHeartbeat = "master_heartbeat",
  TaskProcess = "task_process",
  WorkerHeartbeat = "worker_heartbeat",
  CronUpdate = "cron_update",
  CreateExecution = "create_execution",
  TestConnection = "test_connection",
  DataRequest = "data_request",
  TerminateExecution = "terminate_execution",
  StatusStreamReq = "status_request",
  WorkerDisconnectReq = "status_request",
  NotifyClient = "notify_client",
  RmClientEntry = "remove_client_entry",
  PauseSchedulerMsgTpe = "pause_scheduler",
  ExecUpdateMsgTpe = "execution_update",
}

export enum MessageScopeLevel {
  Project     = "project",
  Execution   = "execution",
  Job         = "job",
  Replication = "replication",
  Worker      = "worker",
  All         = "all",
}

export interface MessageScope {
  level: MessageScopeLevel;
  project_id?: string;
  user_id?: string;
  exec_id?: number;
  replication_id?: number;
  job_id?: number;
  job_name?: string;
}

/**
 * Responsible for streaming communication with the backend via websocket
*/
export class Ws { 
  id: string
  status: 'offline' | 'online' | 'connecting'
  instance?: WebSocket
  callback?: (data: any) => void

  constructor(data: ObjectAny = {}) {
    this.id = new_ts_id()
    this.status = 'offline'
  }

  get connected () {
    return this.status === 'online'
  }

  async getToken() {
    try {
      let resp = await apiPost(Route.Ws, {}, makeHeaders())
      return resp.data.token as string
    } catch (error) {
      console.log(error)
    }
  }

  async connect(callback: (data: any) => void) {
    let token = await this.getToken()
    if(!token) return
    if(this.connected) this.close() // close if existing connection
    this.status = 'connecting'

    const masterWsURL = masterURL.replace('http', 'ws')
    const webSocket = new WebSocket(`${masterWsURL}${Route.Ws}?token=${token}`);
    webSocket.onmessage = (e) => {
      // console.log(e)
      let data = JSON.parse(e.data)
      callback(data)
    }

    webSocket.onclose = (e) => {
      // console.log(e)
      this.status = 'offline'
    }

    webSocket.onerror = (e) => {
      console.error(e)
    }

    this.instance = webSocket
    this.callback = callback

    // wait for open
    let connected = await new Promise<boolean>(function(resolve) {
      webSocket.onopen = (e) => {
        resolve(true)
      }

      setTimeout(() => {
        resolve(false) // give 3 sec to connect
      }, 3000);
    })

    this.status = connected ? 'online' : 'offline'

    return this.connected
  }

  subscribe(scope : MessageScope) {
    if(!this.connected) {
      return console.error(`could not subscribe with closed websocket`)
    }

    // subscribe to log stream
    const payload = JSON.stringify({
      req_id: new_ts_id(),
      type: 'log_stream_request',
      data: { scope, ws_stream_id: this.id },
    })
    try {
      this.instance?.send(payload)  
    } catch (error) {
      console.error(error)
    }
  }

  close() {
    this.instance?.close()
    this.status = 'offline'
  }
}

export class MessageBatcher {
  
  batch: Message[]
  last_drained: number

  constructor() {
    this.batch = []
    this.last_drained = 0
  }

  add (msg: Message) {
    this.batch.push(msg)
  }

  drain(buffer_time_ms = 1000) {
    const now = new Date().getTime()
    if(this.last_drained + buffer_time_ms <= now) {
      let batch = this.batch
      this.batch = []
      this.last_drained = now
      return batch
    } 
    return []
  }
}