import * as React from "react";
import { useState } from 'react';
import { DataTable, DataTableRowEventParams, DataTableRowExpansionTemplate } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Link } from "react-router-dom";
import { useHookState, useVariable } from "../core/store";
import { sling } from "../App";
import { Replication } from "../core/replication";
import { blankConnSpec, connRef } from "../core/connection";
import { ReplicationActiveSwitch } from "./ReplicationsView";
import { Chart as ChartJs } from 'primereact/chart';
import { ObjectAny, ObjectNumber, ObjectString } from "../utilities/interfaces";
import { DashboardRecord, VolumeRecord } from "../core/stats";
import _ from "lodash";
import { ExecStatus, toBytes } from "../core/execution";
import { get_duration, useIsMounted, useWindowDimensions } from "../utilities/methods";
import { rudderstack } from "..";

interface Props {}

export const HomeView: React.FC<Props> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const replications = useHookState(sling.state.replications)
  const replicLastSynchMap = useHookState<ObjectNumber>({})
  const replicDurationMap = useHookState<ObjectNumber>({})
  const replicStatushMap = useHookState<ObjectString>({})
  const dashboardRec = useHookState(sling.state.dashboard)
  const volumeChartRef = React.useRef(null)
  const { height } = useWindowDimensions();
  const isMounted = useIsMounted()
  const connSpecs = sling.state.settings.app.connection_specs.get()
  const volumeChartOptions = useVariable<ObjectAny>({
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
          legend: {
              labels: {
                  color: '#495057'
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          },
          y: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          }
      }
  }) 
  const volumeChartData = useVariable<ObjectAny>({
        labels: [],
        datasets: [
            {
                label: 'Total bytes (GB)',
                backgroundColor: '#42A5F5',
                data: []
            },
        ]
  })

  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    document.title = 'Sling - Dashboard'
    rudderstack.page(document.title, '', sling.rudderProperties())
    refreshCharts()
    refreshScalars()
    refreshJobsLastExec()
    sling.wsCallbacks.home = processWsData
    return () => {
      delete sling.wsCallbacks.home
    }
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const processWsData = (data: any) => {
    // console.log(data)
  }

  const refreshScalars = async () => {
    let dashboardRecords = await sling.getStats('dashboard', 'day', 1) as DashboardRecord[]
    if(!isMounted.current || dashboardRecords.length === 0) return
    dashboardRec.set(dashboardRecords[0])
  }

  const refreshJobsLastExec = async () => {
    let replicScheduleMap : ObjectString = {}
    for(let replic of Object.values(replications.get())) {
      replicScheduleMap[replic.id || 0] = replic.config?.defaults?.schedule || ''
    }

    let jobs = await sling.loadJobs({fields: 'jobs_last_sync'})
    if(!isMounted.current) return

    replicDurationMap.set(rdm => {
      for(let job of jobs) {
        let currDuration = rdm[job.replication_id] || 0
        rdm[job.replication_id] = currDuration + job.avg_duration
      }
      return rdm
    })

    replicLastSynchMap.set(rsm => {
      for(let job of jobs) {
        // needs to be the same schedule as replucation to consider minimum
        let schedule = replicScheduleMap[job.replication_id]
        if(job.schedule !== schedule) continue

        if(job.replication_id in rsm) {
          let currLastSync = rsm[job.replication_id]
          if(job.last_execution_time < currLastSync) {
            rsm[job.replication_id] = job.last_execution_time
          }
        } else {
          rsm[job.replication_id] = job.last_execution_time
        }
      }
      return rsm
    })

    replicStatushMap.set(rsm => {
      for(let job of jobs) {
        rsm[job.replication_id] = 'OK'
      }
      for(let job of jobs) {
        if(job.last_execution.status === ExecStatus.Error)
          rsm[job.replication_id] = 'Error'
      }
      return rsm
    })
  }

  const refreshCharts = async () => {
    const toGB = (b: number) => b / 1024 / 1024 / 1024
    let volRecords = await sling.getStats('volume', 'day', 14) as VolumeRecord[]
    if(!isMounted.current) return
    volumeChartData.set({
        labels: volRecords.map(r => new Date(r.date*1000).toISOString().split('T')[0]),
        datasets: [
            {
                label: 'Total bytes (GB)',
                backgroundColor: '#42A5F5',
                data: volRecords.map(r => toGB(r.total_bytes))
            },
        ]
    })
    const volumeChart : any = volumeChartRef.current
    volumeChart?.refresh()
  }

  const replicationsSorted = () => {
    let replics = Object.values(replications.get())
    replics = _.orderBy(replics, ['active', 'config.source', 'config.target'], ['desc', 'asc', 'asc'])
    return replics
  }
  
  ///////////////////////////  JSX  ///////////////////////////
  const onRowExpand = (e: DataTableRowEventParams): void => {
  }
  
  const onRowCollapse = (e: DataTableRowEventParams): void => {
  }

  const rowExpansionTemplate = (data: any, options: DataTableRowExpansionTemplate): React.ReactNode => {
    return <></>
  }

  const activeBody = (rec: Replication) => {
    return <ReplicationActiveSwitch replication={rec} tooltipPosition='left'/>
  }

  const nameBody = (rec: Replication) => {
    const source = connRef(rec.source_id)
    const target = connRef(rec.target_id)
    const sourceSpec = connSpecs[source.type] || blankConnSpec(source.type)
    const targetSpec = connSpecs[target.type] || blankConnSpec(target.type)
    const iconHeight = "13px"
    return (
      <span>
        <Link to={`/replication?rid=${rec.id}`}>
          <div className="flex  justify-content-center">
            <span className="mr-1">
              <img
                src={'assets/connections/' + sourceSpec.icon}
                alt={source.type}
                height={iconHeight}
                width={iconHeight}
                style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
              />
            </span>
            {source.name}
            <span className="ml-1 mr-1"> {'->'} </span>
            <span className="mr-1">
              <img
                src={'assets/connections/' + targetSpec.icon}
                alt={target.type}
                height={iconHeight}
                width={iconHeight}
                style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
              />
            </span>
            {target.name}
          </div>
        </Link>
      </span>
    );
  }

  // eslint-disable-next-line
  const lastSynchBody = (replication: Replication, column: ColumnBodyOptions) => {
    let last_synch = replicLastSynchMap.get()[replication.id || 0]
    if(last_synch) 
      return <>
        {new Date(last_synch * 1000)?.toLocaleString()}
      </>
    return <>-</>
  }

  // eslint-disable-next-line
  const AvgDurationBody = (replication: Replication, column: ColumnBodyOptions) => {
    let duration = replicDurationMap.get()[replication.id || 0]
    return <> { get_duration(duration) }</>
  }

  const statusBody = (replication: Replication, column: ColumnBodyOptions) => {
    if(!replication.active) return <i>disabled</i>
    let status = replicStatushMap.get()[replication.id || 0] || 'OK'
    let color = status === 'OK' ? 'green' : 'orange'
    return <>
      <span className={`text-${color}-500 font-medium`}>
        {status}
      </span>
    </>
  }

  const scheduleBody = (replication: Replication, column: ColumnBodyOptions) => {
    if(!replication.active) return <>-</>
    return <span>{replication.schedule}</span>
  }

  return (
    <div className="grid">
      <div className="col-12 md:col-12 lg:col-6 xl:col-6">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-6 xl:col-6">
            <CardOngoingExecutions rec={dashboardRec.get()}/>
          </div>
          <div className="col-12 md:col-12 lg:col-6 xl:col-6">
            <CardBytesProcessed rec={dashboardRec.get()}/>
          </div>
          <div className="col-12 md:col-12 lg:col-6 xl:col-6">
            <CardUniqueObjects rec={dashboardRec.get()}/>
          </div>
          <div className="col-12 md:col-12 lg:col-6 xl:col-6">
            <CardRowsProcessed rec={dashboardRec.get()}/>
          </div>
        </div>
      </div>
      <div className="col-12 md:col-12 lg:col-6 xl:col-6">
        <div className="card">
          <ChartJs
            ref={volumeChartRef}
            type="bar"
            height='220px'
            data={volumeChartData.get()}
            options={volumeChartOptions.get()}
          />
        </div>
      </div>

      <br />
      
      <div className="col-12 md:col-12 lg:col-12 xl:col-12">
        <div className="card">
          <DataTable
            value={replicationsSorted()}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            responsiveLayout="scroll"
            scrollable scrollHeight={`${height - 515}px`}
            rowExpansionTemplate={rowExpansionTemplate}
            emptyMessage="No replications found."
            dataKey="id"
          >
            {/* <Column expander style={{ width: '3em' }} /> */}
            <Column header="Active" body={activeBody} className="flex justify-content-center" style={{width: '10%', textAlign:"center"}}/>
            <Column header="Name" body={nameBody} style={{minWidth: '50%'}} sortable />
            {/* <Column field="price" header="Price" sortable body={priceBodyTemplate} /> */}
            <Column body={statusBody} className="flex justify-content-center" header="Status" style={{ width: '15%', textAlign: 'center'}} />
            <Column field="streams_cnt" className="flex justify-content-center" header="Objects" style={{ width: '10%', textAlign: 'center'}} />
            <Column body={scheduleBody} className="flex justify-content-center" header="Schedule" style={{ width: '15%', textAlign: 'center'}} />
            {/* <Column body={AvgDurationBody} className="flex justify-content-center" header="Avg Duration" style={{ textAlign: 'center'}} sortable /> */}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const CardOngoingExecutions: React.FC<{rec: DashboardRecord}> = (props) => {
  let color = props.rec.failure_count  > 0 ? 'orange' : 'green'
  return (
    <>
      <div className="card mb-0" style={{overflowX: 'hidden'}}>
          <div className="flex justify-content-between mb-3">
              <div>
                  <span className="block text-500 font-medium mb-3">Ongoing Executions</span>
                  <div className="text-900 font-medium text-xl"> { props.rec.running_count }</div>
              </div>
              <Link to='/history'>
                <div className={`flex align-items-center justify-content-center bg-${color}-100 border-round`} style={{ width: '2.5rem', height: '2.5rem' }} >
                    <i className={`pi pi-chevron-circle-right text-${color}-500 text-xl`}/>
                </div>
              </Link>
          </div>
          
          <Link to='/history' onClick={() => { sling.state.temp.history_view_filter.set('error') }}>
            <span className={`${props.rec.failure_count  > 0 ? 'text-orange' : 'text-green'}-500 font-medium`}>
              { props.rec.failure_count } failures 
            </span>
          </Link>
           <span className="text-500"> in last 24h</span>
          
      </div>
    </>
  )
}

const CardUniqueObjects: React.FC<{rec: DashboardRecord}> = (props) => {
  let color = props.rec.objects_failing  > 0 ? 'orange' : 'teal'
  return (
    <>
      <div className="card mb-0" style={{overflowX: 'hidden'}}>
          <div className="flex justify-content-between mb-3">
              <div>
                  <span className="block text-500 font-medium mb-3">Objects Replicating</span>
                  <div className="text-900 font-medium text-xl"> { props.rec.unique_objects?.toLocaleString() || 0 }</div>
              </div>
              <Link to='/replications'>
                <div className={`flex align-items-center justify-content-center bg-${color}-100 border-round`} style={{width: '2.5rem', height: '2.5rem'}}>
                    <i className={`pi pi-bolt text-${color}-500 text-xl`}/>
                </div>
              </Link>
          </div>
              <span className={`${props.rec.objects_failing  > 0 ? 'text-orange' : 'text-green'}-500 font-medium`}> { props.rec.objects_failing?.toLocaleString() || 0 } failing </span>
              <span className="text-500">objects</span>
      </div>
    </>
  )
}

const CardBytesProcessed: React.FC<{rec: DashboardRecord}> = (props) => {
  let delta_pcnt = props.rec.total_bytes_prev > 0 ? (props.rec.total_bytes - props.rec.total_bytes_prev) / props.rec.total_bytes_prev : 0
  delta_pcnt = Math.round(delta_pcnt * 100) / 1
  let delta_color = delta_pcnt  >= 0 ? 'green' : 'orange'
  
  return (
    <>
      <div className="card mb-0" style={{overflowX: 'hidden'}}>
          <div className="flex justify-content-between mb-3">
              <div>
                  <span className="block text-500 font-medium mb-3">Bytes (month)</span>
                  <div className="text-900 font-medium text-xl"> { toBytes(props.rec.total_bytes) }</div>
              </div>
              <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                  <i className="pi pi-chart-bar text-purple-500 text-xl"/>
              </div>
          </div>
          <span className={`text-${delta_color}-500 font-medium`}> { props.rec.total_bytes_prev > 0 ? delta_pcnt : '-'}% </span>
          <span className="text-500">delta</span>
      </div>
    </>
  )
}

const CardRowsProcessed: React.FC<{rec: DashboardRecord}> = (props) => {
  let delta_pcnt = props.rec.total_rows_prev > 0 ? (props.rec.total_rows - props.rec.total_rows_prev) / props.rec.total_rows_prev : 0
  delta_pcnt = Math.round(delta_pcnt * 100) / 1
  let delta_color = delta_pcnt  >= 0 ? 'green' : 'orange'

  let total_rows = props.rec.total_rows || 0
  let total_rows_str = total_rows.toLocaleString() 

  if(total_rows > 1000000000) {
    total_rows_str = (Math.round(total_rows/1000000000*100)/100).toLocaleString() + 'B'
  } else if(total_rows > 1000000) {
    total_rows_str = (Math.round(total_rows/1000000*100)/100).toLocaleString() + 'M'
  }
  
  return (
    <>
      <div className="card mb-0" style={{overflowX: 'hidden'}}>
          <div className="flex justify-content-between mb-3">
              <div>
                  <span className="block text-500 font-medium mb-3">Rows (month)</span>
                  <div className="text-900 font-medium text-xl"> { total_rows_str } rows</div>
              </div>
              <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                  <i className="pi pi-chart-line text-blue-500 text-xl"/>
              </div>
          </div>
          <span className={`text-${delta_color}-500 font-medium`}> { props.rec.total_rows_prev > 0 ? delta_pcnt : '-'}% </span>
          <span className="text-500">delta</span>
      </div>
    </>
  )
}

