import { ObjectAny } from "../utilities/interfaces"
import { WorkerRegion } from "./worker"

export enum Plan {
  Unselected = "unselected",
  CloudFree = "cloud-free",
  CloudStandard = "cloud-standard",
  CloudBusiness = "cloud-business",
  SelfHostedFree = "self-hosted-free",
  SelfHosted = "self-hosted",
}

export enum Status {
  OK         = "ok",
  OverLimit  = "over-limit",
  BalanceDue = "balance-due",
}

export class Project {
  id: string
  name: string
  organization: string
  domain: string
  owner_email: string
  status: string
  plan: Plan
  region: WorkerRegion
  token: string
  paused: boolean
  stripe_customer_id: string
  sub_period_start: number;
  sub_period_end: number;
  constructor(data: ObjectAny = {}) {
    this.id = data.id
    this.name = data.name
    this.organization = data.organization
    this.domain = data.domain
    this.owner_email = data.owner_email
    this.status = data.status || Status.OK
    this.plan = data.plan || Plan.SelfHostedFree
    this.region = data.region || WorkerRegion.Any
    this.token = data.token 
    this.paused = data.paused
    this.stripe_customer_id = data.stripe_customer_id
    this.sub_period_start = data.sub_period_start
    this.sub_period_end = data.sub_period_end
  }

  get is_self_hosted() {
    return [Plan.SelfHosted, Plan.SelfHostedFree].includes(this.plan)
  }

  get is_cloud() {
    return !this.is_self_hosted
  }

  get is_free() {
    return [Plan.CloudFree, Plan.SelfHostedFree].includes(this.plan)
  }
}