import { createState, State } from "@hookstate/core";
import { ObjectAny, ObjectString } from "../utilities/interfaces";
import { delete_cookie, jsonClone, toastError, toastInfo } from "../utilities/methods";
import { API, CRUD } from "./api/api";
import { authURL, Route } from "./api/routes";
import { Connection, ConnectionMap, connRef } from "./connection";
import { Execution, ExecutionMap } from "./execution";
import { Job, JobMap, JobMode } from "./job";
import { Plan, Project } from "./project";
import { Settings, SettingsLevel } from "./settings";
import { User } from "./user";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { Replication, ReplicationMap } from "./replication";
import { getFileList, getSchemas, discoverStreams, getTables, getViews } from "./schemata";
import { MessageScopeLevel } from "./api/ws";
import { DashboardRecord, DurationRecord, getPeriodMultiplier, StatNameType, StatPeriodType, UsageRecord, VolumeRecord } from "./stats";
import { environment, HttpResponse, isDemo, urlHasModeDemo } from "./api/http";
import * as Sentry from "@sentry/react";
import { Workr, WorkerMap } from "./worker";
import { version } from '../../package.json'


export type SlingOptions = {};

class SlingState {
  user: State<User>
  project: State<Project>
  replications: State<ReplicationMap>
  jobs: State<JobMap>
  executions: State<ExecutionMap>
  connections: State<ConnectionMap>
  workers: State<WorkerMap>
  dashboard: State<DashboardRecord>
  settings: State<Settings>
  temp: State<ObjectAny>
  loaded: State<boolean>

  constructor(data: ObjectAny = {}) {
    this.user = createState(new User()) // loaded via method
    this.project = createState(new Project())  // loaded via method
    this.executions = createState({} as ExecutionMap) // loaded via method
    this.replications = createState({} as ReplicationMap)  // loaded via method
    this.jobs = createState({} as JobMap)  // loaded via method
    this.connections = createState({} as ConnectionMap) // loaded via method
    this.workers = createState({} as WorkerMap) // loaded via method
    this.settings = createState(new Settings())
    this.temp = createState({} as ObjectAny)
    this.loaded = createState<boolean>(false)
    this.dashboard = createState({
      project_id: '',
      unique_objects: 0,
      objects_ok: 0,
      objects_failing: 0,
      total_bytes: 0,
      total_rows: 0,
      total_bytes_prev: 0,
      total_rows_prev: 0,
      running_count: 0,
      failure_count: 0,
    } as DashboardRecord)
    
    // key for demo user
    if(urlHasModeDemo)
      localStorage.setItem('demo_key', '698575497.6Fs6TE1rbkahnneIqI18ctOa03lvF5Of')
  }
}


export class Sling {
  api: API
  state: SlingState
  wsCallbacks: { [key: string]: (data: any) => void; }; 

  constructor(options: SlingOptions) {
    this.api = new API()
    this.state = new SlingState()
    this.wsCallbacks = {}
  }

  async init() {
    if (await this.loadUser()) {
      let promises : Promise<any>[] = []
      await this.loadProjects(this.state.user.def_project_id.get())
      if(this.state.project.get().is_self_hosted) promises.push(this.loadWorkers())
      promises.push(this.loadSettings('app'))

      await this.loadConnections()
      promises.push(this.loadReplications())
      // promises.push(this.connectWebsocket()) // disable by default

      // wait for all promises
      for(let promise of promises) await promise
      this.state.loaded.set(true)

      // check version
      this.checkVersion()
    }
  }

  async dispose() {
    this.api.ws.close()
  }

  async logout() {
    await signOut()
    this.state.user.id.set('')
    delete_cookie('sIdRefreshToken')
    delete_cookie('sIRTFrontend')
    delete_cookie('sFrontToken')
    delete_cookie('sAccessToken')
    window.location.href = this.isDemoUser ? 'https://slingdata.io' : authURL
  }

  get userAtLeastSpectator() {  
    const user = this.state.user.get()
    return user.is_spectator || user.is_power || user.is_admin || user.is_master
  }

  get userAtLeastPower() {  
    const user = this.state.user.get()
    return user.is_power || user.is_admin || user.is_master
  }

  get userAtLeastAdmin() {  
    const user = this.state.user.get()
    return user.is_admin || user.is_master
  }

  get isDemoUser() {  
    return isDemo && !this.userAtLeastAdmin
  }

  get userAtLeastMaster() {  
    const user = this.state.user.get()
    return user.is_master
  }

  async loadUser() {
    const response = await this.api.Get(Route.User)
    if (response.error) {
      // if (response.data?.message === "try refresh token") {
      //   toastInfo("Session expired, reloading...")
      //   setTimeout(() => {
      //     window.location.reload()
      //   }, 2000);
      // }
      return false
    }
    let user = new User(response.data?.user)
    if(!user.id) return false

    this.state.user.set(new User(response.data?.user))
    this.state.project.id.set(user.def_project_id)
    Sentry.setUser({id: user.id, email: user.email})
    return true
  }

  async loadProjects(id?: string) {
    const records = await CRUD().projects.List({ id })
    let projects : Project[] = []
    for (let record of records) {
      let project = new Project(record)
      if(id && project.id === this.state.user.def_project_id.get()) {
        this.state.project.set(project)
      }
      projects.push(project)
    }
    return projects
  }

  async checkProject(id: string) {
    if(this.state?.project?.id?.get() && id !== this.state.project.id.get()) {
      await this.setProject(id)
      return false
    }
    return true
  }

  async setProject(project_id: string) {
    let user = new User(jsonClone(this.state.user.get()))
    user.def_project_id = project_id
    if(user.def_project_id && await this.saveUser(user)) {
      window.location.reload() // refresh to load new workspace
    }
  }

  async loadWorkers(id?: string) {
    const records = await CRUD().workers.List({ id })
    this.state.workers.set(workers => {
      if (!id) workers = {} // if no id specified, delete all to refresh
      for (let record of records) {
        workers[record.id] = new Workr(record)
      }
      return workers
    })
  }

  async createProject(name: string) {
    let data = {
      name,
      owner_email: this.state.user.email.get(),
    }
    const response = await this.api.Post(Route.Projects, data)
    if(response.error) {
      toastError('Could not create workspace', response.error)
      return ''
    }
    return response.data.id as string
  }

  async saveProject(project: Project) {
    let data = jsonClone(project)
    const response = await this.api.Put(Route.Projects, data)
    if(response.error) {
      toastError('Could not save project', response.error)
      return false
    }
    return true
  }

  async setPlan(plan: Plan) {
    const response = await this.api.Post(Route.Subscription, { plan })
    if(response.error) {
      toastError('Could not set plan', response.error)
      return false
    }
    return true
  }

  async loadSettings(level: SettingsLevel) {
    const response = await this.api.Get(Route.Settings, { level: level })
    if(response.error) return false
    if(level === 'app'){
      this.state.settings.app.set(response.data?.settings)
      this.state.settings.app.frontend_version.set(version)
      console.log("frontend_version: " + this.state.settings.app.frontend_version.get())
      
      this.api.Get(Route.Status)
        .then((resp) => {
          this.state.settings.app.backend_version.set(resp?.data?.version || '')
          console.log("backend_version: " + this.state.settings.app.backend_version.get())
        })
    } else if(level === 'user'){
      this.state.settings.user.set(response.data?.settings)
    } else if(level === 'project'){
      this.state.settings.project.set(response.data?.settings)
    }
    return true
  }

  checkVersion() {
    const backend_version = this.state.settings.app.backend_version.get()
    const frontend_version = this.state.settings.app.frontend_version.get()
    if(backend_version > frontend_version)
      toastInfo('App Update Available', 'Please hard refresh your page to use the latest version', 9000)
  }

  async saveSettings(level: SettingsLevel, settings: ObjectAny) {
    let data : ObjectAny = {
      level: level,
      settings: jsonClone(settings)
    }
    const response = await this.api.Put(Route.Settings, data)
    if(response.error) return false
    return true
  }

  async testSettings(name: 'email'|'slack-webhook'|'msteams-webhook'|'notification', data: ObjectAny) {
    data.test_name = name
    const response = await this.api.Post(Route.Settings, data)
    if(response.error) return false
    return true
  }

  async loadConnections(id?: string) {
    // const records = await this.api.List(Route.Connections, id)
    const records = await CRUD().connections.List({ id })
    
    // update in state
    this.state.connections.set(conns => {
      if (!id) conns = {} // if no id specified, delete all to refresh
      for (let record of records) {
        conns[record.id] = new Connection(record)
      }
      return conns
    })
  }

  async loadConnectionSchemata(connId: string, schema? : string, path?: string) {
    let internal_schemas = [
      'pg_catalog',
      'pg_toast',
      // 'information_schema',
      '_timescaledb_internal',
      '_timescaledb_config',
      '_timescaledb_catalog',
      'timescaledb_experimental',
      'timescaledb_information',
    ]
    let internal_schema_prefixes = [
      'pg_temp_',
      'pg_toast_temp_',
    ]
    let conn = connRef(connId)
    if (conn.is_api || conn.is_airbyte) {
        let streamRecords = await discoverStreams(conn)
        this.state.connections[connId].set(c => {
          for (let i = 0; i < streamRecords.length; i++) {
            const rec = streamRecords[i];
            c.schemata[rec.stream_name] = []
            if (!(rec.stream_name in c.columns)) c.columns[rec.stream_name] = []
            c.columns[rec.stream_name].push({
              column_id: i+1,
              column_name: rec.column_name,
              column_type: rec.column_type,
              primary_key: rec.primary_key,
              update_key: rec.update_key,
            })
          }
          return c
        })
    } else if (conn.is_database) {
      if(schema) {
        // table level
        let promises: Promise<any[]>[] = []
        promises.push(getTables(conn, schema))
        promises.push(getViews(conn, schema))

        let schemaTables : string[] = []
        for(let promise of promises) {
          let records = await promise
          for (let rec of records) {
            schemaTables.push(rec.table_name)
          }
        }
        this.state.connections[connId].schemata[schema].set(schemaTables)
      } else {
        let schemaRecords = await getSchemas(conn)
        this.state.connections[connId].set(c => {
          for (let rec of schemaRecords) {
            let skip = false
            for(let internal_schema of internal_schemas) {
              if(rec.schema_name.toLowerCase() === internal_schema) skip = true
            }
            for(let prefix of internal_schema_prefixes) {
              if(rec.schema_name.toLowerCase().startsWith(prefix)) skip = true
            }
            if(skip) continue
            c.schemata[rec.schema_name] = []
          }
          return c
        })
      }
    } else if (conn.is_file && path) {
      let fileRecords = await getFileList(conn, path)
      this.state.connections[connId].set(c => {
        for (let rec of fileRecords) {
          c.schemata[rec.name] = []
        }
        return c
      })
    }
  }

  async saveConnection(conn: Connection) {
    return await CRUD().connections.Save(conn)
  }

  async deleteConnection(conn: Connection) {
    return await CRUD().connections.Delete(conn)
  }

  async testConnection(conn: Connection) {
    let resp = await this.api.Post(Route.ConnectionTest, conn.payload())
    return { success: resp.status === 200, error: resp.error }
  }

  async loadReplications(id?: number) {
    const records = await CRUD().replications.List({ id })
    
    // update in state
    this.state.replications.set(replications => {
      if (!id) replications = {} // if no id specified, delete all to refresh
      for (let record of records) {
        replications[record.id] = new Replication(record)
      }
      return replications
    })
  }

  async saveReplication(replication: State<Replication>) {
    replication.config.source.set(connRef(replication.source_id.get()).name)
    replication.config.target.set(connRef(replication.target_id.get()).name)
    return await CRUD().replications.Save(replication.get())
  }

  async compileReplication(replication: State<Replication>) {
    replication.config.source.set(connRef(replication.source_id.get()).name)
    replication.config.target.set(connRef(replication.target_id.get()).name)
    
    let jobs : { [key: string]: Job; } = {}
    let resp =  await this.api.Post(Route.ReplicationCompile, replication.get())
    if(resp.error) {
      toastError("Could not compile Replication", resp.error)
      return jobs
    }

    for(let key of Object.keys(resp.data)) jobs[key] = new Job(resp.data[key])
    return jobs
  }

  async exportReplication(replication: Replication) {
    let data : ObjectAny = {
      level: 'replication',
      id: replication.id,
    }
    let resp = await this.api.Post(Route.Export, data)
    if (resp.error) {
      toastError('Error encountered', resp.error)
      return
    }
    return resp.data.output as string
  }

  async deleteReplication(replication: Replication) {
     return await CRUD().replications.Delete(replication)
  }

  async loadJobs(args: {
    ids?: number[],
    replication_id?: number,
    stream_id?: string,
    fields?: string,
  }) {
    let ids = args.ids
    let replicationId = args.replication_id
    let stream_id = args.stream_id
    
    let where : any[] = []
    let fields = args.fields || ''

    if(replicationId) where = where.concat(['replication_eq', replicationId])
    if(stream_id) where = ['stream_eq', stream_id]
    if(ids) {
      fields = "executions_all"
      where = ["id_in"].concat(ids.map(v => v.toString()))
    }
    const records = await CRUD().jobs.List({ where, fields })
    
    // update in state
    this.state.jobs.set(jobs => {
      if (!ids && !replicationId) jobs = {} // if no id specified, delete all to refresh
      for (let record of records) {
        jobs[record.id] = new Job(record)
      }
      return jobs
    })

    return records.map(r => new Job(r))
  }

  async saveJob(job: Job) {
     return await CRUD().jobs.Save(job)
  }

  async deleteJob(job: Job) {
     return await CRUD().jobs.Delete(job)
  }

  async executeJob(stream_id: string, debug='', mode?:JobMode) {
    let payload : ObjectAny = { stream_id }
    if(debug) payload.debug = debug
    if(mode) payload.mode = mode
    let resp = await this.api.Post(Route.Executions, payload)
    if(resp.error) {
      toastError(`Error executing stream`, resp.error);
      return 0
    }
    return (resp.data.job_id as number)
  }

  async loadUsers() {
    const records = await CRUD().users.List()
    return records.map(r => new User(r))
  }

  async saveUser(user: User) {
    const response = await this.api.Put(Route.Users, user.payload())
    if(response.error) {
      toastError('Could not update user')
      return false
    }
    return true
  }

  async loadExecutions(args: {
    ids?: number[],
    replication_id?: number,
    stream_id?: string,
    fields?: string,
    last?: number,
    where?: string[],
  }) {
    let ids = args.ids
    let replication_id = args.replication_id
    let stream_id = args.stream_id
    let last = args.last || 100

    let where = args.where || []
    let fields = args.fields || ''
    if(replication_id) where = where.concat(['replication_eq', replication_id.toString()])
    if(stream_id) where = ['stream_eq', stream_id]
    if(ids) {
      fields = "executions_all"
      where = ["id_in"].concat(ids.map(v => v.toString()))
    }
    const records = await CRUD().executions.List({ fields, where, last })
    
    // update in state
    this.state.executions.set(execs => {
      if (!ids && !replication_id) execs = {} // if no id specified, delete all to refresh
      for (let record of records) {
        execs[record.id] = new Execution(record)
      }
      return execs
    })

    return records.map(r => new Execution(r))
  }

  async getStats(name: StatNameType, period: StatPeriodType, lookback: number, replication_id?: number, stream_id?: string) {
    let payload = {
      name,
      period,
      lookback,
      replication_id,
      stream_id,
    }

    let resp : HttpResponse
    try {
      resp = await this.api.Get(Route.Stats, payload)
      if(resp.error) throw resp.error
    } catch (error) {
      console.error(error)
      return []
    }

    if(name === 'volume') {

      let records : VolumeRecord[] = []
      for (let rec of (resp.data?.data || [])) {
        records.push(rec)
      }
      // fill the previous lookback dates
      if (records.length && records.length < lookback) {
        const refDate = records[0].date
        for (let i = records.length; i < lookback; i++) {
          const item : VolumeRecord = {
            date: refDate - getPeriodMultiplier(period)*i,
            total_executions: 0,
            total_duration: 0,
            total_rows: 0,
            total_bytes: 0,
          };
          records.unshift(item)
        }
      }

      return records
    }

    if(name === 'duration') {
      let records : DurationRecord[] = []
      for (let rec of (resp.data?.data || [])) {
        records.push(rec)
      }

      return records
    }

    if(name === 'dashboard') {
      let records : DashboardRecord[] = []
      for (let rec of (resp.data?.data || [])) {
        records.push(rec)
      }

      return records
    }

    if(name === 'usage') {
      let data = resp.data?.data 
      let record : UsageRecord = {
        week_bytes: data.week_bytes,
        month_bytes: data.month_bytes,
        usage_bytes: data.usage_bytes,
      }
      
      return record
    }

    return []
  }

  async terminateExecution(id: number) {
    return await CRUD().executions.Delete({ id })
  }

  async connectWebsocket(force = false) {
    let connect = force || (this.api.ws.status === 'offline')
    if(connect) {
      if (await this.api.ws.connect((data: any) => this.processWsData(data))) {
        this.api.ws.subscribe({level: MessageScopeLevel.Project})
      }
    }
    return connect && this.api.ws.connected
  }

  processWsData(data: any) {
    for(let callback of Object.values(this.wsCallbacks)) {
      callback(data)
    }
  }

  async saveState() { }
  async loadState() { }

  rudderProperties(extra : ObjectAny = {}) {
     let data : ObjectAny = {
      environment: environment,
      user_id: this.state.user.id.get(),
      user_email: this.state.user.email.get(),
      project_id: this.state.project.id.get(),
      frontend_version: this.state.settings.app.frontend_version.get(),
      backend_version: this.state.settings.app.backend_version.get(),
    }
    for(let key of Object.keys(extra)) {
      data[key] = extra[key]
    }
    return data
  }
}

export const stagingDeleteUser = async () => {
  console.log('stagingDeleteUser')
  const headers : ObjectString = {'Sling-API-Key': 'No9ouVfMDUg2lqEvaGZmDrMX5qGibu7j'}
  await new API().Delete(Route.Users, {"email":"princelrc85@yahoo.com"}, headers)
}