import { State } from "@hookstate/core";
import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import * as React from "react";
import { Replication, ReplicationStreamConfig } from "../core/replication";
import { useHookState, useVariable } from "../core/store";
import { MultiSelect } from 'primereact/multiselect';
import { t } from "../utilities/translator";
import { getStreamId } from "../core/execution";
import { sling } from "../App";
import { Dropdown } from 'primereact/dropdown';
import { ConnKind } from "../core/connection";
import { ColRecord, getFileColumns, getManyTableColumns, getSqlColumns, StreamColumnMap } from "../core/schemata";
import { rudderTrack } from "..";
import { fileIncrementalNote, ReplicationJobDialog } from "./ReplicationJobDialog";
import { Tooltip } from "primereact/tooltip";
import { jsonClone } from "../utilities/methods";

interface Props {
  replication: State<Replication>
  show: State<boolean>
}

interface StreamKeyRecord {
  stream_id: string;
  stream_name: string;
  PK?: string[];
  UK?: string;
}

export const KeyManager: React.FC<Props> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const replication = useHookState(props.replication)
  const records = useVariable<StreamKeyRecord[]>([])
  const streamColumnsMap = useHookState<StreamColumnMap>({})
  const show = useHookState(props.show)
  const loading = useHookState(false)
  const jobDialog = useHookState({show: false, name: ''})
  const sourceConn = sling.state.connections[replication.source_id.get()]

  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    if(show.get()) {
      getAllColumns().then(
        () => refreshStreamRecords()
      )
    }
  }, [show.get()]); // eslint-disable-line

  React.useEffect(() => {
    if (!jobDialog.show.get()) {
      refreshStreamRecords()
    }
  }, [jobDialog.show.get()]); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const cancelHide = () => { props.show.set(false) }

  const getAllColumns = async () => {
    let conn = sourceConn.get()
    loading.set(true)

    let streams = replication.config.streams.get()
    if(conn.kind === ConnKind.Database) {
      let tableStreams : string[] = []
      let queryStreamsCols : StreamColumnMap = {}
      let queryStreamsPrm : { [key: string]: Promise<ColRecord[]>; } = {}

      for(let key of Object.keys(streams)) {
        let stream = streams[key]
        if(stream.sql !== undefined) {
          // is a query stream, fetch one by one
          queryStreamsPrm[key] = getSqlColumns(conn, stream.sql || '')
        } else {
          tableStreams.push(key)
        }
      }

      if(tableStreams.length) {
        streamColumnsMap.set(await getManyTableColumns(sourceConn.get(), tableStreams))
      }

      for(let key of Object.keys(queryStreamsPrm)) {
        queryStreamsCols[key] = await queryStreamsPrm[key]
      }
      streamColumnsMap.set(scm => {
        for(let key of Object.keys(queryStreamsCols)) {
          scm[key] = queryStreamsCols[key]
        }
        return scm
      })
    }
    
    if(conn.kind === ConnKind.File) {
      let fileStreamsCols : StreamColumnMap = {}
      let fileStreamsPrm : { [key: string]: Promise<ColRecord[]>; } = {}
      for(let key of Object.keys(streams)) {
        let streamConfig = streams[key]
        let config = replication.get().apply_defaults(jsonClone(streamConfig))
        fileStreamsPrm[key] = getFileColumns(conn, key, config.source_options)
      }
      for(let key of Object.keys(fileStreamsPrm)) {
        fileStreamsCols[key] = await fileStreamsPrm[key]
      }
      streamColumnsMap.set(scm => {
        for(let key of Object.keys(fileStreamsCols)) {
          scm[key] = fileStreamsCols[key]
        }
        return scm
      })
    }
    loading.set(false)
  }

  const refreshStreamRecords = () => {
    let recs : StreamKeyRecord[] = []
    let streams = replication.config.streams.get()
    for (let name of Object.keys(streams)) {
      let stream = streams[name]
      let stream_id = getStreamId(
        replication.source_id.get(), 
        replication.target_id.get(),
        name,
      )
      recs.push({
        stream_id: stream_id,
        stream_name: name,
        PK: stream.primary_key || [],
        UK: stream.update_key || '',
      })
    }
    records.set(recs)
  }

  const autoDetectKeys = () => {
    const appearsPK = (name: string) => {
      name = name.toLowerCase().trim()
      return name.endsWith('_id') || name === 'id'
    }
    const appearsUK = (name: string) => {
      name = name.toLowerCase().trim()
      let hasAt = name.includes('_at')
      let hasDate = name.includes('_date')
      let hasLast = name.includes('last')
      let hasMod = name.includes('mod')
      let hasUpdate = name.includes('update')
      return (hasLast || hasAt || hasDate) && (hasMod || hasUpdate)
    }

    const hasPK = (stream: ReplicationStreamConfig) => stream.primary_key && stream.primary_key.length
    const hasUK = (stream: ReplicationStreamConfig) => !!stream.update_key

    let streams = replication.config.streams
    for(let key of Object.keys(streams)) {
      let stream = streams[key].get()
      let columns = streamColumnsMap[key]?.get()
      if(hasPK(stream) || hasUK(stream) || sourceConn.is_file.get() || !columns) {
        continue
      }

      for(let column of columns) {
        if(!hasPK(streams[key].get()) && appearsPK(column.column_name)) 
          streams[key].primary_key.set([column.column_name])
        if(!hasUK(streams[key].get()) && appearsUK(column.column_name))
          streams[key].update_key.set(column.column_name)
      }
    }

    refreshStreamRecords()
  }

  ///////////////////////////  JSX  ///////////////////////////
    
  const Footer = <>
    <div className="flex align-items-center justify-content-center">
      <Button 
        label={ t('Close')}
        onClick={() => cancelHide() }
        icon="pi pi-check"
        className="p-button-info p-mr-4"
        style={{marginRight: '10px'}}
      />
    </div>
  </>
  

  const colKeyBody = (keyType:'PK'|'UK', data: StreamKeyRecord) => {

    const streamConfig = props.replication.config.streams[data.stream_name]
    const columns = (streamColumnsMap.get()[data.stream_name] || []).map(r => r.column_name)

    const setPK = (keys: string[]) => {
      keys = keys || []
      streamConfig.primary_key.set(keys)
      data.PK = keys
    }

    const setUK = (key: string) => {
      streamConfig.update_key.set(key)
      data.UK = key
    }

    if(keyType==='PK')
      return (
        <MultiSelect 
          style={{maxWidth: '10em', fontSize: '10px'}}
          value={ data.PK }
          options={columns}
          onChange={e => {
            setPK(e.value)
          }}
          placeholder="Select PK" 
        />
      )
      
    const elementID = 'dropdown-' + data.stream_id.toLowerCase()
    if(keyType==='UK')
      return <>
        <Tooltip disabled={!sourceConn.is_file.get()} target={'#'+elementID} position='right' style={{maxWidth: '300px'}}>
          {fileIncrementalNote}
        </Tooltip>
        <span id={elementID}>
          <Dropdown 
            style={{maxWidth: '10em'}}
            value={sourceConn.is_file.get() ? 'Internal UK' : data.UK || ''} 
            options={columns} 
            showClear={true}
            onChange={e => {
                setUK(e.value)
              }} 
            placeholder="Select UK" 
            disabled={sourceConn.is_file.get()} // use last_mod date of file
          />
        </span>
      </>
  
    return <></>
  }


  const nameTemplate = (data: StreamKeyRecord, column: ColumnBodyOptions) => {
    return <span>
      {data.stream_name}
      <i
        style={{ color: 'orange', cursor: "pointer" }} 
        className={"pi pi-cog ml-2"}
        onClick={() => {
          let stream_id = getStreamId(
            replication.source_id.get(), 
            replication.target_id.get(),
            data.stream_name,
          )
          jobDialog.name.set(data.stream_name)
          sling.state.temp.job_config.set(true)
          jobDialog.show.set(true)
          rudderTrack('replication', 'task-edit', sling.rudderProperties({stream_id,job_name: data.stream_name}))
        }}
      />
    </span>
  }

  const modeTemplate = (data: StreamKeyRecord, column: ColumnBodyOptions) => {
    let stream = replication.config.streams.get()[data.stream_name]
    let mode = stream.mode || replication.config.defaults.mode.get()
    return <span className={'mode ' + mode?.replaceAll("+", "-")}> {mode} </span>
  }

  const statusTemplate = (data: StreamKeyRecord, column: ColumnBodyOptions) => {
    let ok = (data.PK && data.PK.length) || data.UK || sourceConn.is_file.get()
    let color = ok ? 'green' : 'red'
    return <div>
      <i style={{ color }} className={ok ? "pi pi-check-circle" : "pi pi-times"}></i>
    </div>
  }

  return (
    <>
      {
        jobDialog.show.get() ?
        <ReplicationJobDialog
          show={jobDialog.show}
          name={jobDialog.name}
          replication={replication}
        />
        : null
      }
      <Dialog
        id='key-manager-dialog'
        header={<>Stream Key Manager for <code>incremental</code> Mode</>}
        modal={false}
        visible={props.show.get() || false}
        onHide={() => { cancelHide() }}
        footer={Footer}
      >
        <div
          // className="card"
          style={{
            width: '60rem',
            minHeight: '30rem',
          }}
        >
          <div className="grid">
            <div className="col-8">
              <div style={{maxWidth: '600px'}}>With <code>incremental</code> mode, the stream data will be merged or appended into the target table, depending on what keys are provided. You must provide at least a Primary key (PK) or an Update Key (UK), or both. See <a href="https://docs.slingdata.io/sling-cli/configuration#incremental-mode-strategies" target='_blank' rel="noopener noreferrer">here</a> for more details.</div>
              <p>Showing below only selected streams.</p>
            </div>

            {
              !sourceConn.is_file.get() ?
              <>
                <div className="col-3">
                  <span className="flex justify-content-evenly">
                  Click the Bolt icon to have Sling attempt to auto-detect PKs & UKs by name.
                  </span>
                  <br/> 
                </div>
                <div className="col-1 flex align-content-center">
                  <Tooltip target={'#detect-keys-icon'} position='right' style={{maxWidth: '400px'}}>
                    Sling will use the columns names to try to determine which column is a Primary key and which column is an Update Key. Typically, PKs are named some variation of <code>id</code> and UKs are named similar to <code>last_updated</code>. <strong>This will only be applied to streams with no keys selected.</strong> 
                  </Tooltip>
                  <span id='detect-keys-icon' className="flex justify-content-center">
                    <i
                      style={{ color: 'blue', cursor: "pointer", fontSize: '25px' }} 
                      className={"pi pi-bolt mt-3"}
                      onClick={() => { autoDetectKeys() }}
                    />
                  </span>
                </div>
              </>
              : null
            }
          </div>


          <DataTable
            loading={loading.get()}
            responsiveLayout="scroll"
            value={records.get()}
            scrollable
            scrollHeight='20rem'
            emptyMessage='No streams selected.'
          >
            <Column 
              header="Stream Name"
              className="flex justify-content-center column-wrap"
              body={nameTemplate}
            />
            <Column
              header="Mode"
              className="flex justify-content-center"
              headerStyle={{maxWidth: '9em', textAlign: 'center'}}
              bodyStyle={{maxWidth: '9em', textAlign: "center"}}
              body={modeTemplate}
            />
            <Column
              header="Primary Key"
              className="flex justify-content-center"
              headerStyle={{maxWidth: '11em', textAlign: 'center'}}
              bodyStyle={{maxWidth: '11em', textAlign: "center"}}
              body={(data: any) => colKeyBody('PK', data)}
            />
            <Column
              header="Update Key"
              className="flex justify-content-center"
              headerStyle={{maxWidth: '11em', textAlign: 'center'}}
              bodyStyle={{maxWidth: '11em', textAlign: "center"}}
              body={(data: any) => colKeyBody('UK', data)}
            />
            <Column
              header="OK"
              headerStyle={{maxWidth: '4em', textAlign: 'center'}}
              bodyStyle={{maxWidth: '4em', textAlign: "center"}}
              body={statusTemplate}
            />
          </DataTable>
        
        </div>
      </Dialog>
    
    </>
  );
};