import { toastError } from "../utilities/methods";
import { metadataReq } from "./api/api";
import { Connection } from "./connection";
import { SourceOptions } from "./job";



export interface ColRecord {
  column_id: number;
  column_name: string;
  column_type: string;
  primary_key?: boolean;
  update_key?: boolean;
}


export const getSchemas = async (conn: Connection) => {
  interface Record {
    schema_name: string;
  }
  let records: Record[] = []
  
  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_schemas",
    }
    let resp = await metadataReq(data)
    records = resp.records()
  } catch (error) {
    toastError(`Could not get schemas for ${conn.name}`, error)
  }
  return records
}

export const getTables = async (conn: Connection, schema: string) => {
  interface Record {
    table_name: string;
  }
  let records: Record[] = []

  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_tables",
      schema: schema
    }
    let resp = await metadataReq(data)
    records = resp.records()
  } catch (error) {
    toastError(`Could not get tables for ${conn.name}, schema ${schema}`, error)
  }
  return records
}

export const discoverStreams = async (conn: Connection) => {
  interface Record {
    stream_name: string;
    column_name: string;
    column_type: string;
    primary_key: boolean;
    update_key: boolean;
  }
  let records: Record[] = []

  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "discover",
    }

    let resp = await metadataReq(data)
    records = resp.records()
  } catch (error) {
    toastError(`Could not discover streams for ${conn.name}`, error)
  }
  return records
}

export const getViews = async (conn: Connection, schema: string) => {
  interface Record {
    table_name: string;
  }
  let records: Record[] = []

  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_views",
      schema: schema
    }
    let resp = await metadataReq(data)
    records = resp.records()
  } catch (error) {
    toastError(`Could not get views for ${conn.name}, schema ${schema}`, error)
  }
  return records
}

export const getTableColumns = async (conn: Connection, table: string) => {
  interface Record {
    schema_name: string;
    table_name: string;
    table_type: string;
    column_name: string;
    data_type: string;
    position: number;
  }
  let records: ColRecord[] = []

  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_columns",
      table: table
    }
    let resp = await metadataReq(data)
    let recs : Record[] = resp.records()
    for(let rec of recs) {
      records.push({
        column_id: rec.position,
        column_name: rec.column_name,
        column_type: rec.data_type,
      })
    }
  } catch (error) {
    toastError(`Could not get columns for ${conn.name}, table ${table}`, error)
  }
  return records
}

export type StreamColumnMap = { [key: string]: ColRecord[]; }

export const getManyTableColumns = async (conn: Connection, tables: string[]) => {
  interface Record {
    schema_name: string;
    table_name: string;
    is_view: boolean;
    column_name: string;
    column_type: string;
    column_id: number;
  }
  let tableColumnMap : StreamColumnMap = {}

  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_schemata",
      tables: tables
    }
    let resp = await metadataReq(data)
    let recs : Record[] = resp.records()
    for(let rec of recs) {
      let key = conn.make_key_identifier(rec.schema_name, rec.table_name)
      if(key in tableColumnMap) {
        tableColumnMap[key].push({
          column_id: rec.column_id,
          column_name: rec.column_name,
          column_type: rec.column_type,
        })
      } else {
        tableColumnMap[key] = [{
          column_id: rec.column_id,
          column_name: rec.column_name,
          column_type: rec.column_type,
        }]
      }
    }
  } catch (error) {
    toastError(`Could not get columns for ${conn.name}`, error)
  }
  return tableColumnMap
}

export const getSqlColumns = async (conn: Connection, query: string) => {

  let records : ColRecord[] = []
  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_sql_columns",
      query: query
    }
    let resp = await metadataReq(data)
    records = resp.records() as ColRecord[]
  } catch (error) {
    toastError(`Could not get sql columns for ${conn.name}`, error)
  }
  return records
}

export const getFileColumns = async (conn: Connection, path: string, options?: SourceOptions) => {

  let records : ColRecord[] = []
  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_file_columns",
      options: options,
      path: path,
    }
    let resp = await metadataReq(data)
    records = resp.records() as ColRecord[]
  } catch (error) {
    toastError(`Could not get file columns for ${conn.name}`, error)
  }
  return records
}

export const getFileList = async (conn: Connection, path: string) => {

  interface Record {
    name: string;
    is_dir: boolean;
  }
  let records : Record[] = []
  try {
    let data = {
      conn_id: conn.id as string,
      procedure: "get_list",
      path: path,
    }
    let resp = await metadataReq(data)
    records = resp.records() as Record[]
  } catch (error) {
    toastError(`Could not get file list for ${conn.name}`, error)
  }
  return records
}


/*eslint-disable */
export const setKeys = (recs: ColRecord[], pks: string[], uk: string) => {
  // set primary_key
  recs.map(r => { r.primary_key = false}) // set all false first
  recs.filter(r => pks?.includes(r.column_name?.toLowerCase()))
        .map(r => { r.primary_key = true})

  // set update_key
  recs.map(r => { r.update_key = false}) // set all false first
  recs.filter(r => uk === r.column_name?.toLowerCase())
        .map(r => { r.update_key = true})
}
/*eslint-enable */