import { State } from "@hookstate/core";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { rudderstack } from "..";
import { sling } from "../App";
import { connRef, getPrefix } from "../core/connection";
import { JobMode, Compression, jobModeDescription, DefaultSourceOptionsFile, DefaultTargetOptionsDB, DefaultTargetOptionsFile, Format } from "../core/job";
import { getDefaults, Replication, ReplicationStreamConfig } from "../core/replication";
import { useHookState } from "../core/store";
import { JobSchedule } from "./JobSchedule";
import { KeyManager } from "./KeyManager";

interface Props {
  loading: State<boolean>;
  refresh: State<number>;
  replication: State<Replication>
}

export const ReplicationPanelSettings: React.FC<Props> = (props) => {
  const replication = useHookState(props.replication)
  const sourceConn = useHookState(sling.state.connections[replication.source_id.get()])
  const targetConn = useHookState(sling.state.connections[replication.target_id.get()])
  const history = useHistory()
  const showKeyManager = useHookState(false)
  
  React.useEffect(() => { 
    let data = { replication_id: replication.id.get() }
    rudderstack.page(document.title, 'ReplicationPanelSettings', sling.rudderProperties(data))
  }, []); // eslint-disable-line
  
  return(
    <>
      <div
        className="grid"
        style={{
          maxHeight: `${window.innerHeight - 270}px`,
          overflowY: 'scroll',
        }}
      >
        <div className="col-3"></div>
        <div className="col-6">

            <div className="card p-fluid">
                <h5>Schedule</h5>
                <JobSchedule schedule={replication.config.defaults.schedule} />
            </div>

            <div className="card p-fluid">
                <h5>Default Target Object Name Pattern</h5>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="input-object-name">Name Pattern</label>
                        <div className="p-inputgroup">
                          <InputText
                            id="input-object-name"
                            type="text"
                            value={replication.config.defaults.object.get()}
                            onInput={(e:any) => {
                              let val = e.target.value as string
                              let prefix = getPrefix(targetConn.get())
                              val = val.startsWith(prefix) ? val : prefix
                              replication.config.defaults.object.set(val)
                            }}
                          />
                          <Button
                            icon="pi pi-sync"
                            className="p-button-info"
                            tooltip="Reset Name Pattern"
                            onClick={() => {
                              let { object_pattern } = getDefaults(sourceConn.get(), targetConn.get())
                              replication.config.defaults.object.set(object_pattern)
                            }}
                          />
                        </div>
                        <small>Enter the pattern to use for naming objects in target connection. See <a target='_blank' href="https://docs.slingdata.io/sling-cli/replication#object-pattern-options" rel="noopener noreferrer">here</a> for documentation.</small>
                    </div>
                </div>
          </div>
          
            <div className="card p-fluid">
              <h5>Default Mode</h5>
              <div className="field">
                  <Dropdown
                    id="form-mode"
                    className="p-inputtext-sm"
                    options={Object.values(JobMode)}
                    value={ replication.config.defaults.mode.get() }
                    onChange={(e)=>{ 
                      replication.config.defaults.mode.set(e.target.value) 
                    }}
                    tooltip={jobModeDescription(replication.config.defaults.mode.get())}
                    tooltipOptions={{position: 'left', style: {maxWidth: '300px'}}}
                  />
                  <br />
              </div>
              {
                replication.config.defaults.mode.get() === JobMode.IncrementalMode && targetConn.get().is_database ?
                <>
                  <KeyManager replication={replication} show={showKeyManager}/>
                  <Button
                    label="Manage Keys"
                    tooltip="Manage Primary Keys & Update Keys"
                    tooltipOptions={{ position: 'bottom' }}
                    className="p-button-rounded p-button-info"
                    onClick={async (e) => { 
                      showKeyManager.set(true)
                    }}
                  />
                </>
                : null
              }
            </div>
            
            <StreamSourceOptions replication={replication} streamConfig={replication.config.defaults} />
            <StreamTargetOptions replication={replication} streamConfig={replication.config.defaults} />
          
            <div className="card p-fluid">
              <h5>Default Retries</h5>
              <div className="field">
                  <InputNumber
                    inputId="minmax-buttons"
                    value={replication.settings.retries.get()}
                    onValueChange={(e) => replication.settings.retries.set(e.value || 0)}
                    mode="decimal"
                    showButtons
                    min={0}
                    max={3}
                  />
                    <small>The number of retries if a task fails.</small>
                  <br />
              </div>
            </div>
          
            <div className="card p-fluid">
                <h5>Danger Zone</h5>
                <Button
                  type="button" icon="pi pi-times"
                  className="p-button-danger"
                  label="Delete Replication"
                  disabled={!sling.userAtLeastPower || sling.isDemoUser}
                  onClick={() => {
                    confirmDialog({
                      message: 'Are you sure you want to delete?',
                      header: 'Confirmation',
                      icon: 'pi pi-exclamation-triangle',
                      accept: async () => {
                        if(await sling.deleteReplication(replication.get())) {
                          await sling.loadReplications()
                          history.push(`/replications`)
                        }
                      },
                    })
                  }}
                />
            </div>
        </div>
        <div className="col-3"></div>
      </div>
    </>
  )
}

export const StreamSourceOptions = (props: {
  replication: State<Replication>,
  streamConfig: State<ReplicationStreamConfig>,
}) => {
  const replication = props.replication
  const streamConfig = props.streamConfig

  const defaultBoolean = (val: boolean | undefined, defVal: boolean) => val === undefined ? defVal: val
  const defaultString = (val: string | undefined, defVal: string) => val === undefined ? defVal: val

  return <>
  
      {/* SOURCE OPTIONS */}
      {
        connRef(replication.source_id.get()).is_file ? 
        <div className="card p-fluid">
          <h5>Default Source Options</h5>
          <div className="grid p-fluid">
            <div className="field col-2">
                <p>Header</p>
                <Checkbox
                  inputId='options-header'
                  tooltip="Whether to treat the first line as columns names"
                  tooltipOptions={{position: 'bottom'}}
                  onChange={async (e) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.header = e.checked
                        if(e.checked === DefaultSourceOptionsFile.header) delete opts.header
                        return opts
                      }
                    )
                  }}
                  checked={
                    defaultBoolean(streamConfig.source_options.get()?.header, DefaultSourceOptionsFile.header)
                  }
                />
            </div>

            <div className="field col-3">
                <p>Empty as Null</p>
                <Checkbox
                  inputId='options-header'
                  tooltip="Whether to treat an empty value as null"
                  tooltipOptions={{position: 'bottom'}}
                  onChange={async (e) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.empty_as_null = e.checked
                        if(e.checked === DefaultSourceOptionsFile.empty_as_null) delete opts.empty_as_null
                        return opts
                      }
                    )
                  }}
                  checked={ 
                    defaultBoolean(streamConfig.source_options.get()?.empty_as_null, DefaultSourceOptionsFile.empty_as_null)
                  }
                />
            </div>

            <div className="field col-3">
                <p>Skip Blank Lines</p>
                <Checkbox
                  inputId='options-trim-space'
                  tooltip="Whether to skip blank lines"
                  tooltipOptions={{position: 'bottom'}}
                  onChange={async (e) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.skip_blank_lines = e.checked
                        if(e.checked === DefaultSourceOptionsFile.skip_blank_lines) delete opts.skip_blank_lines
                        return opts
                      }
                    )
                  }}
                  checked={ 
                    defaultBoolean(streamConfig.source_options.get()?.skip_blank_lines, DefaultSourceOptionsFile.skip_blank_lines)
                  }
                />
            </div>

            <div className="field col-4">
                <label htmlFor="options-date-format">Date Format</label>
                <InputText
                  id="options-date-format"
                  type="text"
                  tooltip="Specific date format to use"
                  tooltipOptions={{position: 'bottom'}}
                  value={ 
                    defaultString(streamConfig.source_options.get()?.datetime_format, DefaultSourceOptionsFile.datetime_format)
                  }
                  onInput={(e:any) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.datetime_format = e.target.value
                        if(e.target.value === DefaultSourceOptionsFile.datetime_format) delete opts.datetime_format
                        return opts
                      }
                    )
                  }}
                />
            </div>

            <div className="field col-3">
                <label htmlFor="options-format">File Format</label>
                <Dropdown
                  id="options-format"
                  options={ Object.values(Format) }
                  value={ 
                    defaultString(streamConfig.source_options.get()?.format, DefaultSourceOptionsFile.format)
                  }
                  onChange={(e)=>{
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.format = e.target.value
                        if(e.target.value === DefaultSourceOptionsFile.format) delete opts.format
                        return opts
                      }
                    )
                  }}
                />
            </div>

            <div className="field col-3">
                <label htmlFor="options-compression">Compression</label>
                <Dropdown
                  id="options-compression"
                  options={ Object.values(Compression) }
                  value={ 
                    defaultString(streamConfig.source_options.get()?.compression, DefaultSourceOptionsFile.compression)
                  }
                  onChange={(e)=>{
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.compression = e.target.value
                        if(e.target.value === DefaultSourceOptionsFile.compression) delete opts.compression
                        return opts
                      }
                    )
                  }}
                />
            </div>

            <div className="field col-2">
                <label htmlFor="options-header">Delimiter</label>
                <InputText
                  id="options-delimiter"
                  type="text"
                  value={ 
                    defaultString(streamConfig.source_options.get()?.delimiter, DefaultSourceOptionsFile.delimiter)
                  }
                  onInput={(e:any) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.delimiter = e.target.value
                        if(e.target.value === DefaultSourceOptionsFile.delimiter) delete opts.delimiter
                        return opts
                      }
                    )
                  }}
                />
            </div>

            <div className="field col-2">
                <p>Trim Space</p>
                <Checkbox
                  inputId='options-trim-space'
                  tooltip="Whether to remove leading/trailing white space"
                  tooltipOptions={{position: 'bottom'}}
                  onChange={async (e) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.trim_space = e.checked
                        if(e.checked === DefaultSourceOptionsFile.trim_space) delete opts.trim_space
                        return opts
                      }
                    )
                  }}
                  checked={ 
                    defaultBoolean(streamConfig.source_options.get()?.trim_space, DefaultSourceOptionsFile.trim_space)
                  }
                />
            </div>

            <div className="field col-2">
                <p>Flatten</p>
                <Checkbox
                  inputId='options-header'
                  tooltip="Whether to flatten a nested value (applies to JSON or XML source files)"
                  tooltipOptions={{position: 'bottom'}}
                  onChange={async (e) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.flatten = e.checked
                        if(e.checked === DefaultSourceOptionsFile.flatten) delete opts.flatten
                        return opts
                      }
                    )
                  }}
                  checked={ 
                    defaultBoolean(streamConfig.source_options.get()?.flatten, DefaultSourceOptionsFile.flatten)
                  }
                />
            </div>

            <div className="field col-4">
                <label htmlFor="options-jmespath">JMESPath</label>
                <InputText
                  id="options-date-jmespath"
                  type="text"
                  tooltip="Specify a JMESPath expression to use to filter / extract nested JSON data. See https://jmespath.org/ for more"
                  tooltipOptions={{position: 'bottom', style: {maxWidth: '320px'}}}
                  value={ 
                    defaultString(streamConfig.source_options.get()?.jmespath, DefaultSourceOptionsFile.jmespath)
                  }
                  onInput={(e:any) => {
                    streamConfig.source_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.jmespath = e.target.value
                        if(e.target.value === DefaultSourceOptionsFile.jmespath) delete opts.jmespath
                        return opts
                      }
                    )
                  }}
                />
            </div>
          </div>
        </div>
        :
        null
      }
  
  </>
}

export const StreamTargetOptions = (props: {
  replication: State<Replication>,
  streamConfig: State<ReplicationStreamConfig>,
}) => {
  const replication = props.replication
  const streamConfig = props.streamConfig
  const defaultBoolean = (val: boolean | undefined, defVal: boolean) => val === undefined ? defVal: val
  const defaultString = (val: string | undefined, defVal: string) => val === undefined ? defVal: val

  return <>

      {/* TARGET OPTIONS */}
      {
        connRef(replication.target_id.get()).is_database ? 
        <div className="card p-fluid">
          <h5>Default Target Options</h5>
          <div className="grid p-fluid">
            <div className="field col-4">
                <p>Use Bulk Loading</p>
                <Checkbox
                  inputId='options-bulk-loading'
                  tooltip="Whether to use bulk loading or batch INSERT transactions (Bulk loading is typically faster)"
                  tooltipOptions={{position: 'bottom', style: {maxWidth: '250px'}}}
                  onChange={async (e) => {
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.use_bulk = e.checked
                        if(e.checked === DefaultTargetOptionsDB.use_bulk) delete opts.use_bulk
                        return opts
                      }
                    )
                  }}
                  checked={
                    defaultBoolean(streamConfig.target_options.get()?.use_bulk, DefaultTargetOptionsDB.use_bulk)
                  }
                />
            </div>
            <div className="field col-4">
                <p>Add New Columns</p>
                <Checkbox
                  inputId='options-new-columns'
                  tooltip="Whether to auto add in destination table newly added source stream columns"
                  tooltipOptions={{position: 'bottom', style: {maxWidth: '250px'}}}
                  onChange={async (e) => {
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.add_new_columns = e.checked
                        if(e.checked === DefaultTargetOptionsDB.add_new_columns) delete opts.add_new_columns
                        return opts
                      }
                    )
                  }}
                  checked={
                    defaultBoolean(streamConfig.target_options.get()?.add_new_columns, DefaultTargetOptionsDB.add_new_columns)
                  }
                />
            </div>
          </div>
        </div>
        :
        null
      }

      {
        connRef(replication.target_id.get()).is_file ? 
        <div className="card p-fluid">
          <h5>Default Target Options</h5>
          <div className="grid p-fluid">
            <div className="field col-2">
                <p>Header</p>
                <Checkbox
                  inputId='options-header'
                  tooltip="Write the first line with columns names"
                  tooltipOptions={{position: 'bottom'}}
                  onChange={async (e) => {
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.header = e.checked
                        if(e.checked === DefaultTargetOptionsFile.header) delete opts.header
                        return opts
                      }
                    )
                  }}
                  checked={
                    defaultBoolean(streamConfig.target_options.get()?.header, DefaultTargetOptionsFile.header)
                  }
                />
            </div>

            <div className="field col-2">
                <p>Single</p>
                <Checkbox
                  inputId='options-header'
                  tooltip="Whether to write to a single file, or allow multiple partitions (faster)"
                  tooltipOptions={{position: 'bottom'}}
                  onChange={async (e) => {
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        if(e.checked) {
                          opts.file_max_bytes = 0
                          opts.file_max_rows = 0
                        } else {
                          opts.file_max_bytes = 2000000
                          opts.file_max_rows = 500000
                        }
                        return opts
                      }
                    )
                  }}
                  checked={ streamConfig.target_options.get()?.file_max_rows === 0 }
                />
            </div>

            <div className="field col-2">
                <label htmlFor="options-header">Delimiter</label>
                <InputText
                  id="options-delimiter"
                  type="text"
                  value={ 
                    defaultString(streamConfig.target_options.get()?.delimiter, DefaultTargetOptionsFile.delimiter)
                  }
                  onInput={(e:any) => {
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.delimiter = e.target.value
                        if(e.target.value === DefaultTargetOptionsFile.delimiter) delete opts.delimiter
                        return opts
                      }
                    )
                  }}
                />
            </div>

            <div className="field col-5">
                <label htmlFor="options-format">Format</label>
                <Dropdown
                  id="options-format"
                  options={ Object.values(Format) }
                  value={ 
                    defaultString(streamConfig.target_options.get()?.format, DefaultTargetOptionsFile.format)
                  }
                  onChange={(e)=>{
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.format = e.target.value
                        if(e.target.value === DefaultTargetOptionsFile.format) delete opts.format
                        return opts
                      }
                    )
                  }}
                />
            </div>

            <div className="field col-5">
                <label htmlFor="options-compression">Compression</label>
                <Dropdown
                  id="options-compression"
                  options={ Object.values(Compression) }
                  value={ 
                    defaultString(streamConfig.target_options.get()?.compression, DefaultTargetOptionsFile.compression)
                  }
                  onChange={(e)=>{
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.compression = e.target.value
                        if(e.target.value === DefaultTargetOptionsFile.compression) delete opts.compression
                        return opts
                      }
                    )
                  }}
                />
            </div>

            <div className="field col-12">
                <label htmlFor="options-date-format">Date Format</label>
                <InputText
                  id="options-date-format"
                  type="text"
                  tooltip="Specific date format to use"
                  tooltipOptions={{position: 'bottom'}}
                  value={ 
                    defaultString(streamConfig.target_options.get()?.datetime_format, DefaultTargetOptionsFile.datetime_format)
                  }
                  onInput={(e:any) => {
                    streamConfig.target_options.set(
                      opts => {
                        if(!opts) opts = {}
                        opts.datetime_format = e.target.value
                        if(e.target.value === DefaultTargetOptionsFile.datetime_format) delete opts.datetime_format
                        return opts
                      }
                    )
                  }}
                />
                <small>See <a target='_blank' href="https://docs.slingdata.io" rel="noopener noreferrer">here</a> for date formatting documentation.</small>
            </div>
          </div>
        </div>
        :
        null
      }
  
  </>
}