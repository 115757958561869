import { ObjectAny } from "../../utilities/interfaces"
import { serialize } from "../../utilities/methods"


export const isDev = window.location.href.includes('://localhost')
export const isStaging = window.location.href.includes('.sling.vinedatalabs.com') || window.location.href.includes("sling-cloud.pages.dev")
export const isProd = window.location.href.includes('app.slingdata.io')
export const isDemo = window.location.href.includes('demo.slingdata.io')
export const urlHasModeDemo = window.location.href.includes('?mode=demo')

export const masterURL = isStaging ? "https://api.sling.vinedatalabs.com" : 
                  isDemo ? 'https://apidemo.slingdata.io' : 
                  isProd ? 'https://api.slingdata.io' : 
                  isDev ? "http://localhost:4000" : window.location.origin

export const environment = isStaging ? "staging" : 
                  isDemo ? 'demo' : 
                  isProd ? 'production' : 
                  isDev ? "development" : 'other'

/** Responsible for making all non-streaming backend calls */
export class Http { 
  constructor(data: ObjectAny = {}) { } // eslint-disable-line
} 

export interface HttpResponse {
  // response: globalThis.Response
  status: number
  statusText: string
  data: any
  error: string
}

export const apiPost = async (route: string, payload={}, extraHeaders={}) => {
  let url = `${masterURL}${route}`
  let headers = Object.assign({ 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" }, extraHeaders)

  try {
    let response = await fetch(url, {
      method: 'post',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(payload),
    })
    let data = await response.json()

    if (response.status >= 400 && !data.error) data.error = response.statusText

    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
      error: data.error,
    } as HttpResponse
  } catch (error) {
    return {
      status: 555,
      statusText: `${error}`,
      data: {},
      error: `${error}`,
    } as HttpResponse
  }
}

export const apiGet = async (route: string, payload: ObjectAny = {}, extraHeaders = {}) => {
  for (let k of Object.keys(payload)) {
    if(!payload[k] && payload[k] !== 0) delete payload[k]
  }
  let url = `${masterURL}${route}?${serialize(payload)}`
  let headers = Object.assign({ 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" }, extraHeaders)

  try {
    let response = await fetch(url, { headers, credentials: 'include' })
    let data = await response.json()
    if (response.status >= 400 && !data.error) data.error = response.statusText
    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
      error: data.error,
    } as HttpResponse
  } catch (error) {
    return {
      status: 555,
      statusText: `${error}`,
      data: {},
      error: `${error}`,
    } as HttpResponse
  }
}


export const apiPatch = async (route: string, payload={}, extraHeaders={}) => {
  let url = `${masterURL}${route}`
  let headers = Object.assign({ 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" }, extraHeaders)

  try {
    let response = await fetch(url, {
      method: 'patch',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(payload),
    })
    let data = await response.json()

    if (response.status >= 400 && !data.error) data.error = response.statusText

    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
      error: data.error,
    } as HttpResponse
  } catch (error) {
    return {
      status: 555,
      statusText: `${error}`,
      data: {},
      error: `${error}`,
    } as HttpResponse
  }
}

export const apiPut = async (route: string, payload={}, extraHeaders={}) => {
  let url = `${masterURL}${route}`
  let headers = Object.assign({ 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" }, extraHeaders)

  try {
    let response = await fetch(url, {
      method: 'put',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(payload),
    })
    let data = await response.json()

    if (response.status >= 400 && !data.error) data.error = response.statusText

    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
      error: data.error,
    } as HttpResponse
  } catch (error) {
    return {
      status: 555,
      statusText: `${error}`,
      data: {},
      error: `${error}`,
    } as HttpResponse
  }
}

export const apiDelete = async (route: string, payload={}, extraHeaders={}) => {
  let url = `${masterURL}${route}`
  let headers = Object.assign({ 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" }, extraHeaders)

  try {
    let response = await fetch(url, {
      method: 'delete',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(payload),
    })
    let data = await response.json()

    if (response.status >= 400 && !data.error) data.error = response.statusText

    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
      error: data.error,
    } as HttpResponse
  } catch (error) {
    return {
      status: 555,
      statusText: `${error}`,
      data: {},
      error: `${error}`,
    } as HttpResponse
  }
}