import { ObjectAny } from "../utilities/interfaces"
import { md5 } from "../utilities/methods";
import { JobType } from "./job"

export type ExecutionMap = { [key: string]: Execution; }

export enum ExecStatus {
  Created = "created",
  Queued = "queued",
  Started = "started",
  Running = "running",
  Success = "success",
  Terminated = "terminated",
  Interrupted = "interrupted",
  TimedOut = "timed-out",
  Error = "error",
  Skipped = "skipped",
  Stalled = "stalled",
}

export class RecentExec {
  id: string
  status: ExecStatus
  duration: number
  rows: number
  epoch: number
  constructor(data: any[]) {
    this.id = data[0]
    this.status = data[1]
    this.duration = data[2]
    this.rows = data[3]
    this.epoch = data[4] || 0
  }
}

export class Execution {
  id: number
  job_id: number
  job_name: string
  replication_id: number
  stream_id: string
  type: JobType
  worker_id: string
  status: ExecStatus
  err: string
  src_conn: string
  tgt_conn: string
  start_time?: number
  end_time?: number
  bytes: number
  exit_code: number
  output: string
  rows: number
  try_num: number
  prev_exec_id: number
  next_execution_time: number
  data: ObjectAny

  constructor(data: ObjectAny = {}) {
    this.id = data.id
    this.job_id = data.job_id
    this.job_name = data.job_name
    this.replication_id = data.replication_id
    this.stream_id = data.stream_id
    this.type = data.type
    this.worker_id = data.worker_id
    this.status = data.status
    this.err = data.err
    this.src_conn = data.src_conn
    this.tgt_conn = data.tgt_conn
    this.start_time = data.start_time || data.end_time || 0
    this.end_time = data.end_time || 0
    this.bytes = data.bytes || 0
    this.exit_code = data.exit_code
    this.output = data.output
    this.rows = data.rows || 0
    this.rows = data.rows
    this.try_num = data.try_num
    this.prev_exec_id = data.prev_exec_id
    this.next_execution_time = data.next_execution_time
    this.data = data.data || {
      job_name: data.job_name,
      duration: data.duration,
      percent: data.percent,
      avg_duration: data.avg_duration,
    }
  }

  get duration() { 
    let end = this.end_time as number
    let start = this.start_time as number

    if (!start || this.start_time === 0) return (this.data.duration || 0) as number
    if (!end || end === 0) end = new Date().getTime()/1000

    return Math.round(end - start)
  }

  get percent() { return this.data.percent as number|null }
  get avg_duration() { return this.data.avg_duration as number|null }
  get start_date() {
    if(this.start_time) return new Date(this.start_time * 1000)
    return undefined
  }
  get start_date_str() { return this.start_date?.toLocaleString() }
  get end_date() {
    if(this.end_time) {
      return new Date(this.end_time * 1000)
    }
    return undefined
  }
  get end_date_str() { return this.end_date?.toLocaleString() }

  get rank() {
    if(this.status === ExecStatus.Running) return 1
    if(this.status === ExecStatus.Queued) return 2
    if(this.status === ExecStatus.Started) return 2
    if(this.status === ExecStatus.Created) return 2
    if(this.status === ExecStatus.Skipped) return 3
    return 3
  }

  is_running() {
    return [ExecStatus.Queued, ExecStatus.Running, ExecStatus.Started].includes(this.status)
  }

  payload() {
    return {
      id: this.id
    }
  }
}

export interface ExecutionHeartbeat {
  id: number,
  replication_id: number,
  exec_id: number,
  status: ExecStatus,
  err: string,
  start_time: number,
  end_time: number,
  bytes: number,
  rows: number,
  rate: number,
  pid: number,
  cpu_pct: number,
  cpu_time: number,
  ram_pct: number,
  ram_rss: number,
  tx_bytes: number,
  rc_bytes: number,
  timestamp: number,
  job_id: number,
  percent: number,
  duration: number,
  avg_duration: number,
}

export interface ExecutionStatus {
  job_id: number
  exec_id: number
  status: ExecStatus
  text: string
  rows: number
  bytes: number
  percent: number
  stalled: boolean
  duration?: number
  avg_duration: number
}

export interface TaskProcess {
  job_id: number,
  exec_id: number,
  pid: number,
  output: string,
  start_time: number,
  end_time: number,
  exit_code?: number,
}


export const getStreamId = (source_id: string, target_id: string, name: string) => {
  return md5(`${source_id}${target_id}${name.toLowerCase()}`)
}

export const toGB = (b: number) => b / 1024 / 1024 / 1024
export const toBytes = (b: number, round=false) => {
  let value = 0
  let suffix = ''
  if(b > 1024 * 1024 * 1024) {
    value = Math.round(b / 1024 / 1024) / 1024
    suffix = 'GB'
  } else if(b > 1024 * 1024) {
    value = Math.round(b / 1024) / 1024
    suffix = 'MB'
  } else {
    value = Math.round(b) / 1024
    suffix = 'KB'
  }
  if (round) {
    if(value < 100) value = Math.round(value * 10) / 10
    else value = Math.round(value)
    return `${value.toLocaleString()} ${suffix}`
  }
  return `${value.toLocaleString()} ${suffix}`
}