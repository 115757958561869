import { ObjectAny } from "../utilities/interfaces";
import { ConnSpec } from "./connection";
import { Plan } from "./project";

export type SettingsLevel = 'app' | 'project' | 'user'


export interface SlackSetting {
  webhook_url: string
}

export interface MsTeamsSetting {
  webhook_url: string
}

export class ProjectSetting {
  slack: SlackSetting
  msteams: MsTeamsSetting
  webhooks: ObjectAny
  replication_notifications: { [key: number]: NotificationSetting; }

  constructor(data: ObjectAny={}) {
    this.slack = data.slack || {}
    this.msteams = data.msteams || {}
    this.webhooks = data.webhooks || {}
    this.replication_notifications = data.replication_notifications || {}
  }
}

export interface UserSetting {
  notify: boolean
}


export interface NotificationSetting {
  email?: boolean
  slack?: boolean
  msteams?: boolean
  on_success?: boolean
  on_failure?: boolean
  on_linger?: boolean
  on_empty?: boolean
}

interface Product {
  id: string;
  description: string;
  price_id: string;
  unit_price: number;
}

interface PlanDetails {
  name: Plan;
  description: string;
  base_product: Product;
  usage_product: Product;
  package_gb: number;
}

export interface AppSetting {
  backend_version: string
  frontend_version: string
  ssh_public_key: string
  plans: { [key: string]: PlanDetails }
  free_weekly_gb_limit: number
  region_ips: { [key: string]: string[] }
  connection_specs: { [key: string]: ConnSpec }
}


export class Settings {
  app: AppSetting
  project: ProjectSetting
  user: UserSetting
  constructor(data: ObjectAny = {}) {
    this.app = data.app || {
      ssh_public_key: 'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQD4Lo0VGse+3nPkeUwayKd6CVlZmpVcPDhmMbSoV8aQLG/blUYuN3OQ65+rCHYy2fA8LVa07Y5mrqZhksoUjOP4RlvhDZ+wj2iCRuGHyEt+W/6SJwl5E4AbEma89tTWiQFRQK2ZmoFc4v42JqdEX5OKwxcvH4QG1G70l8RUSWYuahM04K30fW+ukpCBRPE9k5nEnsPk5NOMbOl9wAr1dh2M/3Xd6M7UR2Te2dM3NfiAdZpO8V0ZCCIZDWu6lNZMtxv6j9Xz+ZV8v6NHVhB4nSnkEVCDhegjsbB8XFYIobhMqqZOoYDO6UmzL318/u/CDZDq/QUzu3lNJv+iDefLLWkF',
      connection_specs: data.connection_specs || {},
      plans: data.plans || {},
      free_weekly_gb_limit: data.free_weekly_gb_limit || 1,
      region_ips: data.regions_ips || {},
    }
    this.user = data.user || {}
    this.project = new ProjectSetting(data.project || {})
    this.app.backend_version = this.app.backend_version || ''
    this.app.frontend_version = this.app.frontend_version || ''
  }
}