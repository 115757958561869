import { State } from "@hookstate/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import * as React from "react";
import { useHistory } from "react-router-dom";
import { sling } from "../App";
import { Message, MessageScopeLevel, MessageType, Ws } from "../core/api/ws";
import { ExecStatus, Execution, ExecutionHeartbeat, ExecutionStatus, TaskProcess, toBytes } from "../core/execution";
import { searchString, toastInfo } from "../utilities/methods";
import { t } from "../utilities/translator";
import { useHookState } from "../core/store";
import { rudderTrack } from "..";

interface Props {
  show: State<boolean>
  execution: State<Execution>
}

export const ExecutionDialog: React.FC<Props> = (props) => {
  const execution = props.execution
  ///////////////////////////  HOOKS  ///////////////////////////
  const history = useHistory()
  const logDebugMode = useHookState(false)
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    // subscribe for execution scope if execution is pending
    const pendingStatus = [ExecStatus.Created, ExecStatus.Queued, ExecStatus.Running]
    let ws = new Ws()
    if(pendingStatus.includes(props.execution.status.get())) {
      ws.connect(processWsData).then(
        () => {
          ws.subscribe({
            level: MessageScopeLevel.Execution,
            project_id: sling.state.project.id.get(),
            exec_id: props.execution.id.get(),
          })
        }
      )
    }

    rudderTrack('execution', 'execution-dialog', sling.rudderProperties({execution_id: execution.id.get(), job_id: execution.job_id.get()}))

    return () => {
      ws.close()
    }
  }, []); // eslint-disable-line
  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const processWsData = (msg: Message) => {
    if(msg.type === MessageType.ExecutionHeartbeat) {
      let ehb = msg.data as ExecutionHeartbeat
      execution.set(exec => {
        exec.status = ehb.status
        exec.rows = ehb.rows
        exec.bytes = ehb.bytes
        exec.start_time = ehb.start_time
        exec.end_time = ehb.end_time
        return exec
      })
    }

    if(msg.type === MessageType.ExecStatus) {
      let es = msg.data as ExecutionStatus
      execution.set(exec => {
        exec.status = es.status
        exec.rows = es.rows
        exec.bytes = es.bytes
        exec.data.percent = es.percent
        exec.data.avg_duration = es.avg_duration
        return exec
      })
    }

    if(msg.type === MessageType.TaskProcess) {
      let tp = msg.data as TaskProcess
      props.execution.output.set(o => {
        o = o + tp.output
        return o
      })

      // scroll down to bottom of log?
      var textarea = document.getElementById('log-text');
      if(textarea) textarea.scrollTop = textarea.scrollHeight;
    }
  }

  const getOutput = () => {
    let output = props.execution.output.get() + '\n' + (props.execution.err.get() || '')
    output = filterLines(output, logDebugMode.get())?.join('\n') || ''
    output = "Execution #" + (props.execution.id.get()?.toString() || '') + '\n' + output
    return output
  }

  ///////////////////////////  JSX  ///////////////////////////

  const title = props.execution.job_name.get() + ' (' + props.execution.src_conn.get() + ' -> ' + props.execution.tgt_conn.get() + ')'

  const header = <>
    <span title={title}>{ title.length > 80 ? title.slice(0, 80) + '...' : title}</span>
  </>

  const Footer = <>
    <div className="flex align-items-center justify-content-center">

      {
        props.execution.get().is_running() ?
        <Button
          type="button"
          disabled={!sling.userAtLeastPower}
          icon="pi pi-times"
          className="p-button-danger"
          label={t('Terminate')}
          onClick={async () => {
            await sling.terminateExecution(props.execution.id.get())
          }}
        />
        :
        <Button
          type="button"
          disabled={!sling.userAtLeastPower}
          icon="pi pi-play"
          className="p-button-success"
          label={t('Execute')}
          onClick={async () => {
            if(await sling.executeJob(props.execution.stream_id.get())) {
              toastInfo(`Triggered "${props.execution.job_name.get()}"`)
            }
          }}
        />
      }
      
      {
        !window.location.href.includes('/replication?') ?
        <>
          <Button
            label={t('Config')}
            disabled={!sling.userAtLeastPower}
            onClick={() => {
              let rid = props.execution.replication_id.get()
              let name = props.execution.job_name.get()
              sling.state.temp.job_config_name.set(name)
              history.push(`/replication?rid=${rid}`)
            }}
            className="p-button-info p-ml-4"
          />
          <Button
            label={t('Replication')}
            onClick={() => {
              let rid = props.execution.replication_id.get()
              history.push(`/replication?rid=${rid}`)
            }}
            className="p-button-info p-ml-4"
          />
        </>
        :
        null
      }

      <Button 
        label={t('Close')}
        onClick={() => props.show.set(false)}
        icon="pi pi-times"
        className="p-button-secondary p-mr-4"
        style={{marginRight: '10px'}}
      />

    </div>
  </>

  return (
    <>
    <Dialog
      header={header}
      visible={props.show.get() || false}
      onHide={() => { props.show.set(false) }}
      footer={Footer}
      closeOnEscape
      dismissableMask
    >
      <div
        style={{
          width: '60rem',
          minHeight: '38rem',
          marginBottom: '-16px',
        }}
      >
      
      <div className="grid">
        <div className="col-3">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}>Status</h4>
        </div>
        <div className="col-3">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}> Start Time</h4>
        </div>
        <div className="col-3">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}> End Time</h4>
        </div>
        <div className="col-3">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}> Rows / Bytes</h4>
        </div>

        <div className="col-3 flex justify-content-center">
          <span className={'status ' + execution.status.get()}> {execution.status.get()} </span>
        </div>
        <div className="col-3 flex justify-content-center">
          <span style={{ textAlign: "center", marginBottom: 0 }}> { execution.get().start_date_str}</span>
        </div>
        <div className="col-3 flex justify-content-center">
          <span style={{ textAlign: "center", marginBottom: 0 }}> 
            { execution.get().end_date_str || '-'}
          </span>
        </div>
        <div className="col-3 flex justify-content-center">
          <span style={{ textAlign: "center", marginBottom: 0 }}> 
            {execution.rows.get()?.toLocaleString()} ({ execution.status.get() === ExecStatus.Interrupted ? '-' : toBytes(execution.bytes.get() || 0, true)})
          </span>
        </div>
        
      </div>

      <div className="grid" style={{marginTop: 0}}>
        <div className="col-3"></div>
        <div className="col-3 flex justify-content-center">
          <RadioButton
            inputId="log-info" name="log-info" value="log-info"
            onChange={(e) => logDebugMode.set(!e.value)}
            checked={ !logDebugMode.get() }
            className="mr-2"
          />
          <label className="mt-1" htmlFor="log-info">Normal Logs</label>
        </div>
        <div className="col-3 flex justify-content-center">
          <RadioButton
            inputId="log-debug" name="log-debug" value="log-debug"
            onChange={(e) => logDebugMode.set(e.value)}
            checked={ logDebugMode.get() }
            className="mr-2"
          />
          <label className="mt-1" htmlFor="log-info">Debug Logs</label>
        </div>
        <div className="col-3"></div>
      </div>


      <InputTextarea
        id='log-text'
        cols={90}
        rows={32}
        value={getOutput()}
        style={{fontFamily: 'monospace', marginTop: '10px', maxHeight:'600px'}}
      />
        
      </div>
    </Dialog>
    </>
  );
};

export const filterLines = (logs: string, log_debug_mode: boolean) => {
  const lines = logs.split('\n')
  let newLines : string[] = []
  var pattern = new RegExp("\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}:\\d{2} (INF|DBG) ", "g")
  let isDebugLine = false 

  const push = (line: string) => {
    if(isDebugLine){
      if(log_debug_mode) newLines.push(line)
    } else {
      newLines.push(line)
    }
  }

  for(let line of lines) {
    let matches = searchString(line, pattern)
    if(matches.length > 0) {
      let match = matches[0]
      if(match) isDebugLine = match[1] === 'DBG'
    }
    push(line)
  }
  return newLines
}