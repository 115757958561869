import { State, none } from "@hookstate/core";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import TreeNode from "primereact/treenode";
import { TreeTableEventParams, TreeTable } from "primereact/treetable";
import * as React from "react";
import { sling } from "../App";
import { ConnKind, getPrefix } from "../core/connection";
import { getStreamId } from "../core/execution";
import { Replication, ReplicationStreamMap } from "../core/replication";
import { useHookState } from "../core/store";
import { ObjectBoolean, ObjectNumber } from "../utilities/interfaces";
import { useIsMounted, toastInfo, setFilterRefresh, jsonClone, toastError } from "../utilities/methods";
import { ReplicationJobDialog } from "./ReplicationJobDialog";
import { Tooltip } from "primereact/tooltip";
import { JobMode } from "../core/job";
import { rudderstack, rudderTrack } from "..";
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import YAML from 'yaml'

interface Props {
  loading: State<boolean>;
  isModified: State<boolean>;
  refresh: State<number>;
  replication: State<Replication>
}
export const sqlStreamTemplate = '\n-- select ...\n-- from my_schema.my_table\n'

export const ReplicationPanelStreams: React.FC<Props> = (props) => {
  const replication = props.replication
  const isModified = props.isModified
  const loading = useHookState(props.loading)
  const sourceConn = useHookState(sling.state.connections[replication.source_id.get()])
  // const targetConn = useHookState(sling.state.connections[replication.target_id.get()])
  const nodes = useHookState<TreeNode[]>([])
  const expanded = useHookState<ObjectBoolean>({})
  const jobDialog = useHookState({show: false, name: ''})
  const filterSearch = useHookState('')
  const isMounted = useIsMounted()
  const newPathRef = React.useRef<OverlayPanel>(null);
  const newPath = useHookState('')
  const newPathPrefix = useHookState('')
  const newSQLStreamRef = React.useRef<OverlayPanel>(null);
  const newSQLStreamName = useHookState('')
  const showSelected = useHookState(false)
  const viewMode = useHookState('Table UI')
  const yamlText = useHookState('')
  const yamlError = useHookState('')
  
  React.useEffect(() => { 
    let data = { replication_id: replication.id.get() }
    rudderstack.page(document.title, 'ReplicationPanelStreams', sling.rudderProperties(data))
  }, []); // eslint-disable-line
  
  React.useEffect(() => { 
    refreshNodes(false)

    // refresh yaml
    yamlText.set(makeYaml())

    // open dialog if specified
    jobDialog.set( jd => {
      let job_config_name = sling.state.temp.job_config_name?.get()
      if(job_config_name) {
        jd.name = job_config_name
        jd.show = true
        sling.state.temp.job_config_name.set(none)
      }
      return jd
    })
  }, [props.refresh.get()]); // eslint-disable-line

  React.useEffect(() => { 
    // refresh nodes when dialog is dismissed
    if(!jobDialog.show.get() && jobDialog.name.get() !== '') {
      refreshNodes(false)
    }
  }, [jobDialog.show.get()]); // eslint-disable-line

  const refreshSchemas = async () => {
    await sling.loadConnectionSchemata(sourceConn.id.get())
  }

  const isYamlView = () => viewMode.get() === "YAML"

  const refreshStreams = async () => {
    await sling.loadConnectionSchemata(sourceConn.id.get())
  }

  const refreshSchemaTables = async (schema: string) => {
    await sling.loadConnectionSchemata(sourceConn.id.get(), schema)
  }

  const makeYaml = () => {
    let config = jsonClone(replication.config.get())
    let source = YAML.stringify({source: config.source}).trim()
    let target = YAML.stringify({target: config.target}).trim()
    let defaults = YAML.stringify({defaults: config.defaults}).trim()
    let streams = YAML.stringify({streams: config.streams}).trim()
    
    defaults = defaults.replaceAll(
      'update_key: _sling_loaded_at',
      'update_key: _sling_loaded_at  # do not change, used in incremental mode for new/updated file ingestion',
    )
    return `${source}\n${target}\n\n${defaults}\n\n${streams}`
  }
  
  const saveConfigFromYaml = (yamlText: string) => {
    try {
      let config = YAML.parse(yamlText)
      if(config.defaults && typeof config.defaults !== 'object') throw new Error('"defaults" must be an object')
      if(config.streams && typeof config.streams !== 'object') throw new Error('"streams" must be an object')
      
      
      if(typeof config.streams === 'object') {
        for(let key of Object.keys(config.streams)) {
          let value = config.streams[key]
          if(!value) config.streams[key] = {} // put object object for null
        }
      }
      
      if(typeof config.defaults === 'object') {
        for(let key of ['source_options', 'target_options']) {
          let value = config.defaults[key]
          if(!value) config.defaults[key] = {} // put object object for null
        }

        // not allowed to schedule on free plan
        if(config.defaults.schedule && sling.state.project.is_free.get()) {
          config.defaults.schedule = ''
        }
      }

      replication.config.set(cfg => {
        // no need to save source/target, to not mess
        cfg.defaults = config.defaults
        cfg.streams = config.streams
        return cfg
      })
      
      yamlError.set('')
    } catch (error) {
      // toastError("Could not save YAML", error)
      yamlError.set(`${error}`)
    }
  }

  const refreshNodes = async (force = false) => {
    loading.set(true)
    if(sourceConn.is_database.get()) {
      if(force || !sourceConn?.schemata?.keys.length) await refreshSchemas()
    }
    // if(sourceConn.is_file.get()) {
    //   if(force || !sourceConn?.schemata?.keys.length) await refreshFolders()
    // }
    if(sourceConn.is_api.get() || sourceConn.is_airbyte.get()) {
      if(force || !sourceConn?.schemata?.keys.length) await refreshStreams()
    }
    let n = await schemataToNodes(filterSearch.get().toLowerCase())
    if(isMounted.current) nodes.set(n)
    loading.set(false)
  }

  const schemataToNodes = async (filter: string) => {
    let conn = sourceConn.get()
    let nodes : TreeNode[] = []
    let streams = replication.config.streams.get() || {}
    let defaultConfig = replication.config.defaults.get()
    let all_keys : ObjectBoolean = {}
    let all_selected_keys : string[] = []

    if(conn.kind === ConnKind.Database) {
      let schema_modes : ObjectNumber = {}

      for(let schema of Object.keys(conn.schemata)) {
        let selectedStreams = replication.get().schema_streams(schema)

        let childNodes : TreeNode[] = []
        for(let table of conn.schemata[schema]) {
          let key = sourceConn.get().make_key_identifier(schema, table)
          let selected = key in streams
          let streamConfig = selected ? streams[key] : undefined
          let childNode : TreeNode = {
            key: key,
            leaf: true,
            data: {
              config: streamConfig,
              active: selected,
              selected: selected,
              schema: schema,
              table: table,
              status: selected ? 'OK' : '',
              mode: selected ? streamConfig?.mode || defaultConfig.mode : '',
            }
          }
          all_keys[key] = true

          if(!filter || key.toLowerCase().includes(filter) || (showSelected.get() && selected)){
            childNodes.push(childNode)
            if(childNode.data.mode) {
              schema_modes[childNode.data.mode] = (schema_modes[childNode.data.mode] || 0)+1
            }
          }
        }
        if(conn.schemata[schema]?.length) all_selected_keys = all_selected_keys.concat(selectedStreams)
        let schema_is_active = replication.get().schema_selected(schema)

        let mode = Object.keys(schema_modes).length === 1 ? Object.keys(schema_modes)[0] : 'mixed'
        let node : TreeNode = {
          key: schema.toLowerCase(),
          leaf: false,
          data: {
            active: schema_is_active,
            schema: schema,
            streams: streams,
            status: schema_is_active ? 'OK' : 'disabled',
            mode: schema_is_active && Object.keys(schema_modes).length ? mode : '-',
          }
        }
        if(!filter || childNodes.length > 0 || schema.toLowerCase().includes(filter) || (showSelected.get() && selectedStreams.length)){
          node.children = childNodes
          nodes.push(node)
        }
      }

      for(let key of Object.keys(streams).sort()) {
        let streamConfig = streams[key]
        if(!streamConfig.sql) continue

        all_selected_keys.push(key)
        all_keys[key] = true
        let active = !streamConfig.disabled
        let node : TreeNode = {
          key: key,
          leaf: true,
          data: {
            config: streamConfig,
            active: true,
            selected: true,
            query: streamConfig.sql,
            status: active ? 'OK' : '-',
            mode: active ? streamConfig?.mode || defaultConfig.mode : '-',
          }
        }
        if(!filter || key.toLowerCase().includes(filter) || (showSelected.get() && active)){
          nodes.push(node)
        }
      }
    }

    if(conn.kind === ConnKind.Airbyte || conn.kind === ConnKind.API) {
      all_selected_keys = Object.keys(streams)
      for(let stream of Object.keys(conn.schemata).sort()) {
        let selected = stream in streams
        let streamConfig = selected ? streams[stream] : undefined

        let node : TreeNode = {
          key: stream,
          leaf: true,
          data: {
            config: streamConfig,
            active: !streamConfig?.disabled,
            selected: selected,
            stream: stream,
            status: selected ? 'OK' : '-',
            mode: selected ? streamConfig?.mode || defaultConfig.mode : '-',
          }
        }
        all_keys[stream] = true
        if(!filter || stream.toLowerCase().includes(filter) || (showSelected.get() && selected)){
          nodes.push(node)
        }
      }
    }

    if(conn.kind === ConnKind.File) {
      // let folders : ObjectBoolean = {}
      // replication.settings.folders_active.merge(folders)
      for(let key of Object.keys(streams).sort()) {
        let streamConfig = streams[key] || {}
        let active = !streamConfig.disabled
        let node : TreeNode = {
          key: key,
          leaf: true,
          data: {
            config: streamConfig,
            active: active,
            selected: true,
            path: key,
            folder: key,
            status: active ? 'OK' : '-',
            mode: active ? streamConfig?.mode || defaultConfig.mode : '-',
          }
        }
        if(!filter || key.toLowerCase().includes(filter) || (showSelected.get() && active)){
          nodes.push(node)
        }
      }
    }

    // check if existing streams still exist. For example, a source table could be deleted
    if(Object.keys(all_keys).length > 0) {
      replication.config.streams.set(streams => {
        for(let stream_key of all_selected_keys) {
          if(!(stream_key in all_keys)) {
            // delete from stream list and put notification
            delete streams[stream_key]
            toastInfo("Selected Stream Not Found", `Stream "${stream_key}" cannot be found in ${sourceConn.name.get()}. Removing from replication selection. Please Save to take effect.`, 9000)
          }
        }
        return streams
      })
    }

    return nodes
  }

  const loadOnExpand = async (e: TreeTableEventParams) => {
    if (!e.node.children || e.node.children.length === 0) {
      loading.set(true)
      await refreshSchemaTables(e.node.data.schema)
      await refreshNodes()
      loading.set(false)
    }
    expanded[e.node.key as string].set(true)
  }

  const onCollapse = async (e: TreeTableEventParams) => {
    console.log(e.node)
    expanded[e.node.key as string].set(none)
  }
  
  const activeBody = (node: any, column: any) => {
    const isFolder = node.data.folder !== undefined && !node.data.file
    const isSchema = node.data.schema !== undefined && !node.data.table
    const isTable = node.data.table !== undefined
    const isQuery = node.data.query !== undefined
    const isAPI = node.data.stream !== undefined
    const stream = replication.config.streams[node.key]

    if (node?.data?.selected && (isTable || isQuery || isFolder || isAPI)) {
      return (
        <div>
          <InputSwitch
            checked={ !stream?.disabled?.get() }
            onChange={async (e) => {
              stream.set(
                s => {
                  if(!s) s = {}
                  s.disabled = !e.value
                  if(!s.disabled) s.disabled = undefined
                  return s
                }
              )
            }}
          />
        </div>
      );
    }

    if (isSchema) { 
      return (
        <div>
          <InputSwitch
            checked={ replication.get().schema_selected(node.data.schema) }
            onChange={async (e) => {
              replication.config.streams.set(
                streams => {
                  if(!streams) streams = {}
                  for(let key of replication.get().schema_streams(node.data.schema)) {
                    if(!streams[key]) continue
                    streams[key].disabled = !e.value
                    if(!streams[key].disabled) streams[key].disabled = undefined
                  }
                  return streams
                }
              )
            }}
          />
        </div>
      );
    }

    return <></>
  }
  
  const nodeTemplate = (node: any, column: any) => {
    const isSchema = node.data.schema !== undefined
    const isTable = node.data.table !== undefined
    const isQuery = node.data.query !== undefined
    const isFile = node.data.path !== undefined
    const isAPI = node.data.stream !== undefined
    const isExpanded = expanded.keys.includes(node.key)
    let schemaStreams = replication.get().schema_streams(node.data.schema)
    let defaultIsIncremental = [JobMode.IncrementalMode].includes(replication.config.defaults.mode.get() || JobMode.FullRefreshMode)

    if (isTable) {
      return <>
        <span>
          <span style={{marginLeft: '-15px', paddingRight: '10px'}}>
            <Checkbox
              onChange={async (e) => {
                let key = node.key
                let selected = e.checked

                replication.config.streams.set(ss => {
                  if(!ss) ss = {}
                  if(selected){
                    ss[key] = {} // save stream to use defaults
                  } else if (key in ss) { 
                    delete ss[key] // unset active
                  }
                  return ss
                })
                refreshNodes()
              }}
              checked={node.data.selected}
            />
          </span>
          <span className="text-l" style={{verticalAlign: 'middle'}}>{ node.data.table }</span>
        </span>
      </>
    }

    if (isQuery) {
      return <>
        <span>
          <span style={{marginLeft: '-15px', paddingRight: '10px'}}>
            <Checkbox
              onChange={async (e) => {
                let key = node.key
                let selected = e.checked

                replication.config.streams.set(ss => {
                  if(!ss) ss = {}
                  if(selected){
                    ss[key] = {} // save stream to use defaults
                  } else if (key in ss) { 
                    delete ss[key] // unset active
                  }
                  return ss
                })
                refreshNodes()
              }}
              checked={node.data.selected}
            />
          </span>
          <span className="text-l" style={{verticalAlign: 'middle'}}>{ node.key }</span>

          <span id={`node-${node.data.key}`}>
            <Badge
              className={"ml-1 p-badge-info"}
              value={"custom sql"}
            />
          </span>
        </span>
      </>
    }

    if (isAPI) {
      return <>
        <span>
          <span style={{marginLeft: '-15px', paddingRight: '10px'}}>
            <Checkbox
              onChange={async (e) => {
                let key = node.key
                let selected = e.checked

                replication.config.streams.set(ss => {
                  if(!ss) ss = {}
                  if(selected){
                    // get default PK & UK
                    let cols = sourceConn.get().columns[key] || []
                    let pk = cols.filter(c => c.primary_key).map(c => c.column_name)
                    let uk = cols.filter(c => c.update_key).map(c => c.column_name)

                    ss[key] = {
                      mode: pk.length && uk.length && defaultIsIncremental ? JobMode.IncrementalMode : undefined,
                      primary_key: pk,
                      update_key: uk.length > 0 ? uk[0] : undefined,
                    } // save stream to use defaults
                  } else if (key in ss) { 
                    delete ss[key] // unset active
                  }
                  return ss
                })
                refreshNodes()
              }}
              checked={node.data.selected}
            />
          </span>
          <span className="text-l" style={{verticalAlign: 'middle'}}>{ node.key }</span>
        </span>
      </>
    }

    if (isFile) {
      return <span style={{marginLeft: '-35px'}}> { node.key } </span>
    }
    if (isSchema && isExpanded) {
      let schemaStreamKeys = replication.get().schema_streams(node.data.schema)

      return <span>
        <span> { node.data.schema } </span>
        <Button
          label={ node.children?.length === schemaStreamKeys?.length ? 'unselect all' : 'select all' }
          className="p-button-outlined ml-2 mb-2 small-button-2"
          onClick={() => {
            if(!node.children) return
            replication.config.streams.set(ss => {
              if(!ss) ss = {}
              if(schemaStreamKeys.length === node.children?.length) {
                for(let child of node.children) {
                  delete ss[child.key] // unselect all
                }
                if(Object.keys(ss).length === 0) ss = undefined as any
              } else {
                for(let child of node.children) {
                  ss[child.key] = {}  // select all
                }
              }
              return ss
            })
            refreshNodes()
          }}
        />
      </span>
    }

    // schema node
    schemaStreams = replication.get().schema_streams(node.data.schema)
    return <>
      <span>
        { node.data.schema }
        {
          schemaStreams.length > 0 ?
          <>
          {
            !node.data?.active ?
            <Tooltip target={`#node-${node.data.schema}`} content="Schema is deactivated" position="right" />
            :
            null
          }

          <span id={`node-${node.data.schema}`}>
            <Badge
              className={"ml-1 "+(!node.data?.active ? 'p-badge-warning':'')}
              value={schemaStreams.length}
            />
          </span>
          </>
          :
          null
        }
      </span>
    </>;
  }
  
  const actionTemplate = (node: any, column: any) => {
    const isTable = node.data.table !== undefined
    const isQuery = node.data.query !== undefined
    const isSchema = !isTable && node.data.schema !== undefined
    const isFile = node.data.path !== undefined
    const isAPI = node.data.stream !== undefined
    
    if (isFile || (isAPI && node.data.selected) || (isTable && node.data.selected) || (isQuery && node.data.selected)) {
      return <div>
        <Button
          type="button"
          icon={loading.get() ? 'pi pi-spin pi-spinner' :"pi pi-play"}
          className="p-button-success p-button-sm small-button"
          tooltip="Execute"
          tooltipOptions={{position: 'top'}}
          style={{ marginRight: '.5em', margin: 0}}
          onClick={async () => {
            if(isModified.get()) return toastInfo('Please save your changes before executing tasks')

            loading.set(true)
            let stream_id = getStreamId(
              replication.source_id.get(), 
              replication.target_id.get(),
              node.key,
            )
            rudderTrack('replication', 'task-execute', sling.rudderProperties({stream_id,job_name: node.key}))
            if(await sling.executeJob(stream_id)) {
              toastInfo(`Triggered "${node.key}"`)
            }
            loading.set(false)
          }}
        />
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning p-button-sm small-button ml-1"
          tooltip="Edit"
          tooltipOptions={{position: 'top'}}
          style={{ marginTop: '7px', margin: 0}}
          onClick={async () => {
            let stream_id = getStreamId(
              replication.source_id.get(), 
              replication.target_id.get(),
              node.key,
            )
            jobDialog.name.set(node.key)
            sling.state.temp.job_config.set(true)
            jobDialog.show.set(true)
            rudderTrack('replication', 'task-edit', sling.rudderProperties({stream_id,job_name: node.key}))
          }}
        />
      </div>;
    }

    const hasStreams = (schema: string) => {
      for(let stream of Object.keys(node.data.streams as ReplicationStreamMap)) {
        if(stream.startsWith(schema + '.')) return true
      }
      return false
    }
    
    if (isSchema && hasStreams(node.data.schema)) {
      return <div>
        <Button
          type="button"
          icon={loading.get() ? 'pi pi-spin pi-spinner' :"pi pi-play"}
          className="p-button-success p-button-sm"
          tooltip="Execute All Selected Tables"
          tooltipOptions={{position: 'top'}}
          style={{ marginRight: '.5em' }}
          onClick={async () => {
            if(isModified.get()) return toastInfo('Please save your changes before executing tasks')
            if(sling.state.project.is_free.get()) return toastInfo(`Free Plan Limit`, `To trigger a task group, you'll need to sign up for a paid plan. You can execute each stream individually.`, 6000)

            // trigger all jobs
            loading.set(true)
            let streams = node.data.streams as ReplicationStreamMap
            rudderTrack('replication', 'task-execute-batch', sling.rudderProperties({stream_ids: Object.keys(streams)}))
            let promises = Object.keys(streams)
                            .filter(name => name.startsWith(`${node.data.schema}.`))
                            .map(name => sling.executeJob(
                              getStreamId(
                                replication.source_id.get(), 
                                replication.target_id.get(),
                                name,
                              )
                            ))
            for(let promise of promises) await promise
            toastInfo(`Triggered all streams for "${node.data.schema}"`)
            loading.set(false)
          }}
        />
      </div>;
    }

    return <></>
  }

  const newPathDialog = <OverlayPanel
    ref={newPathRef}
    showCloseIcon
    dismissable>
    <p><strong>Enter File or Folder Path</strong></p>
    <InputText
      id='new-path-input'
      type="text"
      value={newPath.get()}
      onInput={(e:any) => {
        // validate
        let val = e.target.value as string
        if(!val.startsWith(newPathPrefix.get())) {
          newPath.set(newPathPrefix.get().trim())
        } else {
          newPath.set(e.target.value.trim())}
        }
      }
      style={{width: '400px'}}
    />
    <Button
      label="OK"
      className="ml-2"
      onClick={async (e) => {
        // validate
        replication.config.streams.set(ss => {
          if(!ss) ss = {}
          ss[newPath.get()] = {}
          return ss
        })
        newPathRef.current?.hide()
        await refreshNodes()
        rudderTrack('replication', 'new-task-from-path', sling.rudderProperties({}))
      }}
    />
  </OverlayPanel>

  const newSQLStreamDialog = <OverlayPanel
    ref={newSQLStreamRef}
    showCloseIcon
    dismissable>
    <p><strong>Enter the Stream Name</strong></p>
    <InputText
      id='new-sql-stream-input'
      type="text"
      value={newSQLStreamName.get()}
      placeholder='new_table_name'
      onInput={(e:any) => {
        // validate
        let val = (e.target.value as string)
                    .replaceAll('.', '_').replaceAll(' ', '_').toLowerCase()
        newSQLStreamName.set(val)
      }}
      style={{width: '300px'}}
    />
    <Button
      label="OK"
      className="ml-2"
      onClick={async (e) => {
        // validate
        replication.config.streams.set(ss => {
          if(!ss) ss = {}
          ss[newSQLStreamName.get()] = {
            sql: sqlStreamTemplate,
          }
          return ss
        })
        newSQLStreamRef.current?.hide()
        await refreshNodes()

        // show job dialog
        jobDialog.name.set(newSQLStreamName.get())
        sling.state.temp.job_config.set(true)
        jobDialog.show.set(true)
      }}
    />
  </OverlayPanel>

  const Header = <div className="flex justify-content-between">
    <div className="col-4" style={{ padding: 0, paddingBottom: '10px' }}> 
      <div className="text-left"> 
        {
          sourceConn.get().is_file ?
          <Button icon="pi pi-plus" 
            className="p-button-success p-button-sm" 
            label="New Path Stream"
            disabled={isYamlView()}
            style={{}}
            onClick={(e) => {
              newPathPrefix.set(getPrefix(sourceConn.get()))
              newPath.set(newPathPrefix.get())
              newPathRef.current?.toggle(e)
              setTimeout(() => {
                document.getElementById('new-path-input')?.focus()
              }, 100);
            }}
          /> 
          :
          null
        } 
        {
          sourceConn.get().is_database ?
          <Button icon="pi pi-plus" 
            className="p-button-success p-button-sm" 
            label="New SQL Stream"
            disabled={isYamlView()}
            tooltip='Create a Stream Task with a custom SQL Query'
            tooltipOptions={{position: 'top', style: {maxWidth: '200px'}}}
            style={{}}
            onClick={(e) => {
              newSQLStreamName.set('')
              newSQLStreamRef.current?.toggle(e)
              setTimeout(() => {
                document.getElementById('new-sql-stream-input')?.focus()
              }, 100);
            }}
          /> 
          :
          null
        } 

        {
          replication.config.streams?.keys?.length > 0 ?
          <>
            {/* <Button 
              className="p-button-info p-button-sm ml-2" 
              label="Trigger"
              tooltip="Trigger all selected streams"
              tooltipOptions={{position: 'top'}}
              onClick={async (e) => {
                // trigger all jobs
                loading.set(true)
                let promises = Object.keys(replication.config.streams.get())
                                .map(name => sling.executeJob(
                                  getStreamId(
                                    replication.source_id.get(), 
                                    replication.target_id.get(),
                                    name,
                                  )
                                ))
                for(let promise of promises) await promise
                toastInfo(`Triggered all selected streams`)
                loading.set(false)
              }}
            />  */}

            <Button 
              className="p-button-help p-button-sm ml-2" 
              label={showSelected.get()? 'Show All' : "Show Selected"}
              disabled={isYamlView()}
              onClick={(e) => { 
                showSelected.set(v => !v)
                filterSearch.set(showSelected.get() ? ':selected' : '')
                refreshNodes(false)
              }}
            /> 

            <Tooltip target={'#export-replication-button'} position='right' style={{maxWidth: '300px'}} autoHide={false}>
              Export to a YAML file. You can then import this file into another workspace or use it with the Sling CLI tool to run it manually. Click <a href="https://docs.slingdata.io/sling-cli/replication" target='_blank' rel="noopener noreferrer"> here</a> for more details.
            </Tooltip>

            {/* <Button
              id='export-replication-button'
              className="p-button-success p-button-sm ml-2" 
              label='Export Replication'
              onClick={async (e) => { 
                loading.set(true)
                let yamlPayload = await sling.exportReplication(replication.get())
                const source = connRef(replication.source_id.get())
                const target = connRef(replication.target_id.get())
                if(yamlPayload) download(`${source.name}-${target.name}.yaml`, yamlPayload)
                loading.set(false)
                rudderTrack('replication', 'export-replication', sling.rudderProperties({replication_id: replication.id.get()}))
              }}
            />  */}
          </>
          :
          null
        } 
      </div>
    </div>
    <div className="col-4" style={{ padding: 0, paddingBottom: '10px' }}>
      <div className="text-center">
        <SelectButton 
          id='ui-yaml-toggle'
          value={viewMode.get()}
          options={['Table UI', 'YAML']}
          onChange={(e) => {
            if(!e.value) return
            if(yamlError.get()) return toastError("YAML Has Errors", "Please fix the YAML config")
            if(e.value === 'YAML') {
              yamlText.set(makeYaml())
              setTimeout(() => { document.getElementById('yaml-text')?.focus() }, 100)
            }
            viewMode.set(e.value)
          }} 
          tooltip='Switch between a Tabular UI view and a YAML editor view'
          tooltipOptions={{position: 'top'}}
        />
      </div>
    </div>
    <div className="col-4" style={{ padding: 0, paddingBottom: '10px' }}>
      <div className="text-right">
        <Button
          icon="pi pi-play" 
          label="Execute"
          disabled={ Object.keys(replication.config?.streams?.get() || {}).length === 0}
          className="p-button-success p-button-sm" style={{marginRight: '5px'}}
          onClick={async () => {
            if(isModified.get()) return toastInfo('Please save your changes before executing tasks')
            if(sling.state.project.is_free.get()) return toastInfo(`Free Plan Limit`, `To trigger all tasks, you'll need to sign up for a paid plan. You can execute each stream individually.`, 6000)
          

            // trigger all jobs
            loading.set(true)
            let streams = replication.config.streams.get()
            rudderTrack('replication', 'task-execute-all', sling.rudderProperties({stream_ids: Object.keys(streams)}))
            let promises = Object.keys(streams)
                            .filter(key => !streams[key].disabled) // not disabled
                            .map(name => sling.executeJob(
                              getStreamId(
                                replication.source_id.get(), 
                                replication.target_id.get(),
                                name,
                              )
                            ))
            for(let promise of promises) await promise
            toastInfo(`Triggered all active streams"`)
            loading.set(false)
          }}
          tooltip='Execute all active streams'
          tooltipOptions={{position: 'top'}}
        />
        <div className="p-input-icon-left">
          <i className="pi pi-search"></i>
          <InputText
            type="search"
            className="p-inputtext-sm"
            disabled={isYamlView()}
            onChange={(e:any) => {
              setFilterRefresh(filterSearch, e.target.value, refreshNodes)
            }}
            onKeyDown={(e) => {
              if(e.key === 'Escape') { filterSearch.set(''); refreshNodes(false) } 
              if(e.key === 'Enter') { refreshNodes(false) }
            }}
            placeholder="Search"
            size={20}
          />
          <Button icon="pi pi-refresh" 
            disabled={isYamlView()}
            className="p-button-info p-button-sm" style={{marginLeft: '-40px'}}
            onClick={() => refreshNodes(true)}
          />
        </div>
      </div>
    </div>
  </div>


  const modeBody = (node: any, column: any) => {
    return <span className={'mode ' + node.data.mode?.replaceAll("+", "-")}> {node.data.mode} </span>
  }

  const col1Title = sourceConn.is_file.get() ? 'File Path' : 
    sourceConn.is_database.get() ? 'Schema / Table' : 'Object Name'

  return (
    <>
      {
        jobDialog.show.get() ?
        <ReplicationJobDialog
          show={jobDialog.show}
          name={jobDialog.name}
          replication={replication}
        />
        : null
      }
      {newPathDialog}
      {newSQLStreamDialog}
      <div
        className="grid"
        style={{
          maxHeight: `${window.innerHeight - 270}px`,
          overflowY: 'scroll',
        }}
      >
        <div className="col-12">
          <div className="card">
            {Header}
            {
              isYamlView() ?
              <div>
                <InputTextarea
                  id='yaml-text'
                  // cols={90}
                  rows={32}
                  value={yamlText.get()}
                  style={{
                    fontFamily: 'monospace',
                    marginTop: '10px',
                    width: '100%',
                    maxHeight: `${window.innerHeight - 470}px`,
                  }}
                  onChange={(e) => {
                    yamlText.set(e.target.value)
                    saveConfigFromYaml(e.target.value)
                  }}
                />
                <div style={{color: 'red', paddingTop: '10px'}}>
                  <strong>
                    {yamlError.get()}
                  </strong>
                </div>
              </div>

              :
              <TreeTable
                value={nodes.get()}
                scrollable
                scrollHeight={`${window.innerHeight - 470}px`}
                // globalFilter={filter.get()}
                loading={loading.get()}
                onExpand={loadOnExpand}
                onCollapse={onCollapse}
                // header={Header}
                resizableColumns
                columnResizeMode="fit"
              >
                <Column header={col1Title} className='column-wrap' body={nodeTemplate} style={{width:'45%'}}  expander></Column>
                <Column field="status" header="Status" style={{ textAlign: 'center', width: '15%'}}></Column>
                <Column header="Mode" body={modeBody} style={{ textAlign: 'center', width: '15%'}}></Column>
                <Column header="Active" body={activeBody} style={{ textAlign: 'center', width: '10%' }} />
                <Column body={actionTemplate} style={{ textAlign: 'center', width: '15%' }} />
              </TreeTable>
            }
          </div>
        </div>
      </div>
    </>
  )
}