import { createState, none, State } from "@hookstate/core";
import _ from "lodash";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { TabView, TabPanel } from "primereact/tabview";
import { Tooltip } from "primereact/tooltip";
import * as React from "react";
import { sling } from "../App";
import { Chart as ChartJs } from 'primereact/chart';
import { ConnKind } from "../core/connection";
import { ExecStatus, Execution, getStreamId, toBytes } from "../core/execution";
import { Job, JobMode, jobModeDescription } from "../core/job";
import { Replication, ReplicationStreamConfig } from "../core/replication";
import { ColRecord, getTableColumns, getFileColumns, getSqlColumns } from "../core/schemata";
import { useVariable, useHookState } from "../core/store";
import { ObjectAny } from "../utilities/interfaces";
import { get_duration, jsonClone, relative_duration, toastError, useIsMounted, copyToClipboard, toastSuccess, toastInfo } from "../utilities/methods";
import { t } from "../utilities/translator";
import { VolumeRecord } from "../core/stats";
import { JobSchedule } from "./JobSchedule";
import { StreamSourceOptions, StreamTargetOptions } from "./ReplicationPanelSettings";
import { filterLines } from "./ExecutionDialog";
import { SelectButton } from 'primereact/selectbutton';
import { sqlStreamTemplate } from "./ReplicationPanelStreams";
import { rudderTrack } from "..";
import { confirmPopup } from 'primereact/confirmpopup'; 

export interface JobDialogProps {
  name: State<string>
  show: State<boolean>
  replication: State<Replication>
  detached?: boolean
}

export const ReplicationJobDialog: React.FC<JobDialogProps> = (props) => {
  const activeIndex = useVariable(0);
  const name = props.name.get()
  const replication = props.replication
  const loading = useHookState(false)
  const origStreamConfig = React.useRef<ReplicationStreamConfig>({})
  const sourceConn = useHookState(sling.state.connections[replication.source_id.get()])
  const targetConn = useHookState(sling.state.connections[replication.target_id.get()])
  const stream_id = getStreamId(sourceConn.id.get(), targetConn.id.get(), name)
  const streamConfig = useHookState(jsonClone<ReplicationStreamConfig>(props.replication.config.streams[name].get() || {}))
  const statusJob = useHookState(new Job())
  const title = `${props.name.get()} (${`${sourceConn.name.get()} -> ${targetConn.name.get()}`})`

  React.useEffect(() => { 
    origStreamConfig.current = jsonClone<ReplicationStreamConfig>(streamConfig.get())

    // go to config panel
    if(sling.state.temp.job_config?.get()) {
      activeIndex.set(2)
      sling.state.temp.job_config?.set(none)
    }
  }, []); // eslint-disable-line

  const isChanged = () => {
    return !_.isEqual(origStreamConfig.current, jsonClone(streamConfig.get()))
  }
  
  const validate = () => {
    if(streamConfig.mode.get() === JobMode.IncrementalMode
      && !(streamConfig.primary_key.keys?.length && streamConfig.update_key.get())
    ) return toastError('Must select primary_key (PK) and update_key (UK) for incremental mode')
    return true
  }

  const cancelhide = () => {
    props.show.set(false)
  }

  const isQuery = () => streamConfig.get().sql !== undefined
  
  const Footer = <>
    <div className="flex align-items-center justify-content-center">

      <Button
        label={ props.detached  ? t('Save') : t('OK')}
        disabled={!sling.userAtLeastPower || sling.isDemoUser || (props.detached && !isChanged())}
        icon={loading.get()? 'pi pi-spin pi-spinner':'pi pi-check'}
        onClick={async () => {
          if(!validate()) return
          if(props.detached && isChanged()) {
            replication.config.streams[name].set(jsonClone(streamConfig.get()))
            if(!await sling.saveReplication(replication)) return
          }
          props.show.set(false)
          // props.name.set('')
          if(isChanged()) replication.config.streams[name].set(jsonClone(streamConfig.get()))
        }}
        className="p-button-success p-ml-4"
      />

      <Button 
        label={ t('Cancel')}
        onClick={() => cancelhide() }
        icon="pi pi-times"
        className="p-button-secondary p-mr-4"
        style={{marginRight: '10px'}}
      />
      {
        sourceConn.is_file.get() || isQuery() ?
        <Button
          type="button"
          disabled={!sling.userAtLeastPower || sling.isDemoUser}
          icon="pi pi-trash"
          label={t('Delete')}
          className="p-button-danger"
          tooltip="Remove"
          tooltipOptions={{position: 'top'}}
          onClick={async () => {
            confirmDialog({
              message: 'Are you sure you want to delete?',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: async () => {
                replication.config.streams.set(ss => {
                  delete ss[name]
                  return ss
                })
                props.show.set(false)
              },
            })
          }}
        />
        :
        null
      }

    </div>
  </>

  const Header = <>
    <span title={title}>{ title.length > 80 ? title.slice(0, 80) + '...' : title}</span>
  </>

  if(!name)  {
    return <></>
  }

  return (
    <>
    <Dialog
      id='job-dialog'
      header={Header}
      visible={props.show.get() || false}
      onHide={() => { cancelhide() }}
      footer={Footer}
    >
      <div
        // className="card"
        style={{
          width: '60rem',
          minHeight: '38rem',
        }}
      >
      <TabView
        style={{ textAlign: "center" }}
        activeIndex={activeIndex.get()}
        onTabChange={(e) => activeIndex.set(e.index)}
      >
        <TabPanel header="Status">
            <StatusPanel
              statusJob={statusJob}
              streamConfig={streamConfig}
              stream_id={stream_id}
            />
        </TabPanel>
        <TabPanel header="Logs">
            <LogPanel stream_id={stream_id}/>
        </TabPanel>
        <TabPanel header="Configuration" disabled={ !sling.userAtLeastPower }>
            <ConfigurationPanel
              name={name}
              stream_id={stream_id}
              replication={replication}
              streamConfig={streamConfig}
            />
        </TabPanel>
        <TabPanel header={ targetConn.get().is_database ? "Settings & Reset" : "Settings"} disabled={ !sling.userAtLeastPower }>
            <SettingsPanel
              name={name}
              stream_id={stream_id}
              replication={replication}
              streamConfig={streamConfig}
            />
        </TabPanel>
      </TabView>
      </div>
    </Dialog>
    </>
  )
}

const StatusPanel = (props: {
  stream_id: string,
  statusJob: State<Job>,
  streamConfig: State<ReplicationStreamConfig>,
}) => {
  const stream_id = props.stream_id
  ///////////////////////////  HOOKS  ///////////////////////////
  const loading = useHookState(false)
  const job = useHookState(props.statusJob)
  const volumeChartRef = React.useRef(null)
  const volumeChartOptions = useVariable<ObjectAny>({
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
          legend: {
              labels: {
                  color: '#495057'
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          },
          y: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          }
      }
  }) 
  const volumeChartData = useVariable<ObjectAny>({
        labels: [],
        datasets: [
            {
                label: 'Total bytes (GB)',
                backgroundColor: '#42A5F5',
                data: []
            },
        ]
    })

  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    getJobRecord()
    refreshCharts()
    rudderTrack('replication', 'task-dialog-status', sling.rudderProperties({stream_id}))
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////

  const getJobRecord = async () => {
    loading.set(true)
    let jobs = await sling.loadJobs({ stream_id })
    if(jobs.length !== 1) return
    job.set(jobs[0])
    loading.set(false)
  }


  const refreshCharts = async () => {
    const toGB = (b: number) => b / 1024 / 1024 / 1024
    let volRecords = await sling.getStats('volume', 'day', 14, undefined, stream_id) as VolumeRecord[]
    volumeChartData.set({
        labels: volRecords.map(r => new Date(r.date*1000).toISOString().split('T')[0]),
        datasets: [
            {
                label: 'Total bytes (GB)',
                backgroundColor: '#42A5F5',
                data: volRecords.map(r => toGB(r.total_bytes))
            },
        ]
    })
    const volumeChart : any = volumeChartRef.current
    volumeChart?.refresh()
  }

  ///////////////////////////  JSX  ///////////////////////////

  const lastExecution = () => {
    return job.get().last_execution
  }

  return <>
    <div
      className="grid"
      style={{}}
    >
      <div className="col-12">
        <div className="grid">
          <div className="col-4 flex justify-content-center">
            <strong>Status/Active</strong>
          </div>
          <div className="col-4 flex justify-content-center">
            <strong>Schedule</strong>
          </div>
          <div className="col-4 flex justify-content-center">
            <strong>Success Rate</strong>
          </div>


          <div className="col-4 flex justify-content-center">
            <span className={'status ' + lastExecution().status}> {lastExecution().status} </span>
          </div>
          <div className="col-4 flex justify-content-center">
            {job.schedule.get() || '-'}
          </div>
          <div className="col-4 flex justify-content-center">
            {job.get().success_rate}
          </div>
          
          <div className="col-12"></div>
          <div className="col-4 flex justify-content-center">
            <strong>Last Execution</strong>
          </div>
          <div className="col-4 flex justify-content-center">
            <strong>Next Execution</strong>
          </div>
          <div className="col-4 flex justify-content-center">
            <strong>Avg Duration</strong>
          </div>

          <div className="col-4 flex justify-content-center">
            <Tooltip target='#job-last-exec-date' position='bottom'>
                <div>
                  { relative_duration(lastExecution().start_date) }
                </div>
            </Tooltip>
            <span id='job-last-exec-date' > {lastExecution().start_date_str} </span>
          </div>
          <div className="col-4 flex justify-content-center">
            <Tooltip target='#job-next-exec-date' position='bottom'>
                <div>
                  { relative_duration(job.get().next_execution_date) }
                </div>
            </Tooltip>
            <span id='job-next-exec-date' > {job.get().next_execution_date_str || '-'} </span>
          </div>
          <div className="col-4 flex justify-content-center">
            {get_duration(job.get().avg_duration)}
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card">
            <ChartJs
              ref={volumeChartRef}
              type="bar"
              height='200px'
              data={volumeChartData.get()}
              options={volumeChartOptions.get()}
            />
        </div>
      </div>
    </div>
  </>
}

const ConfigurationPanel = (props: {
  stream_id: string,
  name: string,
  replication: State<Replication>,
  streamConfig: State<ReplicationStreamConfig>,
}) => {
  const name = props.name
  const stream_id = props.stream_id
  const replication = props.replication
  const streamConfig = props.streamConfig
  ///////////////////////////  HOOKS  ///////////////////////////
  const loading = useHookState(false)
  const colRecords = useHookState<ColRecord[]>([])
  const isQuery = streamConfig.get().sql !== undefined
  const sqlMode = useHookState(false)
  const selectedColumns = useHookState<ObjectAny>({})
  const sourceConn = useHookState(sling.state.connections[replication.source_id.get()])
  const isMounted = useIsMounted()
  const compiledObjectName = useHookState({default:'', stream: ''})
  const compileJobDebounce = React.useRef<any>(null)
  
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    syncColumns().then(
      () => {
        if(colRecords.length === 0 && isQuery)
          sqlMode.set(true) // set to sql mode if no columns
      }
    )
    compileJob()
    rudderTrack('replication', 'task-dialog-configuration', sling.rudderProperties({stream_id}))

    // set debounce
    compileJobDebounce.current = _.debounce(() => compileJob(), 600)
  }, []); // eslint-disable-line
  ///////////////////////////  FUNCTIONS  ///////////////////////////

  const compileJob = async () => {
    let replic = new Replication(jsonClone(replication.get()))

    // to compile the default pattern
    replic.config.streams = {}

    replic.config.streams[name] = replic.config.defaults
    let jobs = await sling.compileReplication(createState<Replication>(replic))
    if(name in jobs) {
      compiledObjectName.set(c => {
        c.default = jobs[name].config.target.object || ''
        return c
      })
    }
    
    // to compile the stream pattern
    if(streamConfig.object.get()) {
      replic.config.streams[name] = streamConfig.get()
      jobs = await sling.compileReplication(createState<Replication>(replic))
      if(name in jobs) {
        compiledObjectName.set(c => {
          c.stream = jobs[name].config.target.object || ''
          return c
        })
      }
    }
  }

  const syncColumns = async () => {
    let conn = sourceConn.get()
    let columnMap : ObjectAny = {}
    let pks = streamConfig.primary_key.get() || []
    let uk = streamConfig.update_key.get() || ''

    if(streamConfig.get().sql === sqlStreamTemplate) return

    loading.set(true)
    if(conn.kind === ConnKind.Database) {
      let columns: ColRecord[] = []

      if(isQuery) columns = await getSqlColumns(conn, streamConfig.get().sql || '')
      else columns = await getTableColumns(conn, name)

      if(!isMounted.current) return
      colRecords.set(
        recs => {
          recs = []
          for(let column of columns) {
            recs.push({
              column_id: column.column_id,
              column_name: column.column_name,
              column_type: column.column_type,
              primary_key: pks.includes(column.column_name.toLowerCase()),
              update_key: uk === column.column_name?.toLowerCase(),
            })
            columnMap[column.column_name.toLowerCase()] = recs[recs.length-1]
          }
          return recs
        } 
      )
    }
    if(conn.kind === ConnKind.File) {
      let config = replication.get().apply_defaults(jsonClone(streamConfig.get()))
      let columns = await getFileColumns(conn, name, config.source_options)
      if(columns.length && isMounted.current) {
        colRecords.set(
          recs => {
            recs = []
            for(let column of columns) {
              recs.push({
                column_id: column.column_id,
                column_name: column.column_name,
                column_type: column.column_type,
                primary_key: pks.includes(column.column_name.toLowerCase()),
                update_key: uk === column.column_name?.toLowerCase(),
              })
              columnMap[column.column_name.toLowerCase()] = recs[recs.length-1]
            }
            return recs
          } 
        )
      }
    }

    if(conn.kind === ConnKind.Airbyte || conn.kind === ConnKind.API) {
      let columns = conn.columns[name.toLowerCase()] || []
      if(columns.length === 0) {
        await sling.loadConnectionSchemata(conn.id)
        columns = sourceConn.get().columns[name.toLowerCase()] || []
      }
      if(columns.length && isMounted.current) {
        colRecords.set(
          recs => {
            recs = []
            for(let column of columns) {
              recs.push({
                column_id: recs.length+1,
                column_name: column.column_name,
                column_type: column.column_type,
                primary_key: pks.includes(column.column_name.toLowerCase()),
                update_key: uk === column.column_name?.toLowerCase(),
              })
              columnMap[column.column_name.toLowerCase()] = recs[recs.length-1]
            }
            return recs
          } 
        )
      }
    }

    if(!isMounted.current) return

    // set selected columns
    let columnNames = streamConfig.columns.get()
    let newColumns : ObjectAny = {}
    if(!columnNames) {
      newColumns = jsonClone(columnMap)
    } else {
      for(let columnName of columnNames) {
        columnName = columnName.toLowerCase()
        if(columnName in columnMap) newColumns[columnName] = columnMap[columnName]
      }
    }
    selectedColumns.set(newColumns)

    loading.set(false)
  }

  const columnTableHeader = () => {
    return (
      <Checkbox 
        checked={ selectedColumns.keys.length === colRecords.keys.length }
        onChange={e => {
          selectedColumns.set(
            sel => {
              for(let col of colRecords.get()) {
                if(e.checked) sel[col.column_name.toLowerCase()] = col
                else if(!(col.primary_key || col.update_key)) {
                  delete sel[col.column_name.toLowerCase()]
                }
              }
              return sel
            }
          )
        }}
      />
    )
  }

  ///////////////////////////  JSX  ///////////////////////////
  const colKeyBody = (keyType:'PK'|'UK'|'S', data: ColRecord) => {

    const setPK = (checked: boolean) => {
      if(checked) {
        streamConfig.primary_key.set(
          pk => { 
            pk = (pk||[])
            pk.push(data.column_name?.toLowerCase()) 
            return pk
          }
        )
      } else {
        streamConfig.primary_key.set(
          pk => { 
            pk = (pk||[])
            return pk.filter(c => c.toLowerCase() !== data.column_name?.toLowerCase())
          }
        )
      }
    }

    const setUK = (checked: boolean) => {
      if(checked) {
        streamConfig.update_key.set(data.column_name?.toLowerCase())
      } else {
        streamConfig.update_key.set(undefined)
      }
    }

    const setSelection = (checked: boolean) => {
      selectedColumns.set(sel => {
        if(checked) sel[data.column_name.toLowerCase()] = data
        else delete sel[data.column_name.toLowerCase()]

        if (Object.keys(sel).length === 0) {
          toastError('Need to select at least one column')
          sel[data.column_name.toLowerCase()] = data
        }

        return sel
      })
    }

    const refreshRecs = () => {
      // this is needed because Hookstate does not allow us to change in place
      // we have to copy back and forth
      let pks = streamConfig.primary_key.get() || []
      let uk = streamConfig.update_key.get() || ''
      colRecords.set(
        recs => {
          for (let i = 0; i < recs.length; i++) {
            const rec = recs[i];
            recs[i].primary_key = pks.includes(rec.column_name.toLowerCase())
            recs[i].update_key = uk === rec.column_name?.toLowerCase()
            if(recs[i].primary_key || recs[i].update_key) {
              selectedColumns[rec.column_name.toLowerCase()].set(rec)
            }
          }

          if(selectedColumns.keys.length === recs.length) {
            streamConfig.columns.set(undefined)
          } else {
            streamConfig.columns.set(
              recs
                .filter(c => c.column_name.toLowerCase() in selectedColumns.get())
                .map(c => c.column_name)
            ) // preserve order
          }
          
          return recs
        }
      )
    }

    const elementID = 'checkbox-' + data.column_name.toLowerCase()
    return <>
      <Tooltip disabled={!sourceConn.is_file.get()} target={'#'+elementID} position='right' style={{maxWidth: '300px'}}>
          {fileIncrementalNote}
      </Tooltip>
      <Checkbox 
        id={elementID}
        checked={ keyType==='PK' ? data.primary_key : keyType==='UK' ? data.update_key : (data.column_name.toLowerCase() in selectedColumns.get()) }
        onChange={e => {
          if(keyType==='PK') { setPK(e.checked); refreshRecs() }
          if(keyType==='UK') { setUK(e.checked); refreshRecs() }
          if(keyType==='S') { setSelection(e.checked); refreshRecs() }
        }}
        disabled={keyType==='UK' && sourceConn.is_file.get()} // use last_mod date of file
      />
    </>
  }


  return <>
      {/*
        mode
        object
        columns
        primary_key
        update_key
        schedule
        */}
      <div
        className="grid"
        style={{
          maxHeight: `${window.innerHeight - 270}px`,
          overflowY: 'scroll',
        }}
      >
        <div className="col-6">
          {/* Object Name */}
          <div className="card p-fluid">
            <strong>Target Object Name</strong>
            <div className="formgrid grid mt-1">
                <div className="col-12 field flex align-content-center">
                  <RadioButton
                    value="Use Default Pattern"
                    inputId="object-name-default"
                    onChange={(e) => {
                      streamConfig.set(
                      cfg => {
                        if(!cfg) cfg = {} as ReplicationStreamConfig
                        delete cfg.object
                        return cfg
                      })
                      compileJob()
                    }}
                    checked={!streamConfig.get()?.object}
                  />
                  <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="object-name-default">
                    Use Default
                    <i><code style={{color: 'grey', whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '12px'}}> → {compiledObjectName.default.get()}</code></i>
                  </label>
                </div>

                <div className="col-12 field flex align-content-center mb-0">
                  <RadioButton
                    value="Use Custom"
                    inputId="object-name-custom"
                    // name="city"
                    onChange={(e) => {
                      compileJob()
                      streamConfig.set(
                        cfg => {
                          if(!cfg) cfg = {} as ReplicationStreamConfig
                          cfg.object = replication.config.defaults.object.get()
                          return cfg
                        })
                    }}
                    checked={!!streamConfig.get()?.object}
                  />
                  <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="object-name-custom">
                    Use Custom
                    { 
                      (!!streamConfig.get()?.object)?
                      <i><code style={{color: 'grey', whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '12px'}}> → {compiledObjectName.stream.get()}</code></i>
                      :
                      null
                    }
                  </label>
                </div>
                {
                    streamConfig.get()?.object ?
                  <div className="field col mb-0 mt-2">
                      <InputText
                        id="input-object-name"
                        type="text"
                        value={streamConfig.get()?.object}
                        onInput={(e:any) => {
                          streamConfig.set(
                            cfg => {
                              if(!cfg) cfg = {} as ReplicationStreamConfig
                              cfg.object = e.target.value
                              return cfg
                            })
                          compileJobDebounce.current()
                        }}
                      />
                      <small>Enter the name/pattern the object in the target connection. See <a target='_blank' href="https://docs.slingdata.io/sling-cli/replication#object-pattern-options" rel="noopener noreferrer">here</a> for documentation.</small>
                  </div>
                  :
                  null
                }
            </div>
          </div>

          {/* Mode */}
          <div className="card p-fluid">
            <strong>Replication Mode</strong>
            <div className="formgrid grid mt-1">
                <div className="col-12 field flex align-content-center">
                  <RadioButton
                    value="Use Default"
                    inputId="mode-default"
                    onChange={(e) => streamConfig.set(
                      cfg => {
                        if(!cfg) cfg = {} as ReplicationStreamConfig
                        delete cfg.mode
                        return cfg
                      })
                    }
                    checked={!streamConfig.get()?.mode}
                  />
                  <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="mode-default">
                    Use Default
                    <i><code style={{color: 'grey', whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '12px'}}> → {replication.config.defaults.mode.get()}</code></i>
                  </label>
                </div>

                <div className="col-12 field flex align-content-center mb-0">
                  <RadioButton
                    value="Use Custom"
                    inputId="mode-custom"
                    // name="city"
                    onChange={(e) => streamConfig.set(
                      cfg => {
                        if(!cfg) cfg = {} as ReplicationStreamConfig
                        cfg.mode = replication.config.defaults.mode.get()
                        return cfg
                      })
                    }
                    checked={!!streamConfig.get()?.mode}
                  />
                  <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="mode-custom">Use Custom</label>
                </div>
                {
                    streamConfig.get()?.mode ?
                  <div className="field col">
                      <Dropdown
                        id="input-mode"
                        className="p-inputtext-sm"
                        options={Object.values(JobMode)}
                        value={ streamConfig.get()?.mode }
                        onChange={(e:any) => streamConfig.set(
                          cfg => {
                            if(!cfg) cfg = {} as ReplicationStreamConfig
                            cfg.mode = e.target.value
                            return cfg
                          })
                        }
                        tooltip={jobModeDescription(replication.config.defaults.mode.get())}
                        tooltipOptions={{position: 'left', style: {maxWidth: '300px'}}}
                      />
                  </div>
                  :
                  null
                }
            </div>
          </div>

          {/* Schedule */}
          <div className="card p-fluid">
            <strong>Replication Schedule</strong>
            <div className="formgrid grid mt-1">
                <div className="col-12 field flex align-content-center">
                  <RadioButton
                    value="Use Default"
                    inputId="schedule-default"
                    onChange={(e) => streamConfig.set(
                      cfg => {
                        if(!cfg) cfg = {} as ReplicationStreamConfig
                        delete cfg.schedule
                        return cfg
                      })
                    }
                    checked={streamConfig.get()?.schedule === undefined}
                  />
                  <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="mode-default">
                    Use Default
                    <i><code style={{color: 'grey', whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '12px'}}> → {replication.config.defaults.schedule.get() || 'No Schedule'}</code></i>
                  </label>
                </div>

                <div className="col-12 field flex align-content-center mb-0">
                  <RadioButton
                    value="Use Custom"
                    inputId="schedule-custom"
                    // name="city"
                    onChange={(e) => streamConfig.set(
                      cfg => {
                        if(!cfg) cfg = {} as ReplicationStreamConfig
                        if(e.checked) {
                          cfg.schedule = replication.config.defaults.schedule.get() || ''
                        } else {
                          cfg.schedule = undefined
                        }
                        return cfg
                      })
                    }
                    checked={streamConfig.get()?.schedule !== undefined}
                  />
                  <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="schedule-custom">Use Custom</label>
                </div>
                {
                    streamConfig.get()?.schedule !== undefined ?
                  <div className="field col" style={{marginTop: '5px'}}>
                      <JobSchedule schedule={streamConfig.schedule} />
                  </div>
                  :
                  null
                }
            </div>
          </div>
        </div>

        <div className="col-6">
          {
            isQuery?
              <SelectButton
                id="job-sql-toggle"
                className="m-1"
                options={['Columns', 'SQL']}
                value={ sqlMode.get() ? 'SQL' : 'Columns'}
                onChange={(e) => {
                  sqlMode.set(e.value === 'SQL')
                }}
                tooltip='Switch between defining the SQL Query and seeing the columns'
                tooltipOptions={{position: 'top'}}
                // style={{width: '100%'}}
              />
            :
            null
          }

          {
            sqlMode.get() ?
            <>
              <InputTextarea
                id='sql-text'
                rows={20}
                value={streamConfig.sql.get()}
                style={{fontFamily: 'monospace', width:'100%', marginTop: '5px'}}
                onChange={(e) => streamConfig.sql.set(e.target.value)}
              />
              <span
                style={{
                  position: 'absolute',
                  right: 60,
                  paddingTop: '10px',
                }}
              >
                <Button
                  icon="pi pi-copy"
                  tooltip="Copy to Clipboard"
                  tooltipOptions={{ position: 'top' }}
                  className="p-button-rounded p-button-text p-button-info"
                  onClick={() => copyToClipboard(streamConfig.sql.get() || '')}
                />
                <Button
                  icon="pi pi-play"
                  tooltip="Test / Refresh Columns"
                  tooltipOptions={{ position: 'top' }}
                  className="p-button-rounded p-button-text p-button-info"
                  onClick={async (e) => { 
                    colRecords.set([])
                    await syncColumns() 
                    if(colRecords.length > 0 ) toastSuccess("Success", `Returned ${colRecords.length} column(s)`)
                  }}
                />
              </span>
            </>
            :
            <DataTable
              loading={loading.get()}
              responsiveLayout="scroll"
              value={colRecords.get()}
              scrollable scrollHeight={ isQuery? '343px':"370px"}
              selectionMode="checkbox"
              emptyMessage='No columns found'
            >
              <Column
                header={columnTableHeader()}
                headerStyle={{maxWidth: '3em', textAlign: 'center'}}
                bodyStyle={{maxWidth: '3em', textAlign: "center"}}
                body={(data: any) => colKeyBody('S', data)}
              />
              <Column field="column_id" header="#" headerStyle={{maxWidth: '3em', textAlign: 'center'}} bodyStyle={{maxWidth: '3em', textAlign: "center"}}/>
              <Column field="column_name" header="Name"/>
              {/* <Column field="column_type" header="Type" headerStyle={{width: '7em', textAlign: 'center'}} bodyStyle={{width: '7em', textAlign: "center"}}/> */}
              <Column
                header="PK"
                headerStyle={{maxWidth: '3em', textAlign: 'center'}}
                bodyStyle={{maxWidth: '3em', textAlign: "center"}}
                body={(data: any) => colKeyBody('PK', data)}
              />
              <Column
                header="UK"
                headerStyle={{maxWidth: '3em', textAlign: 'center'}}
                bodyStyle={{maxWidth: '3em', textAlign: "center"}}
                body={(data: any) => colKeyBody('UK', data)}
              />
            </DataTable>
            }
        </div>
      </div>
    </>
}






const LogPanel = (props: {stream_id: string}) => {
  
  interface LogEntry {
    id: number
    status: ExecStatus
    start_time: number
    end_time: number
    bytes: number
    rows: number
    label: string
  }

  ///////////////////////////  HOOKS  ///////////////////////////
  const loading = useHookState(false)
  const execution = useHookState(new Execution())
  const logEntry = useVariable<LogEntry>({} as LogEntry)
  const logEntries = useVariable<LogEntry[]>([])
  const logDebugMode = useHookState(false)

  ///////////////////////////  EFFECTS  ///////////////////////////

  React.useEffect(() => { 
    getLogEntries()
    rudderTrack('replication', 'task-dialog-log', sling.rudderProperties({stream_id: props.stream_id}))
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const getLogEntries = async () => {
    loading.set(true)
    let entries = await sling.loadExecutions({ 
      stream_id: props.stream_id,
      fields: 'execution_log_entries',
      last: 100,
    })
    loading.set(false)

    logEntries.set(les => {
      les = entries.map(e => {
        return {
          id: e.id,
          status: e.status,
          start_time: e.start_time || 0,
          end_time: e.end_time,
          bytes: e.bytes,
          rows: e.rows,
          label: ``,
        } as LogEntry
      })
      les = _.orderBy(les, ['id'], ['desc'])
      if(les.length > 0 && logEntry.get()?.id === undefined) {
        logEntry.set(les[0])
        loadLogEntry(les[0].id)
      }
      return les
    })
  }

  const loadLogEntry = async (id: number) => {
    // load execution
    loading.set(true)
    let execs = await sling.loadExecutions({ids: [id]})
    loading.set(false)
    if(execs.length === 0) return
    execution.set(execs[0])
  }

  const getOutput = () => {
    let output = execution.output.get() + '\n' + (execution.err.get() || '')
    output = filterLines(output, logDebugMode.get())?.join('\n') || ''
    output = "Execution #" + (execution.id.get()?.toString() || '') + '\n' + output
    return output
  }
  
  ///////////////////////////  JSX  ///////////////////////////

    const logEntryOptionTemplate = (entry: LogEntry) => {
    let time = entry?.start_time || 0
    let date = new Date(time * 1000).toLocaleString()
    
    let color = 'green'
    if([ExecStatus.Error, ExecStatus.Interrupted, ExecStatus.Terminated, ExecStatus.TimedOut].includes(entry?.status)) {
      color = 'red'
    } else if([ExecStatus.Skipped, ExecStatus.Queued].includes(entry?.status)) {
      color = 'grey'
    } else if([ExecStatus.Running].includes(entry?.status)) {
      color = 'purple'
    }

    return (
        <div className="" style={{
          fontSize: '11px',
          color: color,
        }}>
            <div>{`${date}`}</div>
        </div>
      );
    }
  
  return <>
    {/*
      list of past executions
      subscribes to log stream for live updates
      */}
      <div
        style={{
          width: '58rem',
          minHeight: '33rem',
          marginBottom: '-16px',
        }}
      >
        <div className="grid">
          <div className="col-3">
            <div className="grid">
              <div className="col-12" style={{ paddingBottom: 0 }}>
                <strong >Entry</strong>

                {
                  loading.get() ?
                  <i className="pi pi-spin pi-spinner" style={{'fontSize': '11px', marginLeft: '5px'}} />
                  :
                  <a
                    href={window.location.href}
                    onClick={async () => {
                      await getLogEntries()
                      await loadLogEntry(logEntry.get()?.id)
                    }}
                  >
                    <i className="pi pi-refresh" style={{'fontSize': '11px', marginLeft: '5px'}} />
                  </a>
                }
              </div>
              <div className="col-12" >
                <Dropdown
                  value={logEntry.get()}
                  options={logEntries.get()}
                  onChange={async (e) => {
                    let entry = e.target.value as LogEntry
                    logEntry.set(entry)
                    loadLogEntry(entry.id)
                  }}
                  valueTemplate={logEntryOptionTemplate}
                  itemTemplate={logEntryOptionTemplate}
                  placeholder="Select a log entry"
                />

              </div>
              <div className="col-12"></div>
              <div className="col-12">
                <strong >Status</strong>
              </div>
              <div className="col-12">
                <span className={'status ' + execution.status.get()}> {execution.status.get()} </span>
              </div>

              <div className="col-12">
                <strong style={{ marginBottom: 0 }}> Start Time</strong>
              </div>
              <div className="col-12">
                <span style={{ marginBottom: 0 }}> { execution.get().start_date_str}</span>
              </div>

              <div className="col-12">
                <strong style={{ marginBottom: 0 }}> End Time</strong>
              </div>
              <div className="col-12">
                <span style={{ marginBottom: 0 }}> 
                  { execution.get().end_date_str || '-'}
                </span>
              </div>

              <div className="col-12">
                <strong style={{ marginBottom: 0 }}> Rows / Bytes</strong>
              </div>
              <div className="col-12">
                <span style={{ marginBottom: 0 }}> 
                  {execution.rows.get()?.toLocaleString()} ({ execution.status.get() === ExecStatus.Interrupted ? '-' : toBytes(execution.bytes.get() || 0, true)})
                </span>
              </div>
              
            </div>
          </div>

          <div className="col-9">
            <div className="grid" style={{marginTop: 0}}>
              <div className="col-3"></div>
              <div className="col-3 flex justify-content-center">
                <RadioButton
                  inputId="log-info" name="log-info" value="log-info"
                  onChange={(e) => logDebugMode.set(!e.value)}
                  checked={ !logDebugMode.get() }
                  className="mr-2"
                />
                <label className="mt-1" htmlFor="log-info">Normal Logs</label>
              </div>
              <div className="col-3 flex justify-content-center">
                <RadioButton
                  inputId="log-debug" name="log-debug" value="log-debug"
                  onChange={(e) => logDebugMode.set(e.value)}
                  checked={ logDebugMode.get() }
                  className="mr-2"
                />
                <label className="mt-1" htmlFor="log-info">Debug Logs</label>
              </div>
              <div className="col-3"></div>
              <div className="col-12">
                <InputTextarea
                  id='log-text'
                  // cols={60}
                  rows={22}
                  value={getOutput()}
                  style={{fontFamily: 'monospace', width:'100%', marginTop: '10px'}}
                />
              </div>
            </div>
          </div>
        </div>
            
      </div>

    </>
}


const SettingsPanel = (props: {
  name: string,
  stream_id: string,
  replication: State<Replication>,
  streamConfig: State<ReplicationStreamConfig>,
}) => {

  const replication = props.replication
  const streamConfig = props.streamConfig
  const stream_id = props.stream_id
  const targetConn = useHookState(sling.state.connections[replication.target_id.get()])

  React.useEffect(() => { 
    rudderTrack('replication', 'task-dialog-settings', sling.rudderProperties({stream_id: props.stream_id}))
  }, []); // eslint-disable-line

  return <div style={{
    overflowY: 'scroll',
    maxHeight: '33rem'
  }}>

    <div className="card p-fluid">
      <strong>Source Options</strong>
      <div className="formgrid grid mt-1">
          <div className="col-12 field flex align-content-center">
            <RadioButton
              value="Use Default"
              inputId="mode-default"
              onChange={(e) => streamConfig.set(
                cfg => {
                  if(!cfg) cfg = {} as ReplicationStreamConfig
                  delete cfg.source_options
                  return cfg
                })
              }
              checked={!streamConfig.get()?.source_options}
            />
            <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="mode-default">Use Default</label>
          </div>

          <div className="col-12 field flex align-content-center mb-2">
            <RadioButton
              value="Use Custom"
              inputId="mode-custom"
              // name="city"
              onChange={(e) => streamConfig.set(
                cfg => {
                  if(!cfg) cfg = {} as ReplicationStreamConfig
                  cfg.source_options = jsonClone(replication.config.defaults.source_options.get())
                  return cfg
                })
              }
              checked={!!streamConfig.get()?.source_options}
            />
            <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="mode-custom">Use Custom</label>
          </div>

          {
            streamConfig.get()?.source_options ?
            <StreamSourceOptions replication={replication} streamConfig={streamConfig} />:
            null
          }
      </div>
    </div>

    <div className="card p-fluid">
      <strong>Target Options</strong>
      <div className="formgrid grid mt-1">
          <div className="col-12 field flex align-content-center">
            <RadioButton
              value="Use Default"
              inputId="mode-default"
              onChange={(e) => streamConfig.set(
                cfg => {
                  if(!cfg) cfg = {} as ReplicationStreamConfig
                  delete cfg.target_options
                  return cfg
                })
              }
              checked={!streamConfig.get()?.target_options}
            />
            <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="mode-default">Use Default</label>
          </div>

          <div className="col-12 field flex align-content-center mb-2">
            <RadioButton
              value="Use Custom"
              inputId="mode-custom"
              // name="city"
              onChange={(e) => streamConfig.set(
                cfg => {
                  if(!cfg) cfg = {} as ReplicationStreamConfig
                  cfg.target_options = jsonClone(replication.config.defaults.target_options.get())
                  return cfg
                })
              }
              checked={!!streamConfig.get()?.target_options}
            />
            <label className="ml-2 mt-1" style={{margin: 0}} htmlFor="mode-custom">Use Custom</label>
          </div>

          {
            streamConfig.get()?.target_options ?
            <div className="col-6 mb-2">
              <StreamTargetOptions replication={replication} streamConfig={streamConfig} />
            </div>:
            null
          }
      </div>
    </div>

    {
      targetConn.get().is_database ?
      <div className="card p-fluid">
        <strong>Reset Table</strong>
        <div className="col-4 field flex align-content-center">
          <Button
            label={ t('Trigger Reset')}
            disabled={!sling.userAtLeastPower}
            tooltip='Trigger a one-time full-refresh/truncate so that the table is reloaded from scratch. You will be asked for a confirmation.'
            tooltipOptions={{style: {maxWidth: '300px'}, position: 'top'}}
            onClick={async (e: any) => {
                const confirm = (mode: JobMode|undefined) => 
                  confirmDialog({
                      message: 'Are you sure you want to reset this table? All data will be deleted.',
                      style: {maxWidth: '300px'},
                      header: 'Reset Confirmation',
                      icon: 'pi pi-info-circle',
                      accept: () => {
                        sling.executeJob(stream_id, '', mode).then(
                          triggered => {
                            if(triggered) toastInfo(`Reset Triggered`)
                          }
                        )
                      },
                  });
                confirmPopup({
                  target: e.currentTarget,
                  style: {maxWidth: '370px'},
                  message: `Please choose the mode for Resetting. "Full-Refresh" will drop the table and recreate it. "Truncate" will erase the table's content without dropping it (useful for keeping settings such as permissions).`,
                  icon: 'pi pi-info-circle',
                  acceptLabel: 'Full-Refresh',
                  rejectLabel: 'Truncate',
                  accept: () => confirm(JobMode.FullRefreshMode),
                  reject: () => confirm(JobMode.TruncateMode),
                  dismissable: true,
              });
            }}
            className="p-button-warning p-ml-4"
            style={{paddingRight: '50px'}}
          />
        </div>
      </div>
      :
      null
    }

  </div>
}

export const fileIncrementalNote = 
  <div>
    Sling uses an internally generated timestamp to track which files have already been ingested. It will only ingest files that have been modified since the last load when <code>incremental</code> mode is selected.
  </div>