import { sling } from "../../App"
import { ObjectAny, ObjectString, RecordsData } from "../../utilities/interfaces"
import { data_req_to_records, toastError, toastSuccess } from "../../utilities/methods"
import { apiDelete, apiGet, apiPatch, apiPost, apiPut, Http, HttpResponse, isDemo, masterURL } from "./http"
import { Route, routeReverseMap } from "./routes"
import { Ws } from "./ws"



export const makeHeaders = (extraHeaders : ObjectString = {}) => {
  extraHeaders['Sling-Project-ID'] = sling.state.project.id.get()
  let demo_key = localStorage.getItem('demo_key')
  if(isDemo && demo_key) extraHeaders['Sling-API-Key'] = demo_key
  return extraHeaders
}

/** Responsible for making all backend calls through HTTP or WEBSOCKET */
export class API {
  http: Http
  ws: Ws
  constructor() {
    this.http = new Http()
    this.ws = new Ws()
  }

  Post = (route: Route, payload = {}, extraHeaders : ObjectString = {}) => { 
    extraHeaders = makeHeaders(extraHeaders)
    return apiPost(route.toString(), payload, extraHeaders)
  }

  Patch = (route: Route, payload={}, extraHeaders : ObjectString = {}) => { 
    extraHeaders = makeHeaders(extraHeaders)
    return apiPatch(route.toString(), payload, extraHeaders)
  }

  Put = (route: Route, payload={}, extraHeaders : ObjectString = {}) => { 
    extraHeaders = makeHeaders(extraHeaders)
    return apiPut(route.toString(), payload, extraHeaders)
  }

  Get = (route: Route, payload={}, extraHeaders : ObjectString = {}) => { 
    extraHeaders = makeHeaders(extraHeaders)
    return apiGet(route.toString(), payload, extraHeaders)
  }
  
  Delete = (route: Route, payload={}, extraHeaders : ObjectString = {}) => { 
    extraHeaders = makeHeaders(extraHeaders)
    return apiDelete(route.toString(), payload, extraHeaders)
  }
}

export const CRUD = () => {
  return {
    connections: ApiCRUD(Route.Connections),
    executions: ApiCRUD(Route.Executions),
    projects: ApiCRUD(Route.Projects),
    replications: ApiCRUD(Route.Replications),
    settings: ApiCRUD(Route.Settings),
    jobs: ApiCRUD(Route.Jobs),
    users: ApiCRUD(Route.Users),
    workers: ApiCRUD(Route.Workers),
  }
}

export interface ReqMetaDb {
  conn_id: string;
  procedure: string;
  schema?: string;
  table?: string;
  query?: string;
}

export interface ReqMetaFile {
  conn_id: string;
  procedure: string;
  path: string;
}

export const metadataReq = async (data: ReqMetaDb | ReqMetaFile) : Promise<RecordsData> => {
  let resp = await apiPost(Route.DataRequest, data, makeHeaders())
  if (resp.error) throw resp.error
  resp.data.records = () => data_req_to_records(resp.data as RecordsData, true)
  return resp.data as RecordsData
}

export interface ListRequest {
  id?: string | number 
  limit?: number 
  last?: number 
  fields?: string 
  where?: string[] // where template name
  order?: string  // order template name
  items?: any[]
}

function ApiCRUD(route: Route) {
  const nameMap = routeReverseMap()
  const name = nameMap[route]? nameMap[route] : ''
  const api = new API()

  const List = async (req : ListRequest = {}) : Promise<any[]> => {
    let id : string | number | undefined = req.id
    let limit : number = req.limit || 100
    let last : number = req.last || 0
    let fields : string = req.fields || ''
    let where: string[] = req.where || []
    let order: string = req.order || ''
    let items : any[] = []
    try {
      let data : ObjectAny = { id, limit, last, fields, where, order }
      let resp = await api.Get(route, data, {})
      if(resp.error) throw resp.error
      for (let item of resp.data.data) {
        items.push(item)
      }
    } catch(error) {
      toastError(`Error listing ${name}`, error)
    }
    return items
  }

  const Get = List

  const Save = async (item: any) => {
    let resp: HttpResponse;
    let headers = {}
    try {
      if (item.id) {
        // is existing
        resp = await api.Put(route, item.payload(), headers);
      } else {
        // is new
        resp = await api.Post(route, item.payload(), headers);
      }
      if(resp.error) throw resp.error
      toastSuccess(`Success`);
      return resp.data.id as string | number
    } catch (error) {
      toastError(`Error`, error);
    }
    return undefined
  }

  const Delete = async (item: any) : Promise<boolean> => {
    let id = item.id
    if (id === null) return false
    let data : ObjectAny =  { id }
    try {
      let resp = await api.Delete(route, data, {});
      if(resp.error) throw resp.error
      toastSuccess(`Success`);
      return true
    } catch (error) {
      toastError(`Error`, error);
    }
    return false
  }

  return { Get, List, Delete, Save }
}

// LogStream did not work last time I tried
export const LogStream = () => {
  const project_id = sling.state.project.id
  const sse = new EventSource(`${masterURL}${Route.Logs}?project-id=${project_id.get()}`, { withCredentials: true });

  sse.onmessage = (e) => {
    console.log(e)
  }

  sse.onopen = (e) => {
    console.log(e)
  }

  sse.onerror = (e) => {
    // error log here 
    console.log(e)
    sse.close();
  }
  return () => {
    sse.close();
  };
}