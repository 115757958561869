import React, { useState, useEffect, useRef, RefObject } from 'react';
import classNames from 'classnames';
import {
  Route,
  useLocation, useHistory, BrowserRouter, Switch, HashRouter 
} from "react-router-dom";
import { CSSTransition } from 'react-transition-group';

import { AppConfig } from './components/application/AppActions';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { ChatWidget } from '@papercups-io/chat-widget';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import './App.css';
import { ClientMethods, getClientMethods } from './utilities/tauri';
import { UnlistenFn } from '@tauri-apps/api/event';
import { AppFooter } from './components/application/AppFooter';
import { AppMenu } from './components/application/AppMenu';
import { AppTopbar } from './components/application/AppTopbar';
import { Toast } from 'primereact/toast';
import { ObjectAny } from './utilities/interfaces';
import { History } from 'history'
import { ProgressSpinner } from 'primereact/progressspinner';

import * as reactRouterDom from "react-router-dom";
import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import ThirdPartyEmailPassword, {Github, Google} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session, { doesSessionExist } from "supertokens-auth-react/recipe/session";
import { ConnectionsView } from './views/ConnectionsView';
import { HomeView } from './views/HomeView';
import { ReplicationsView } from './views/ReplicationsView';
import { Sling } from './core/sling';
import { useHookState, useVariable } from './core/store';
import { ReplicationOneView } from './views/ReplicationOneView';
import { ConnType } from './core/connection';
import { isDemo, isProd, masterURL, urlHasModeDemo } from './core/api/http';
import { HistoryView } from './views/HistoryView';
import { authURL } from './core/api/routes';
import { getMobileOperatingSystem, toastInfo, useWindowDimensions, useWindowFocus } from './utilities/methods';
import { UsersView } from './views/UsersView';
import { WorkspaceSettingsView } from './views/WorkspaceSettingsView';
import { PlanBillingView } from './views/PlanBillingView';
import { PlanSetupPanel, WorkerSetupPanel } from './components/SetupPanels';
import { Plan } from './core/project';
import { rudderstack } from '.';


// this is to extends the window global functions
declare global {
  interface Window {
    toast: RefObject<Toast>
    callbacks: ObjectAny
    msg_received: any[]
    ws_heartbeat: any
    h: History<any>
  }
}

SuperTokens.init({
    // enableDebugLogs: isDev || isStaging,
    appInfo: {
        // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
        appName: "Sling",
        apiDomain: masterURL,
        websiteDomain: window.location.origin,
        apiBasePath: "/auth",
        websiteBasePath: "/auth",
    },
    recipeList: [
        ThirdPartyEmailPassword.init({
            emailVerificationFeature: {
                mode: "REQUIRED"
            },
            getRedirectionURL: async (context) => {
                console.log(window.location)
                console.log(context)
                if (context.action === "SIGN_IN_AND_UP") {
                  window.location.assign(window.location.origin + '/#/')
                }

                if (context.action === "SUCCESS") {
                  window.location.assign(window.location.origin + '/#/')
                }
                return undefined;
            },
            signInAndUpFeature: {
              providers: [
                Github.init(),
                Google.init(),
              ],
              signUpForm: {
                termsOfServiceLink: "https://slingdata.io/terms-of-service",
                privacyPolicyLink: "https://slingdata.io/privacy-policy",
                formFields: [{
                    id: "name",
                    label: "Full Name",
                    placeholder: "First & Last Name",
                }]
              }
            }
        }),
        Session.init({
          // cookieDomain: isProd ? ".slingdata.io"  : isStaging ? '.sling.vinedatalabs.com' : undefined
        }),
    ]
});

export const SessionExist = async () => {
  if (await doesSessionExist()) {
    // user is logged in
  } else {
    // user has not logged in yet
  }
}

export const layoutTreshold = 1300;
export const sidebarWidth = 250;


export var sling : Sling 

const App = () => {
  
  ///////////////////////// SESSION START
  let isTauri = useVariable(false)
  // const isStating = window.location.href.includes('://dev.')
  // let { doesSessionExist } = useSessionContext();

  // useEffect(() => {
  //   // if(!isAuth.get() && !isTauri.get() && !doesSessionExist) redirectToAuth()
  //   if (isDev || isTauri.get() || doesSessionExist()) return
  //   // window.location.href = 'https://auth.slingdata.io'
  //   // redirectToAuth()
  // }, [isTauri.get()])// eslint-disable-line

  ///////////////////////// INIT START
  const history = useHistory();
  const toast = useRef<Toast>(null);
  window.toast = toast

  window.h = history

  const c = useRef<Sling>(new Sling({}))
  sling = c.current

  useEffect(() => {
    window.addEventListener('dragenter', (e) => console.log(e))

    sling.init()
    return () => {
      sling.dispose()
      c.current = new Sling({})
    }
  }, [])// eslint-disable-line
  ///////////////////////// INIT END

  const userId = useHookState(sling.state.user.id)
  const loaded = useHookState(sling.state.loaded)
  const showPlanSetup = useHookState(false)
  const showWorkerSetup = useHookState(false)
  const projectPlan = useHookState(sling.state.project.plan)
  const [layoutMode, setLayoutMode] = useState('overlay');
  const [layoutColorMode, setLayoutColorMode] = useState('light')
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false); //this one
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef<any>();
  const CM = useRef<ClientMethods>(new ClientMethods(false));
  const { width, height } = useWindowDimensions();
  const windowFocus = useWindowFocus(60*60)
  // const location = useLocation();
  const verifyingEmail = () => window.location.href.includes('auth/verify-email')

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    let unlisten1: UnlistenFn | undefined = () => { };
    let unlisten2: UnlistenFn | undefined = () => { };
      
    getClientMethods().then(async (cm) => {
      CM.current = cm
      isTauri.set(cm.isTauri)
      // console.log(`isTauri => ${cm.isTauri}`)
      // console.log(`userId => ${userId.get()}`)
      // console.log(`doesSessionExist => ${doesSessionExist}`)

      unlisten1 = await CM.current.listen('tauri://file-drop', event => {
        console.log(event)
      })
      unlisten2 = await CM.current.listen('tauri://file-drop-hover', event => {
        console.log(event)
      })
    })

    return () => {
      if (unlisten1) unlisten1()
      if (unlisten2) unlisten2()
    }
  }, [userId.get()]); // eslint-disable-line

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    if (width < layoutTreshold) {
      setLayoutMode('overlay')
    } else {
      setLayoutMode('static')
    }
  }, [width]);

  // useEffect(() => {
  //   copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
  // }, [location]);

  const onInputStyleChange = (inputStyle: string) => {
    setInputStyle(inputStyle);
  }

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value)
  }

  const onLayoutModeChange = (mode: string) => {
    setLayoutMode(mode)
  }

  const onColorModeChange = (mode: string) => {
    setLayoutColorMode(mode)
  }

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  }

  const onToggleMenuClick = (event: any) => {
    menuClick = true;

    if(!userId.get()) return

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      }
      else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState);
      }
    }
    else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  }

  const onSidebarClick = () => {
    menuClick = true;
  }

  const onMobileTopbarMenuClick = (event: any) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  }

  const onMobileSubTopbarMenuClick = (event: any) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  }

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  }
  const isDesktop = () => {
    return window.innerWidth >= layoutTreshold
  }

  const marginSize = (min = sidebarWidth, max = sidebarWidth) => {
    let size = min + (width < 1400 ? 0 : Math.round((width - 1400) / 2))
    size = size > max ? max : size
    return size
  }

  const menu = () => {
    let account_settings = []
    if(sling.userAtLeastAdmin) account_settings.push({ label: 'Plan & Billing', icon: 'pi pi-fw pi-credit-card', to: '/plan-billing' })
    account_settings.push({ label: 'Users', icon: 'pi pi-fw pi-users', to: '/users' })
    account_settings.push({ label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/settings' })

    return [
      {
        label: 'MENU',
        items: [
          { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
          { label: 'Connections', icon: 'pi pi-fw pi-cloud', to: '/connections' },
          // { label: 'Extract / Load', icon: 'pi pi-fw pi-clone', to: '/tasks'  },
          // { label: 'Ad-Hoc Task', icon: 'pi pi-fw pi-bolt', to: '/task'  },
          { label: 'Replications', icon: 'pi pi-fw pi-clone', to: '/replications' },
          // { label: 'Tasks', icon: 'pi pi-fw pi-clone', to: '/tasks'  },
          // { label: 'Flows', icon: 'pi pi-fw pi-clone', to: '/flows'  },
          { label: 'History', icon: 'pi pi-fw pi-list', to: '/history' },
        ]
      },
      {
        label: 'Account & Settings', icon: 'pi pi-fw pi-sitemap',
        items: account_settings,
      },
      {
        label: 'Help', icon: 'pi pi-fw pi-sitemap',
        items: [
          { label: 'Documentation', icon: 'pi pi-fw pi-compass', command: () => { CM.current.open("https://docs.slingdata.io") } },
          // { label: 'Chat', icon: 'pi pi-fw pi-comments', to: '/chat' },
        ]
      },
    ]
  }

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light'
  });
  

  useEffect(() => {
    if (userId.get()) {
      setLayoutMode('static')
    } else {
      setLayoutMode('overlay')
    }
    setTimeout(() => {
      if (!userId.get() && !window.location.href.includes('/auth')) {
        window.location.href = authURL
      }
    }, 2000)
  }, [userId.get(), overlayMenuActive, mobileMenuActive, staticMenuInactive]) // eslint-disable-line


  React.useEffect(() => {
    if(windowFocus && loaded.get()) {
      sling.loadSettings('app').then(() => sling.checkVersion())
    }
  }, [windowFocus]); // eslint-disable-line

  // check is workers exist
  useEffect(() => {
    if (loaded.get()) {

      if(verifyingEmail() || urlHasModeDemo) {
        // reload without path, since logo may not render correctly
        window.location.assign(window.location.origin + '/#/')
        return
      }

      if(getMobileOperatingSystem()) {
        toastInfo("Mobile OS Detected", "Sling Cloud is not designed for mobile viewing, please use a Desktop / Laptop machine.", 6000)
      }

      // rudderstack identify
      rudderstack.identify(sling.state.user.id.get(), {
        name: sling.state.user.name.get(),
        email: sling.state.user.email.get(),
      });

      let no_workers = sling.state.workers.keys.length === 0
      let unset_plan = projectPlan.get() === Plan.Unselected
      if(unset_plan) { showPlanSetup.set(true) }
      else if(sling.state.project.get().is_self_hosted && no_workers) { showWorkerSetup.set(true) }
    }
  }, [loaded.get(), projectPlan.get()]) // eslint-disable-line

  if (!userId.get() && !window.location.href.includes('/auth')) {
    // console.log('authURL -> ' + authURL)
    return <span>Loading...</span>
  }

  if (!userId.get()) {
    console.log('LOGIN UI')
    return <>
      <BrowserRouter>
          <Switch>
            {/*This renders the login UI on the /auth route*/}
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
          </Switch>
      </BrowserRouter>
    </>
  }

  return (
    <>
      <HashRouter>
        <Toast ref={toast} />
        <WorkerSetupPanel show={showWorkerSetup}/>
        <PlanSetupPanel show={showPlanSetup}/>

        {/* Pre Render imgs */}
        {Object.values(ConnType)
          .filter(key => !window.location.href.includes('/callback/'))
          .filter(key => ![ConnType.FileHTTP, ConnType.FileHDFS, ConnType.DbAzureDWH, ConnType.DbSQLite].includes(key))
          .map(key => <img key={'conn-img-' + key} src={'assets/connections/' + key + '.png'} alt={key} style={{ display: 'none' }} />)}
          
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}
              style={{
                maxWidth: `${marginSize(sidebarWidth-42, sidebarWidth-42)}px`,
              }}
            >
                <AppMenu model={menu()} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container" style={{
              marginLeft: layoutMode === 'overlay' || staticMenuInactive || !isDesktop() ? '60px' : `${marginSize()}px`,
              marginRight: layoutMode === 'overlay' || staticMenuInactive || !isDesktop() ? '60px' : `${marginSize()}px`,
              paddingLeft: '15px',
              paddingRight: '15px',
            }}>
                <div className="layout-main">
                  {/* <ThirdPartyEmailPasswordAuth> */}
                    {
                      loaded.get() ?
                        <>
                          <Route path="/" exact render={() => <HomeView/>} />
                          <Route path="/connections" component={ConnectionsView}/>
                          <Route path="/replications" component={ReplicationsView} />
                          <Route path="/replication" component={ReplicationOneView} />
                          <Route path="/history" component={HistoryView} />
                          <Route path="/users" component={UsersView} />
                          <Route path="/plan-billing" component={PlanBillingView} />
                          <Route path="/settings" component={WorkspaceSettingsView} />
                        </>
                        :
                        <div style={{position: 'relative', height: `${height - 200}px`}}>
                          <div style={{position: 'absolute', top: `50%`, left: '50%'}}>
                            <ProgressSpinner style={{width: '60px', height: '60px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
                          </div>
                        </div>
                    }
                  {/* </ThirdPartyEmailPasswordAuth> */}
                </div>

                <Tooltip target='#footer-logo' position='left' style={{minWidth: '120px', fontSize: '12px'}}>
                  <span>frontend: { sling.state.settings.app.frontend_version.get() }</span>
                  <br/>
                  <span>backend: { sling.state.settings.app.backend_version.get() }</span>
                </Tooltip>
                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
            <ChatWidget
              // Pass in your Papercups account token here after signing up
              token="f46a1a11-fc7c-4d44-a62d-60d8deca18e0"
              inbox={isProd ? "67d0cc12-f518-4624-bb50-90f04ae569bf" : "09d0916d-6053-4913-99bb-66537a06602c"}
              title="Welcome to Sling"
              subtitle="Ask us anything in the chat window below 😊"
              primaryColor="#005DF1"
              greeting="Hi! Let us know if you are facing issues or have any questions."
              newMessagePlaceholder="Start typing..."
              showAgentAvailability={false}
              agentAvailableText="We're online right now!"
              agentUnavailableText="We're away at the moment."
              requireEmailUpfront={isDemo}
              iconVariant="outlined"
              baseUrl="https://papercups.ocral.org"
              defaultIsOpen={false}
              customer={ isDemo ? undefined : {
                name: sling.state.user.name.get(),
                email: sling.state.user.email.get(),
                external_id: sling.state.user.id.get(),
                metadata: {
                  // version: 1,
                  plan: sling.state.project.plan.get(),
                }, // Custom fields go here
              }}
              // Optionally specify the base URL
              // Add this if you want to require the customer to enter
              // their email before being able to send you a message
              // Add this if you want to indicate when you/your agents
              // are online or offline to your customers
            />

        </div>
      </HashRouter>
    </>
    );

}

export default App;


// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation()?.search);
}
