import { ObjectString } from "../../utilities/interfaces";

export const authURL = window.location.origin + "/auth"

export enum Route {
  Original = '/',
  Status = '/status',
  Ws = '/ws/client',
  Logout = "/app/logout",
  User = "/api/v1/user",
  Users = "/api/v1/users",
  Accounts = "/api/v1/accounts",
  Workers = "/api/v1/workers",
  Projects = "/api/v1/projects",
  Settings = "/api/v1/settings",
  Logs = "/api/v1/logs",
  Stats = "/api/v1/stats",
  Subscription = "/api/v1/subscription",
  Connections = "/api/v1/connections",
  ConnectionTest = "/api/v1/connection-test",
  Executions = "/api/v1/executions",
  Replications = "/api/v1/replications",
  RouteAPIKey = "/api/v1/apikey",
  ReplicationCompile = "/api/v1/replications/compile",
  Transformations = "/api/v1/jobs",
  Jobs = "/api/v1/jobs",
  Uploads = "/api/v1/uploads",
  Export  = "/api/v1/export",
  DataRequest = "/api/v1/data-request",
	ResetPassword = "/reset-password",
  StripeCreateCustomer        = "/api/v1/stripe/create-customer",
  StripeCreateCheckoutSession = "/api/v1/stripe/create-checkout-session",
  StripeCustomerPortal        = "/api/v1/stripe/customer-portal",
  StripeWebhook               = "/api/v1/stripe/webhook",
}

export const routeReverseMap = (): ObjectString => {
  let map: ObjectString = {}
  let keys = Object.keys(Route)
  let values = Object.values(Route)
  for (let i = 0; i < values.length; i++) {
    map[values[i]] = keys[i]
  }
  return map
}