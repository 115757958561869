import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <img id='footer-logo' src='assets/layout/images/logo.svg' alt="Logo" height="20" className="mr-2" />
            
            <a href='https://slingdata.io' target='_blank' rel="noopener noreferrer">
              <span id='footer-logo-text' className="font-medium ml-2">Slingdata.io</span>
            </a>
            
        </div>
    );
}
