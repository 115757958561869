import * as React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { State } from "@hookstate/core";
import { useHookState } from "../core/store";
import { RegionMap, WorkerHeartbeat, WorkerRegion } from "../core/worker";
import { sling } from "../App";
import { TabPanel, TabView } from "primereact/tabview";
import { Message, MessageType } from "../core/api/ws";
import { toastError, toastInfo, toastSuccess } from "../utilities/methods";
import { Route } from "../core/api/routes";
import { RadioButton } from 'primereact/radiobutton';
import { Plan } from "../core/project";
import { WorkspaceChooser } from "./WorkspaceChooser";
import { ListBox } from "primereact/listbox";
import { ObjectAny } from "../utilities/interfaces";
import { Tooltip } from "primereact/tooltip";
import { rudderTrack } from "..";

interface Props {
  show: State<boolean>
}

export const PlanSetupPanel: React.FC<Props> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const show = useHookState(props.show)
  const plan = useHookState(sling.state.project.plan)
  const choice = useHookState(Plan.SelfHostedFree)
  const showWorkspaceDialog = useHookState(false)
  const showRegionPanel = useHookState(false)
  

  if (!sling.userAtLeastAdmin) {
    return <WorkspaceNotSetup show={props.show}/>
  }

  return (
    <>
      <RegionPanel show={showRegionPanel}/>
      <Dialog
        header="Choose Worker Mode"
        visible={show.get()}
        closable={false}
        footer={<div style={{textAlign: 'center'}}>
          <Button
            label='Proceed'
            className='p-button-success'
            tooltipOptions={{position: 'top'}}
            onClick={async () => {
              if(choice.get() === Plan.CloudFree) {
                // set plan, will need to create a new workspace to self-host
                if(await sling.setPlan(choice.get())) {
                  plan.set(choice.get())
                  show.set(false)
                  showRegionPanel.set(true)
                  rudderTrack('setup', 'plan-set', sling.rudderProperties({plan: plan.get()}))
                }
              } else {
                plan.set(choice.get())
                show.set(false)
              }
            }} 
          />

          <WorkspaceChooser show={showWorkspaceDialog}/>
          <Button
            label={ 'Switch Workspaces' }
            className='p-button-secondary ml-2'
            onClick={async () => { showWorkspaceDialog.set(true) }} 
          />

          <Button
            label={ 'Log Out' }
            className='p-button-info ml-2'
            onClick={async () => { sling.logout() }} 
          />
        </div>} 
        onHide={() => show.set(false)}
      >
        <div
          style={{
            width: `400px`,
          }}
        >

          <div className="grid">
            <div className="col-1">
                <RadioButton 
                  inputId="mode-self-hosted" 
                  name="self-hosted"
                  value={Plan.SelfHostedFree}
                  onChange={(e) => choice.set(e.value)}
                  checked={choice.get() === Plan.SelfHostedFree} 
                />
            </div>
            <div className="col-11">
              <h5>Self-Hosted Mode</h5>
              The self-hosted mode allows you to host the worker on your own machine or server. This means that data will never pass through Sling's servers. You also store your Connection credentials on your machine in an <a href="https://docs.slingdata.io/sling-cli/environment" target='_blank' rel="noopener noreferrer">env.yaml</a> file. Sling will only have access to the name/type of each connection, as well as the ability to manage/trigger replications.
            </div>
            <br/>

            <div className="col-1">
                <RadioButton 
                  inputId="mode-cloud" 
                  name="cloud"
                  value={Plan.CloudFree}
                  onChange={(e) => choice.set(e.value)}
                  checked={choice.get() === Plan.CloudFree} 
                />
            </div>
            <div className="col-11">
              <h5>Cloud Mode</h5>
              The cloud mode lets Sling host the worker for you. This means that data will pass through Sling's servers, and that your connection credentials will be stored in Sling's Vault. This is the most convenient and fastest way to get started.
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export const RegionPanel: React.FC<Props> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const show = useHookState(props.show)
  const loading = useHookState(false)
  const region = useHookState(sling.state.project.region)
  const plan = useHookState(sling.state.project.plan)
  const FreeRegions = [WorkerRegion.UnitedStatesEast, WorkerRegion.Germany]

  const itemTemplate = (item: ObjectAny) => {
    let key = 'region-'+item.value
    let tooltip = ''
    if(plan.get() === Plan.CloudFree && !FreeRegions.includes(item.value)) {
      tooltip = 'Only United States (East) and Germany are available on the free plan.'
    }

    return (
      <>
        <Tooltip target={"#"+key} style={{maxWidth: '300px'}} >
          {tooltip}
        </Tooltip>

        <div id={key} key={key} className="flex justify-content-start align-items-center">
          {/* <img
            src={'assets/connections/' + connSpec.icon}
            alt={key}
            height="20px"
            width="20px"
            style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
          /> */}
          <span className="flex justify-content-start ml-3">
            {item.label}
          </span>
        </div>
      </>
    )
  }

  return (
    <Dialog
      header="Choose Workspace Region"
      visible={show.get()}
      closable={false}
      footer={<div style={{textAlign: 'center'}}>
        <Button
          label='Save'
          className='p-button-success'
          icon={loading.get()? 'pi pi-spin pi-spinner':''}
          tooltipOptions={{position: 'top'}}
          disabled={
            !region.get() || 
            region.get() === WorkerRegion.Any ||
            (plan.get() === Plan.CloudFree && !FreeRegions.includes(region.get()))
          }
          onClick={async () => {
            loading.set(true)
            if(!await sling.saveProject(sling.state.project.get())) {
              rudderTrack('setup', 'region-set', sling.rudderProperties({region: region.get()}))
              loading.set(false)
              return toastError(`Error updating Workspace`)
            }
            show.set(false)
            window.location.reload() // refresh to load new region
          }} 
        />
      </div>} 
      onHide={() => show.set(false)}
    >
      <div
        style={{
          width: `300px`,
        }}
      >

        <div className="grid">
          <div className="col-12">
            <p>Select a region for your workspace. You can change this later.</p>
            <p>Be aware that if you need to whitelist IPs, you'll need to go to the Connections section. Once there, open a connection to view details, and you will see the IP addresses at the bottom of the form.</p>
          </div>

          <ListBox 
            value={region.get()}
            options={Object.keys(RegionMap).map(key => {return {
              label: RegionMap[key],
              value: key,
            }})}
            itemTemplate={itemTemplate}
            optionLabel='label'
            optionValue='value'
            onChange={(e) => { 
              if(plan.get() === Plan.CloudFree && !FreeRegions.includes(e.value)) {
                toastInfo('Region Selection', 'Only United States (East) and Germany are currently available on the free plan.', 6000)
              } else {
                region.set(e.value)
              }
            } }
            style={{width: '20rem', minHeight: '150px'}}
            listStyle={{ fontFamily:'monospace', minHeight: '250px', maxHeight: '250px' }}
          />
        </div>
      </div>
    </Dialog>
  );
}

export const WorkerSetupPanel: React.FC<Props> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const show = useHookState(props.show)
  const plan = useHookState(sling.state.project.plan)
  const token = useHookState('')
  const heartbeat_rcvd = useHookState(false)
  const [activeIndex, setActiveIndex] = React.useState(getOSIndex())

  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => {
    if(show.get()) {
      getToken()
      sling.wsCallbacks.worker_setup = async (msg: Message) => {
        if(msg.type === MessageType.WorkerHeartbeat) {
          let whb = msg.data as WorkerHeartbeat
          console.log(whb)
          if(!heartbeat_rcvd.get()) {
            await sling.loadWorkers()
            if(sling.state.workers.keys.length === 0) return
            toastSuccess('Worker registered!')
            heartbeat_rcvd.set(true)
          }
        }
      }
      return () => {
        delete sling.wsCallbacks.worker_setup
      }
    }
  }, [show.get()])  // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////

  const getToken = async () => {
    let resp = await sling.api.Post(Route.Workers)
    if(resp.error) toastError('Could not get worker token', resp.error)
    token.set(resp.data.token)
  }

  ///////////////////////////  JSX  ///////////////////////////
  const footer = () => {
    return <div style={{textAlign: 'center'}}>
        <Button
          label={heartbeat_rcvd.get()? "Worker Registered!!" : 'Waiting' }
          icon={heartbeat_rcvd.get()? "pi pi-check" : 'pi pi-spin pi-spinner' }
          className={heartbeat_rcvd.get()? "p-button-success" : "p-button-secondary" }
          tooltip={heartbeat_rcvd.get()? "Yay!" : 'Waiting for worker to register. Click button to manually check.'}
          tooltipOptions={{position: 'top'}}
          onClick={async () => {
            if(!heartbeat_rcvd.get()) {
              await sling.loadWorkers()
              if(sling.state.workers.keys.length > 0) show.set(false)
              else toastInfo('No registered workers found.')
              return
            }

            // save plan
            plan.set(Plan.SelfHostedFree)
            if(await sling.setPlan(plan.get())) {
              show.set(false)
            }
            rudderTrack('setup', 'plan-set', sling.rudderProperties({plan: plan.get()}))
          }} 
        />

        {
          heartbeat_rcvd.get() ? 
          null
          :
          <>
            <Button
              label={ 'Previous' }
              tooltip='Go back to Worker Mode selection'
              tooltipOptions={{position: 'top'}}
              className='p-button-warning ml-2'
              onClick={async () => { 
                plan.set(Plan.Unselected)
                show.set(false)
              }} 
            />

            <Button
              label={ 'Log Out' }
              className='p-button-info ml-2'
              onClick={async () => { sling.logout() }} 
            />
          </>
        }
    </div>
  }

  if (!sling.userAtLeastAdmin) {
    return <WorkspaceNotSetup show={props.show}/>
  }

  return (
    <Dialog
      header="Setup Worker"
      visible={show.get()}
      closable={false}
      footer={footer()} 
      onHide={() => show.set(false)}
    >
      <TabView
        style={{ textAlign: "center" }}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Mac">
            <UnixPanel token={token.get()} os='mac'/>
        </TabPanel>
        <TabPanel header="Windows">
            <WindowsPanel token={token.get()}/>
        </TabPanel>
        <TabPanel header="Linux">
            <UnixPanel token={token.get()} os='linux'/>
        </TabPanel>
        
      </TabView>
    </Dialog>
  );
};


const UnixPanel: React.FC<{token: string, os: 'mac' | 'linux'}> = (props) => { 

  let docker_url = props.os === 'mac'? 'https://docs.docker.com/desktop/install/mac-install/' : 'https://docs.docker.com/desktop/install/linux-install/'
  return (
    <div
      style={{
        width: `600px`,
      }}
    >
      <h3>Set Crendentials</h3>

      <p>Open your terminal and run these commands to create the Sling <code>env.yaml</code> file with the worker token. This is where you'll store your connection credentials. See <a href="https://docs.slingdata.io/sling-cli/environment" target='_blank' rel="noopener noreferrer">here</a> for more details.</p>
      <div className="code-block">
        <code>
          <p style={{margin: 0}}>mkdir -p ~/.sling/</p> 
          <p style={{margin: 0}}>echo '</p>
          <p style={{margin: 0}}>worker:</p>
          <p style={{margin: 0}}>&nbsp&nbsptoken: { props.token }</p>
          <p style={{margin: 0}}>' {'>>'} ~/.sling/env.yaml</p>
        </code>
      </div>
      
      <br />
      <h3>Start Worker</h3>
      <p>Run this command to launch the worker via Docker. If you don't have docker installed, please follow <a href={docker_url} target='_blank' rel="noopener noreferrer">these steps</a> to do so. You do not need to open any ports, the worker initiates and maintains the connection. Once it is started and successfully registers, the button below will turn green.</p>
      <div className="code-block">
        <code>
          <p>docker run -d -v ~/.sling:/root/.sling --restart always --name sling-worker slingdata/sling-worker</p> 
        </code>
      </div>

    </div>
  )
}


const WindowsPanel: React.FC<{token: string}> = (props) => { 

  let docker_url = 'https://docs.docker.com/desktop/install/windows-install/'
  return (
    <div
      style={{
        width: `600px`,
      }}
    >
      <h3>Set Crendentials</h3>

      <p>Open your terminal and run these commands to create the Sling <code>env.yaml</code> file with the worker token. This is where you'll store your connection credentials. See <a href="https://docs.slingdata.io/sling-cli/environment" target='_blank' rel="noopener noreferrer">here</a> for more details.</p>
      <div className="code-block">
        <code>
          <p style={{margin: 0}}>mkdir -Force -p "$env:USERPROFILE\.sling"</p> 
          <p style={{margin: 0}}>echo @"</p>
          <p style={{margin: 0}}>worker:</p>
          <p style={{margin: 0}}>&nbsp&nbsptoken: { props.token }</p>
          <p style={{margin: 0}}>"@ {'>>'} "$env:USERPROFILE\.sling\env.yaml"</p>
        </code>
      </div>
      
      <br />
      <h3>Start Worker</h3>
      <p>Run this command to launch the worker via Docker. If you don't have docker installed, please follow <a href={docker_url} target='_blank' rel="noopener noreferrer">these steps</a> to do so. You do not need to open any ports, the worker initiates and maintains the connection. Once it is started and successfully registers, the button below will turn green.</p>
      <div className="code-block">
        <code>
          <p>docker run -d -v "$env:USERPROFILE\.sling":/root/.sling --restart always --name sling-worker slingdata/sling-worker</p> 
        </code>
      </div>

    </div>
  )
}

const WorkspaceNotSetup: React.FC<Props> = (props) => { 
  const show = useHookState(props.show)
  const showWorkspaceDialog = useHookState(false)
  return <Dialog
    header="Setup Workspace"
    visible={show.get()}
    closable={false}
    footer={<div style={{textAlign: 'center'}}>
      <Button
        label={ 'Log Out' }
        className='p-button-info ml-2'
        onClick={async () => { sling.logout() }} 
      />
      <WorkspaceChooser show={showWorkspaceDialog}/>
      <Button
        label={ 'Switch Workspaces' }
        className='p-button-secondary ml-2'
        onClick={async () => { showWorkspaceDialog.set(true) }} 
      />
    </div>} 
    onHide={() => show.set(false)}
  >
    <div
      style={{
        width: `400px`,
      }}
    >
      <h3>Workspace is not setup</h3>
      <p>Please have an admin log in and setup the workspace.</p>
    </div>
  </Dialog>
}


const getOSIndex = () => {
  let OsIndex = 0
  if (navigator.userAgent.indexOf("Win") !== -1) OsIndex = 1;
  if (navigator.userAgent.indexOf("Mac") !== -1) OsIndex = 0;
  if (navigator.userAgent.indexOf("Linux") !== -1) OsIndex = 2;
  return OsIndex
}