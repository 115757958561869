import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView,TabPanel } from 'primereact/tabview';
import * as React from "react";
import { none, State, } from "@hookstate/core";
import { ConfirmPopup, ConfirmPopupProps } from 'primereact/confirmpopup';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { FileUpload } from 'primereact/fileupload';
import { Password } from 'primereact/password';
import { blankConnSpec, Connection, ConnKind, ConnSpec, ConnType, SpecProp } from "../core/connection";
import { useHookState } from "../core/store";
import { jsonClone, snake_to_camel, title_case, toastError, toastSuccess } from "../utilities/methods";
import { t } from "../utilities/translator";
import { sling } from "../App";
import { ListBox } from "primereact/listbox";
import { ObjectString } from "../utilities/interfaces";
import { confirmDialog } from 'primereact/confirmdialog'
import { Calendar } from 'primereact/calendar';
import { Tooltip } from "primereact/tooltip";
import { WorkerRegion } from "../core/worker";
import { rudderTrack } from "..";

interface Props {
  show: State<boolean>
  conn: State<Connection>
}

export const ConnDialog: React.FC<Props> = (props) => {
  const activeIndex = useHookState(props.conn.get().id === undefined ? 0 : 1) // set default tab, if conn id exists, then is not a new connection
  const deleteConfirm = useHookState<ConfirmPopupProps>({message: 'Confirm deletion of connection?'})
  const loadingTest = useHookState(false)
  const loadingSave = useHookState(false)
  const connSpecs = useHookState(sling.state.settings.app.connection_specs)
  const [connSpec, setConnSpec] = React.useState<ConnSpec>(blankConnSpec())

  const hasType = props.conn.get().type !== undefined
  const hasId = props.conn.get().id !== undefined && props.conn.get().id !== ''

  
  React.useEffect(() => { 
    if (hasId) {
      let cs = connSpecs.get()[props.conn.type.get()]
      setConnSpec(cs)
      props.conn.data.name.set(props.conn.name.get())
      rudderTrack('connection', 'existing-connection-dialog', sling.rudderProperties())
    } else {
    // focus in search box
      setTimeout(() => {
        let searchEl = window.document.querySelector('#conn-type-chooser > div > div.p-listbox-header > div > input')
        if(searchEl) (searchEl as HTMLElement).focus()
      }, 200);
      rudderTrack('connection', 'new-connection-dialog', sling.rudderProperties())
    }
  }, []); // eslint-disable-line

  ////////////////////////////////////////////////// methods
  const chooseConnType = (typeKey: ConnType) => {
    let cs = connSpecs.get()[typeKey]
    props.conn.type.set(typeKey)
    setConnSpec(cs)
    for(let k in cs.properties) {
      props.conn.data[k].set(cs.properties[k].default ? `${cs.properties[k].default}`:'')
    }
    activeIndex.set(1)
    rudderTrack('connection', 'new-connection-choose-type', sling.rudderProperties({type: typeKey}))
  }

  ///////////////////////////  FUNCTIONS  ///////////////////////////

  const checkResetAndHide = (confirmed = false) => {
    // check if data has changed
    if (!confirmed) {
      let showConfirmation = false
      let initConnData: ObjectString = {}
      if (hasId) {
        initConnData = jsonClone<ObjectString>(sling.state.connections[props.conn.get().id].data.get())
      }
      // if (Object.keys(initConnData).length !== props.conn.data.keys.length) {
      //   showConfirmation = true
      // }

      for (let key of Object.keys(initConnData)) {
        if (initConnData[key] !== props.conn.data[key].get()) {
          showConfirmation = true
        }
      }

      if (showConfirmation) {
        confirmDialog({
          message: 'Changes will be lost. Are you sure you want to close?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => checkResetAndHide(true),
        })
        return
      }
    }
    props.show.set(false)
    props.conn.set(new Connection()) // reset for next conn
}

  const validateConn = (): boolean => {
    let data = jsonClone<ObjectString>(props.conn.get().data)
    for(let req_key of connSpec.required) {
      let property = connSpec.properties[req_key]
      let title = property.title || title_case(snake_to_camel(req_key))
      if (!(req_key in data)) {
        toastError("Missing field", `"${title}" is required`)
        return false
      } else if (!data[req_key]) { 
        toastError("Missing value for field", `"${title}" is required`)
        return false
      }
    }
    // fix name
    props.conn.data.name.set(data.name.replaceAll(/\W+/ig, '_').toUpperCase())
    // put SSH_TUNNEL
    if (data.SSH_HOST) {
      if (!data.SSH_USER) {
        toastError("Missing value for field 'SSH User'")
        return false
      }
      data.SSH_PORT = data.SSH_PORT || '22'
      data.SSH_PASSWORD = data.SSH_PASSWORD || ''
      let ssh_tunnel = `ssh://${data.SSH_USER}:${data.SSH_PASSWORD}@${data.SSH_HOST}:${data.SSH_PORT}`
      props.conn.data['SSH_TUNNEL'].set(ssh_tunnel)
    }
    return true
  }

  const testConn = async () => {
    if(!validateConn()) return
    loadingTest.set(true)
    let result = await sling.testConnection(props.conn.get())
    props.conn.connectivity.set(result.success)
    if (result.success) {
      toastSuccess('Connection test successful!')
    } else {
      toastError(`Error testing connection ${props.conn.name.get()}`, result.error)
    }
    rudderTrack('connection', 'connection-test', sling.rudderProperties({type: props.conn.type.get(), 'success': result.success}))
    loadingTest.set(false)
  }

  const saveConn = async () => {
    if (!validateConn()) return
    loadingSave.set(true)
    props.conn.data['url'].set(none) // so that url get regenerate by backend
    let id = await sling.saveConnection(props.conn.get())
    loadingSave.set(false)
    rudderTrack('connection', 'connection-save', sling.rudderProperties({connection_id: id,type: props.conn.type.get()}))
    if (id) checkResetAndHide(true)
    await sling.loadConnections()
  }

  const deleteConn = async () => {
    rudderTrack('connection', 'connection-delete', sling.rudderProperties({connection_id: props.conn.id.get(),type: props.conn.type.get()}))
    if(await sling.deleteConnection(props.conn.get())) {
      deleteConfirm.visible.set(false)
      checkResetAndHide()
      await sling.loadConnections()
    }
  }
  

  const uploaderGooogle = (cell: SpecProp, event: { xhr: XMLHttpRequest;  formData: any;}) => {
    // this is needed to save teh body of text into the form memory    
    // https://stackoverflow.com/questions/49087286/upload-local-file-to-localstorage-without-server-interaction
    var reader = new FileReader();

    reader.onload = () => {
      // validate it is a valid service account JSON
      let value = reader.result?.toString()
      try {        
        if(value === undefined) { throw new Error('Empty body?') }
        let json_dict = JSON.parse(value)
        if(!("project_id" in json_dict && "private_key" in json_dict && "client_email" in json_dict)) {
          toastError('Error', `Invalid Service Account JSON file.`)
          return
        }
        props.conn.data['project_id'].set(json_dict["project_id"])
      } catch(err) {
        toastError('Error', `Invalid Service Account JSON file.`)
        return
      }

      props.conn.data[cell.key].set(value ? value : '')
      if(props.conn.get().data[cell.key].length > 10) {
        toastSuccess('Success', 'File Uploaded')
      } else {
        toastError('Error', `Could not upload File`)
      }
    }
    
    for(var pair of event.formData.entries()) {
      reader.readAsText(pair[1]) 
    }
  }
  

  ///////////////////////////  JSX  ///////////////////////////

  const IPs = (region: WorkerRegion) => {
    let region_ips = sling.state.settings.app.region_ips.get()
    for(let key of Object.keys(region_ips)) {
    if(key === region) return region_ips[region].join(', ')
    }
    return ''
  }

  const TabPanelTypes = () => {
    
    const itemTemplate = (item: ConnSpec) => (
      <div className="flex align-items-center" style={{ paddingLeft: '.5em', paddingRight: '.5em' }}>
        <img
          src={'assets/connections/' + item.icon}
          alt={item.title}
          height="20px"
          width="20px"
          style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
        />
        <span
          style={{
            display: 'inline-flex',
            // alignItems: 'center',
            fontSize: '17px',
            fontFamily: 'monospace',
            margin: '0 0 0 10px',
            // paddingBottom: '-5px',
          }}
        >
          {item.title}
        </span>
      </div>
    )

    return (
      <TabPanel key='tab-panel-type' header="Choose Type" disabled={hasId}>
        <div className="p-grid">
          <div id='conn-type-chooser' className="p-col">
            <ListBox
              // value={selectedItem}
              id='connections'
              options={Object.values(connSpecs.get())}
              onChange={(e) => chooseConnType(e.value)}
              filter
              optionLabel="title"
              optionValue="key"
              itemTemplate={itemTemplate}
              style={{ width: '100%' }}
              listStyle={{ minHeight: '250px', maxHeight: '250px' }}
            />
          </div>
        </div>
      </TabPanel>
    )
  }

  const getFormFieldInput = (cell: SpecProp) => {

    let date_suffix = cell.format.startsWith('YYYY-MM-DD') ?
          cell.format.replace('YYYY-MM-DD', '') : '' 
    let input : JSX.Element = <></>
    switch(cell.type) {
      case 'boolean':
        input = <div><InputSwitch
          id={'form-'+cell.key.replaceAll('.', '-')}
          tooltip={cell.description}
          tooltipOptions={{ style: {maxWidth:'300px'}, autoHide: false}}
          checked={props.conn.get().data[cell.key] === 'true'}
          onChange={(e) => props.conn.data[cell.key].set(e.value.toString())}
        /></div>
        break;
      case 'dropdown':
        input = <Dropdown
          id={'form-'+cell.key.replaceAll('.', '-')}
          value={ props.conn.get().data[cell.key] }
          options={ cell.options.map(v => { return {label: `${v}`, value: `${v}`} }) }
          onChange={(e) => props.conn.data[cell.key].set(e.value) }
          tooltip={cell.description}
          tooltipOptions={{ style: {maxWidth:'300px'}, autoHide: false}}
          optionLabel="label"
          optionValue="value"
          placeholder="Select..."
        />
        break;
      case 'textarea':
        input = <InputTextarea
          id={'form-'+cell.key.replaceAll('.', '-')}
          tooltip={cell.description}
          tooltipOptions={{ style: {maxWidth:'300px'}, autoHide: false}}
          value={props.conn.get().data[cell.key]}
          onChange={(e) => props.conn.data[cell.key].set(e.target.value)}
          placeholder={connSpec.required.includes(cell.key) ? cell.default || 'Required' : 'Optional'}
        />
        break;
      case 'date':
        input = <Calendar
          id={'form-'+cell.key.replaceAll('.', '-')}
          value={ 
            props.conn.get().data[cell.key] ?
            new Date(props.conn.get().data[cell.key].replace(date_suffix, '')) : undefined
          }
          onChange={(e) => {
            let value = e.value
            if(!value) return
            if(value instanceof Date) {
              let date_str = value.toISOString().split('T')[0]
              props.conn.data[cell.key].set(date_str + date_suffix)
              props.conn.data['airbyte_date_layout'].set(cell.format)
            }
          }}
          tooltip={cell.description}
          tooltipOptions={{ style: {maxWidth:'300px'}, autoHide: false}}
          dateFormat="yy-mm-dd"
          placeholder="Choose Date"
        />
        break;
      // case 'integer':
      //   input = <InputNumber
      //     id={'form-'+cell.key}
      //     tooltip={cell.description}
      //     value={props.conn.vars[cell.key].get()}
      //     onChange={(e) => props.conn.vars[cell.key].set(parseInt(e.value))}
      //     useGrouping={false}
      //   />
      //   break;
      case 'upload-google':
        input = <FileUpload
          id={'form-'+cell.key.replaceAll('.', '-')}
          // mode="basic"
          chooseLabel={"Upload File"}
          name="file"
          maxFileSize={20000}
          onBeforeSend={(e) => { uploaderGooogle(cell, e) }}
          emptyTemplate={() => <p className="p-m-0">Drag and drop JSON file here to upload.</p>}
          // auto
          multiple
        />
        break;
      default:
        if(cell.secret) {
        
          input = <>
            <Tooltip
              target={'#form-'+cell.key.replaceAll('.', '-')}
              autoHide={false}
              style={{maxWidth:'300px'}}
            >
              {cell.description}
            </Tooltip>
            <Password
              id={'form-'+cell.key.replaceAll('.', '-')}
              feedback={false}
              placeholder={connSpec.required.includes(cell.key) ? cell.default || 'Required Secret' : 'Optional Secret'}
              value={jsonClone(props.conn.get())[cell.key]}
              onChange={(e) => props.conn.data[cell.key].set((e.target as HTMLInputElement).value)}
              
            />
          </>
        } else {
          input = <InputText
            id={'form-'+cell.key.replaceAll('.', '-')}
            disabled={cell.key === 'name' && !!props.conn.get().id}
            tooltip={cell.description}
            tooltipOptions={{ style: {maxWidth:'300px'}, autoHide: false}}
            type="text"
            value={props.conn.get().data[cell.key]}
            onChange={(e) => {
              let val = (e.target as HTMLInputElement).value
              if(cell.key === 'name') val = val.replaceAll(/\W+/ig, '_').toUpperCase()
              props.conn.data[cell.key].set(val)
            }}
            placeholder={connSpec.required.includes(cell.key) || cell.key === 'name' ? cell.default || 'Required' : 'Optional'}
            
          />
        }
    }
    return input
  }

  const sshSection = () => (
    <>
      <div key='form-ssh' className="col-12">
        <label>Use SSH Tunnel</label>
        <div><InputSwitch
          tooltip="use SSH Tunnel to connect"
          checked={props.conn.get().data.ssh === 'true'}
          onChange={(e) => props.conn.data.ssh.set(e.value.toString())}
        /></div>
      </div>
      { props.conn.get().data.ssh === 'true' ? 
        <>
          <div className="col-12 md:col-6">
              <label htmlFor="ssh-host">SSH Host</label>
              <InputText
                id="ssh-host"
                type="text"
                tooltip="The hostname or IP of the SSH server"
                value={props.conn.get().data.SSH_HOST}
                onChange={(e) => props.conn.data.SSH_HOST.set((e.target as HTMLInputElement).value)}
              />
          </div>
          <div className="col-12 md:col-6">
              <label htmlFor="ssh-port">SSH Port</label>
              <InputText
                id="ssh-port"
                type="text"
                tooltip="The port of the SSH server"
                value={props.conn.get().data.SSH_PORT}
                onChange={(e) => props.conn.data.SSH_PORT.set((e.target as HTMLInputElement).value)}
              />
          </div>
          <div className="col-12 md:col-6">
              <label htmlFor="ssh-user">SSH username</label>
              <InputText
                id="ssh-user"
                type="text"
                tooltip="The username to use to access the SSH server"
                value={props.conn.get().data.SSH_USER}
                onChange={(e) => props.conn.data.SSH_USER.set((e.target as HTMLInputElement).value)}
                
              />
          </div>
          <div className="col-12 md:col-6">
              <label htmlFor="ssh-password">SSH Password</label>
              <Password
                id="ssh-password"
                feedback={false}
                tooltip="Optional: The password to use to access the SSH server"
                value={props.conn.get().data.SSH_PASSWORD}
                onChange={(e) => props.conn.data.SSH_PASSWORD.set((e.target as HTMLInputElement).value)}
                
              />
          </div>
          <div className="col-12">
              <label> Sling SSH Public Key (See <a href="https://docs.slingdata.io/connections/database-connections#using-a-ssh-tunnel" target="_blank" rel="noopener noreferrer">here</a> how to add)</label>
              <InputTextarea
                readOnly
                tooltip={`Optional: No Password needed if this public key is registered on your SSH server's 'authorized_keys' file.`}
                tooltipOptions={{ style: {maxWidth:'300px'}}}
                value={sling.state.settings.app.ssh_public_key.get()}
              />
          </div>
        </> : null
        }
    </>
  )

  const TabPanelDetails = () => {
    return (
      <TabPanel key='tab-panel-details' header="Enter Details" disabled={!hasType}>
        <div className="grid p-fluid">
          <div key='form-div-title' className="col-12 flex align-items-center justify-content-center">
            <img
              src={'assets/connections/' + connSpec.icon}
              alt={connSpec.title}
              height="40px"
              width="40px"
              style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
            />
            <span
              style={{
                display: 'inline-flex',
                // alignItems: 'center',
                fontSize: '17px',
                fontFamily: 'monospace',
                margin: '0 0 0 10px',
                // paddingBottom: '-5px',
              }}
            >
              {connSpec.title}
            </span>
          </div>
          {
            // dynamic form
            connSpec.form.map(row => {
              return row.map(key => {
                if(key === '_blank') return (<></>)
                if(key === '_ssh') return sshSection()
                let cell = connSpec.properties[key];
                return (
                  <div key={'form-div-'+key} className={`col-12 md:col-${12/row.length}`} >
                    <label htmlFor={'form-' + cell.key.replaceAll('.', '-')}>
                      {cell.title}
                      {
                        cell.ref_url ?
                        <span style={{fontSize: '9px'}}> (<a href={cell.ref_url} target='_blank' rel="noopener noreferrer">reference</a>) </span>
                        :
                        null
                      }
                    </label>
                    {getFormFieldInput(cell)}
                    {/* <small id={'form-' + cell.key + '-help'} className="p-error block">{ cell.title } is required</small> */}
                  </div>
                )
              })
            })
          }

          {
            [ConnKind.Database, ConnKind.API, ConnKind.Airbyte]
              .includes(connSpec.kind) && !sling.state.project.get().is_self_hosted ?
              <>
                <div key='form-div-ips-1' className="col-12 flex align-items-center justify-content-center" style={{paddingTop: '0px', fontSize: '12px' }}>
                  <span>
                    <span>Here are Sling Cloud's Worker IPs (for whitelisting):</span>
                  </span>
                </div>
                <div key='form-div-ips-2' className="col-12 flex align-items-center justify-content-center" style={{paddingTop: '0px', fontSize: '12px' }}>
                  <span>
                    <span>
                      <strong>{ IPs(sling.state.project.region.get()) }</strong>
                    </span> 
                  </span>
                </div>
              </>
            : 
            null
          }

          <div key='form-div-help' className="col-12 flex align-items-center justify-content-center" style={{paddingTop: '0px'}}>
            <span
              style={{ fontSize: '12px' }}
            >
              Click <a href={`https://docs.slingdata.io/connections/${connSpec.kind}-connections/${connSpec.key}`} target="_blank" rel="noopener noreferrer">here</a> for documentation on using {connSpec.title}.
            </span>
          </div>
        </div>
      </TabPanel>
    )
  }

  const dialogFooter = () => (
    <div className="flex align-items-center justify-content-center">
        { hasType ? 
          <Button label={t('Test')} onClick={() => testConn() } icon={loadingTest.get()? 'pi pi-spin pi-spinner':''} className="p-button-info mr-2"/> 
          : null }

        { hasType ? <Button label={t('Save')} onClick={() => saveConn() } icon={loadingSave.get()? 'pi pi-spin pi-spinner':'pi pi-check'} className="p-button-success mr-2"/> : null }

        <Button label={t('Cancel')} onClick={() => checkResetAndHide()} icon="pi pi-times" className="p-button-secondary mr-2"/>

        { hasId ? <Button id='delete-connection' label={t('Delete')} onClick={() => deleteConfirm.visible.set(true)} icon="pi pi-times" className="p-button-danger mr-2"/> : null }
        <ConfirmPopup
          target={document.getElementById('delete-connection') as HTMLElement}
          visible={deleteConfirm.get().visible}
          message={deleteConfirm.get().message}
          icon="pi pi-exclamation-triangle"
          onHide={(result: string) => { if(result==='accept') { deleteConn() } }}
        />
    </div>
  )

  ////////////////////////////////////////////////// main JSX

  if(sling.state.project.get().is_self_hosted) {
    return <Dialog
      visible={props.show.get()}
      closable={false}
      footer={<div style={{textAlign: 'center'}}>
        <Button
          label={ 'Close' }
          className='p-button-info'
          onClick={async () => { props.show.set(false) }} 
        />
      </div>} 
      onHide={() => props.show.set(false)}
    >
      <div
        style={{
          width: `300px`,
        }}
      >
        <h3>Workspace Connections</h3>
        <p>Since this workspace is operating under Self-Hosted mode, Sling is unable to create or edit Connections. Please access the proper <a href="https://docs.slingdata.io/sling-cli/environment#sling-env-file-.sling-env.yaml" target='_blank' rel="noopener noreferrer">env.yaml</a> file where the worker lives and add the connection there.</p>
      </div>
    </Dialog>
  }

  return (
    <div>
        <Dialog
          header={t('Connection Form')}
          visible={props.show.get()}
          style={{width: '40vw', maxWidth: '550px'}}
          modal={true}
          closeOnEscape={true}
          onHide={() => checkResetAndHide()}
        >
          <TabView 
            id="connection-dialog-tabview"
            activeIndex={activeIndex.get()}
            onTabChange={(e) => activeIndex.set(e.index)}
          >
            {TabPanelTypes()}
            {TabPanelDetails()}
          </TabView>

          {dialogFooter()}

        </Dialog>
    </div>
  )
}