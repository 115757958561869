import _ from "lodash";
import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import * as React from "react";
import { rudderstack } from "..";
import { sling } from "../App";
import { Route } from "../core/api/routes";
import { Plan } from "../core/project";
import { useHookState } from "../core/store";
import { Role, User } from "../core/user";
import { itemsFiltered, jsonClone, toastError, toastInfo, toastSuccess, useWindowDimensions } from "../utilities/methods";

interface Props {}

export const UsersView: React.FC<Props> = (props) => {

  const users = useHookState<User[]>([])
  const loading = useHookState(false)
  const { height } = useWindowDimensions();
  const filter = useHookState('');
  const newUser = {
    ref: React.useRef<OverlayPanel>(null),
    email: useHookState(''),
    role: useHookState<Role>('' as Role),
  }
  const roles = [Role.Admin, Role.Power, Role.Spectator]

  ///////////////////////////  HOOKS  ///////////////////////////
  React.useEffect(() => { 
    document.title = 'Sling - Users'
    rudderstack.page(document.title, '', sling.rudderProperties())
    refresh()
  }, []); // eslint-disable-line

  ///////////////////////////  EFFECTS  ///////////////////////////
  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const refresh = async () => {
    loading.set(true)
    let data = await sling.loadUsers()
    users.set(data)
    loading.set(false)
  }


  const usersFiltered = () => {
    return itemsFiltered<User>(
      _.orderBy(users.get(), ['name'], ['asc']),
      filter.get(),
      (v) => new User(v)
    )
  }

  ///////////////////////////  JSX  ///////////////////////////

  const lastActiveBody = (user: User, column: ColumnBodyOptions) => {
    if(!user.created_dt) return <span><i>Pending Invite</i></span>
    return <span> { user.last_login_date_str }</span>
  }

  const roleBody = (user: User, column: ColumnBodyOptions) => {
    return <>
    <Dropdown
      value={user.role}
      style={{width: '100%'}}
      disabled={user.id === sling.state.user.id.get() || !sling.userAtLeastAdmin }
      options={ roles }
      onChange={async (e) => {
        user = new User(jsonClone(user))
        user.role = e.target.value
      
        if(await sling.saveUser(user)) {
          toastSuccess('Successfully updated.')
        }
        refresh()
      }}
    />
    {/* <span className='status success'> {user.role} </span> */}
    </>
  }

  const blockedBody = (user: User, column: ColumnBodyOptions) => {
    return <>
      <InputSwitch
        checked={ user.blocked }
        tooltip={ user.blocked ? 'Unblock user access' : 'Block user access'}
        tooltipOptions={{position: 'top'}}
        disabled={user.id === sling.state.user.id.get() || !sling.userAtLeastAdmin}
        onChange={async (e) => { 
          user = new User(jsonClone(user))
          user.blocked = e.target.value
        
          if(await sling.saveUser(user)) {
            toastSuccess('Successfully updated.')
          }
          refresh()
        }}
      />
    </>
  }

  const actionsBody = (user: User, column: ColumnBodyOptions) => {
    return <div>
        <Button
          type="button"
          icon="pi pi-trash"
          disabled={user.id === sling.state.user.id.get() || !sling.userAtLeastAdmin}
          className="p-button-danger p-button-sm small-button"
          tooltip="Remove User"
          tooltipOptions={{position: 'top'}}
          style={{ marginRight: '.5em' }}
          onClick={async () => {
              confirmDialog({
                message: `Are you sure you want to remove ${user.email} from this workspace?`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                  if(await sling.api.Delete(Route.Users, {id: user.id})) {
                    toastSuccess("User Removed")
                  }
                  refresh()
                },
              })
          }}
        />
    </div>;
  }


  const UserDialog = <OverlayPanel
    ref={newUser.ref}
    showCloseIcon
    dismissable>

    <p className="mb-1"><strong>Input Email and Role</strong></p>
    <p>
    <InputText
      id='new-user-email'
      type="text"
      placeholder="user@company.com"
      value={newUser.email.get()}
      onInput={(e:any) => { newUser.email.set(e.target.value as string) }}
      style={{width: '200px'}}
    /></p>

    <p>
    <Dropdown
      id='new-user-role'
      placeholder="select Role"
      value={newUser.role.get()}
      options={ roles }
      onChange={(e) => { newUser.role.set(e.target.value) }}
      style={{width: '200px'}}
    />
    </p>

    <Button
      label="Send Invite"
      className="ml-2"
      onClick={async (e) => {
        if(!(/^\S+@\S+\.\S+$/.test(newUser.email.get()))) return toastError(`Invalid Email`)
        if(!roles.includes(newUser.role.get())) return toastError(`Invalid Role`)
        let data = {
          email: newUser.email.get(),
          role: newUser.role.get(),
        }
        let response = await sling.api.Post(Route.Users, data)
        if(response.error) return toastError(`Error Inviting New User`, response.error)
        toastSuccess("Email Sent!")
        refresh()
        newUser.ref.current?.hide()
      }}
    />
  </OverlayPanel>


  return (
    <div
      id="notifications-panel"
      className="grid"
    >
      {UserDialog}
      {/* HEADER */}
      <div className="col-4 justify-content-start">
        <Button
          id='add-new-user'
          icon='pi pi-plus'
          disabled={!sling.userAtLeastPower || (sling.isDemoUser && !sling.userAtLeastAdmin)}
          label='Invite User'
          className="p-button-success p-button-sm"
          onClick={async (e) => { 
            if(sling.state.project.plan.get() === Plan.CloudFree || 
              sling.state.project.plan.get() === Plan.SelfHostedFree
            ) return toastInfo('Need to upgrade plan to invite others.')
            
            newUser.email.set('')
            newUser.role.set('' as Role)
            newUser.ref.current?.toggle(e)
          }}
        />
      </div>
      <div className="col-4">
        <h2 style={{ textAlign: "center", marginBottom: 0 }}> {'USERS'}</h2>
      </div>
      <div className="col-4 flex justify-content-end">
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              className="p-inputtext-sm"
              value={filter.get()}
              onChange={(e) => filter.set(e.target.value)}
              onKeyDown={(e) => {
                if(e.key === 'Escape') { filter.set('') } 
              }}
              placeholder="Search"
              size={15}
            />
            <Button
              icon={loading.get() ? 'pi pi-spin pi-spinner' :"pi pi-refresh"}
              className="p-button-info p-button-sm"
              style={{marginLeft: '-40px'}}
              onClick={async (e) => { 
                refresh()
              }}
            />
        </span>
      </div>
      <div
      className="grid"
      style={{
        paddingTop: '10px',
        maxHeight: `${window.innerHeight - 270}px`,
        width: '100%',
        overflowY: 'scroll',
      }}
      >
        <div className="col-12">
          <div className="card">
            <div>
              <DataTable
                value={usersFiltered()}
                loading={loading.get()}
                responsiveLayout="scroll"
                scrollable scrollHeight={`${height - 150}px`}
                emptyMessage='No users found'
                dataKey="id"
              >
                <Column header="Name" field="name" style={{minWidth: '20%', textAlign:"center", overflowX: 'scroll'}} sortable />
                <Column header="Email" field="email" style={{minWidth: '20%', overflowX: 'scroll'}} sortable />
                <Column header="Role" body={roleBody} style={{minWidth: '10%'}} sortable />
                <Column header="Last Active" hidden={!sling.userAtLeastAdmin} body={lastActiveBody} style={{minWidth: '15%'}} className="flex justify-content-center" sortable />
                <Column header="Blocked" hidden={!sling.userAtLeastAdmin} body={blockedBody} style={{width:'10%', textAlign:"center"}} className="flex justify-content-center" />
                <Column header="Actions" hidden={!sling.userAtLeastAdmin} body={actionsBody} style={{width:'10%', textAlign:"center"}} className="flex justify-content-center" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
