import React  from 'react';
import { useHistory } from 'react-router-dom';
import { sling } from '../../App';
import { WorkspaceChooser } from '../WorkspaceChooser';
import { Link } from "react-router-dom";
import { useHookState } from '../../core/store';
import { getMobileOperatingSystem } from '../../utilities/methods';

export const AppTopbar = (props) => {
    async function onLogout() {
      await sling.logout()
    }
    let history = useHistory();
    const workspace = useHookState(sling.state.project.name)
    const showWorkspaceDialog = useHookState(false)
    const isMobile = getMobileOperatingSystem() !== undefined
  
    return (
      <div className="layout-topbar">
            <WorkspaceChooser show={showWorkspaceDialog}/>

            {/* <Link className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/>
                <span>SLING</span>
            </Link> */}

            <button type="button" className="p-link layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            
              <span style={{
                position: 'absolute',
                top: 25,
                left: 83,
                fontSize: '1.1em',
              }}>
                <a href='/#/' onClick={() => showWorkspaceDialog.set(true)}>
                  { 
                    !isMobile ?
                    <strong> {workspace.get()} </strong>
                    : null
                  }
                  <i className="pi pi-chevron-down ml-1"/>
                </a>
              </span>
        
        <span
          className="layout-topbar-logo" onClick={() => { history.push('/') }}
          style={{
          position: 'absolute',
          top: 15,
          left: '50%',
          fontSize: '1.1em',
          transform: 'translate(-50%, 0%)',
        }}
        >
          <Link to={`/`}>
            <img
              src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-with-text.png' : 'assets/layout/images/logo-with-text-white.png'}
              alt="logo"
              />
          </Link>
        </span>

        <span style={{
          position: 'absolute',
          top: 25,
          right: 70,
          fontSize: '1.1em',
        }}>
          <Link to={`/settings`}>
            { 
              !isMobile ?
              <strong> {sling.state.user.name.get()} </strong>
              : null
            }
          </Link>
        </span>

        <span style={{
          position: 'absolute',
          top: 14,
          right: 20,
          fontSize: '1.1em',
        }}>
          <button id="sign-out" className="p-link layout-topbar-button" onClick={onLogout}>
          <i className="pi pi-sign-out" />
              <span>Sign Out</span>
          </button>
          
        </span>
    </div>
  );
}
