import React from "react";
import { useHookState } from "../core/store";
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from "primereact/inputtext";
import { blankConnSpec, Connection, ConnKind, connRef, orderConns } from "../core/connection";
import { Link, useHistory } from "react-router-dom";
import { ListBox } from "primereact/listbox";
import { sling } from "../App";
import { Dialog } from "primereact/dialog";
import { State } from "@hookstate/core";
import { t } from "../utilities/translator";
import { getSchemas } from "../core/schemata";
import { getDefaults, Replication, slingLoadedAtColumn } from "../core/replication";
import { Dropdown } from 'primereact/dropdown';
import { CRUD } from "../core/api/api";
import { itemsFiltered, jsonClone, toastError, toastInfo, useIsMounted, useWindowDimensions } from "../utilities/methods";
import { DefaultSourceOptionsFile, DefaultTargetOptionsDB, DefaultTargetOptionsFile, JobMode, jobModeDescription, SourceOptions, TargetOptions } from "../core/job";
import { JobSchedule } from "../components/JobSchedule";
import { Tooltip } from "primereact/tooltip";
import _ from "lodash";
import { Checkbox } from "primereact/checkbox";
import { rudderstack, rudderTrack } from "..";

export const ReplicationsView: React.FC<{}> = (props) => {
  const showDialog = useHookState(false);
  const loading = useHookState(false);
  const filter = useHookState('');
  const replications = useHookState(sling.state.replications)
  const { height } = useWindowDimensions();

  const replicationsFiltered = () => {
    let replics = Object.values(replications.get())
    replics = _.orderBy(Object.values(replications.get()), ['active', 'config.source', 'config.target'], ['desc', 'asc', 'asc'])
    return itemsFiltered<Replication>(
      replics,
      filter.get(),
      (r) => new Replication({ 
        ...r,
        source_name: connRef(r.source_id).name,
        target_name: connRef(r.target_id).name,
      })
    )
  }

  React.useEffect(() => {
    document.title = 'Sling - Replications'
    rudderstack.page(document.title, '', sling.rudderProperties())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>

      { showDialog.get() ? <ReplicationDialog show={showDialog} />  : null }
      
      <div className="grid">
        {/* TITLE */}
        <div className="col-4 justify-content-start">
          <Button
            id='add-new-replication'
            disabled={ !sling.userAtLeastPower }
            icon='pi pi-plus'
            label='New Replication'
            className="p-button-success p-button-sm"
            onClick={async (e) => { 
              showDialog.set(true)
            }}
          />
        </div>
        <div className="col-4">
          <h2 style={{ textAlign: "center", marginBottom: 0 }}> {'REPLICATIONS'}</h2>
        </div>
        <div className="col-4 flex justify-content-end">
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                className="p-inputtext-sm"
                value={filter.get()}
                onChange={(e) => filter.set(e.target.value)}
                onKeyDown={(e) => {
                  if(e.key === 'Escape') { filter.set('') } 
                }}
                placeholder="Search"
                size={15}
              />
              <Button
                icon={loading.get() ? 'pi pi-spin pi-spinner' :"pi pi-refresh"}
                className="p-button-info p-button-sm"
                style={{marginLeft: '-40px'}}
                onClick={async (e) => { 
                  loading.set(true)
                  await sling.loadReplications() 
                  loading.set(false)
                }}
              />
          </span>
        </div>
        
        {/* HEADERS */}
        <div className="col-4">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}> {'Source'}</h4>
        </div>
        <div className="col-4">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}> {'Target'}</h4>
        </div>
        <div className="col-2">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}> {'Status'}</h4>
        </div>
        <div className="col-2">
          <h4 style={{ textAlign: "center", marginBottom: 0 }}> {'Active'}</h4>
        </div>

        {/* ITEMS */}

        <div className="grid" style={{
          maxHeight: `${height - 250}px`,
          overflowY: 'scroll',
          width: "100%",
        }}>
          {
            replicationsFiltered().map(
              (replication) => {
                return <>
                  <div className="col-12">
                    <CardRow replication={replication}/>
                  </div>
                </>
              }
            )
          }
          {
            replications.keys.length === 0 ?
            <div className="col-12">
              <CardRowNew show={showDialog}/>
            </div>
            :
            null
          }
        </div>
      </div>
    </>
  )
};

const CardRow: React.FC<{ replication: Replication }> = (props) => {
  let source = connRef(props.replication.source_id)
  let target = connRef(props.replication.target_id)
  const cell_id = `r-${props.replication.id}-status`

  if(!source.name) {
    source.name = props.replication.config.source
    toastError(`Was a connection altered?`, `Could not find source conn ${source.name}`)
  }
  if(!target.name) {
    target.name = props.replication.config.target
    toastError(`Was a connection altered?`, `Could not find target conn ${target.name}`)
  }

  const openReplication = () => {}

  return (
    <div id={`replication-${source.name.toLowerCase()}-${target.name.toLowerCase()}`} className="card mb-0" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: '10px', paddingTop: '10px' }}>
      <Link to={`/replication?rid=${props.replication.id}`}>
        <div className="grid">
          <div className="col-4 flex justify-content-center align-items-center" onClick={(e) => { openReplication() }}>
            <ConnBlock conn={source}/>
          </div>
          <div className="col-4 flex justify-content-center align-items-center" onClick={(e) => { openReplication() }}>
            <ConnBlock conn={target} />
          </div>
          <div className="col-2 flex justify-content-center align-items-center" onClick={(e) => { openReplication() }}>
            <Tooltip target={'#'+cell_id} position='right'>
              { props.replication.streams_cnt } objects
            </Tooltip>
            <span id={cell_id} className="text-green-500 font-large" style={{fontSize: '20px'}}>{ props.replication.status } </span>
          </div>
          <div className="col-2 flex justify-content-center align-items-center" onClick={(e) => {
            if (!(e.target as HTMLDivElement)?.className.includes('inputswitch')) {
              openReplication()
            }
          }}>
            <ReplicationActiveSwitch replication={props.replication}  tooltipPosition='right'/>
          </div>
        </div>
      </Link>
    </div>
  ) 
}

const ConnBlock: React.FC<{ conn: Connection }> = (props) => {
  let connSpec = sling.state.settings.app.connection_specs.get()[props.conn.type]
  if (!connSpec) connSpec = blankConnSpec()
  

  return (
      <div className="col-4 flex justify-content-center align-items-center">
        <img
          src={'assets/connections/' + connSpec.icon}
          alt={connSpec.title}
          height="40px"
          width="40px"
          style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
        />
        <div style={{margin: '0 0 0 15px'}}>
          <span className="block font-medium text-xl mb-1"> {props.conn.name} </span>
          <div className="text-600 font-medium text-l">{ connSpec?.title}</div>
        </div>
      </div>
  ) 
}

const CardRowNew: React.FC<{show: State<boolean>}> = (props) => {

  return (
    <div className="card mb-0" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: '10px', paddingTop: '10px' }}>
      <a
        href="#/replications"
        onClick={(e) => { 
          e.preventDefault();
          if(sling.userAtLeastPower) props.show.set(true) 
        }}
      >
        <div className="grid">
          <div className="col-12 flex justify-content-center align-items-center">
            <div className="flex align-items-center">
              <span className="font-medium text-xl mr-3" style={{color: 'black'}}>
                <i> ADD NEW REPLICATION</i>
              </span>
            </div>
            <div className="flex align-items-center justify-content-center border-round">
              <Button
                className="p-button-success" 
                disabled={ !sling.userAtLeastPower }
                icon="pi pi-plus"
                onClick={(e) => { }}
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  ) 
}

const ReplicationDialog: React.FC<{show: State<boolean>}> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const replication = useHookState(new Replication())
  const connections = useHookState(sling.state.connections)
  const source = useHookState(new Connection())
  const target = useHookState(new Connection())
  const loading = useHookState(false)
  const step = useHookState(1)
  const history = useHistory()
  const title = useHookState('')
  const isMounted = useIsMounted()
  
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    rudderTrack('replication', 'new-replication-dialog', sling.rudderProperties())
  }, []); // eslint-disable-line
  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const hide = () => {
    props.show.set(false)
  }

  const selectedSourceAndTarget = () => {
    return source.id?.get() && target.id?.get()
  }

  ///////////////////////////  JSX  ///////////////////////////
  

  const listBoxTemplate = (conn: Connection) => {
    let connSpec = sling.state.settings.app.connection_specs[conn.type].get()
    if (!connSpec) connSpec = blankConnSpec(conn.type)
    return (
      <div key={'list-'+conn.id} className="flex justify-content-start align-items-center">
        <img
          src={'assets/connections/' + connSpec.icon}
          alt={connSpec.title}
          height="20px"
          width="20px"
          style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
        />
        <span className="flex justify-content-start ml-3">
          {conn.name}
        </span>
      </div>
    )
  }

  const SettingsForm = () => {
    return (
      <>
        <div className="grid">
          <div className="col-12">
            <div className="card p-fluid">
              <h5>Default Target Object Name Pattern</h5>
              <div className="formgrid grid">
                  <div className="field col">
                    <InputText 
                      id="form-name-pattern"
                      type="text"
                      className="p-inputtext-sm"
                      value={ replication.config.defaults.object.get() }
                      onChange={(e)=>{ replication.config.defaults.object.set(e.target.value) }}
                    />
                    <small>Enter the pattern to use for naming objects in target connection. See <a target='_blank' href="https://docs.slingdata.io" rel="noopener noreferrer">here</a> for documentation.</small>
                  </div>
              </div>
            </div>
          </div>
            
          <div className="col-6">
            <div className="card p-fluid">
              <h5>Default Mode</h5>
              <div className="field">
                  <Dropdown
                    id="form-mode"
                    className="p-inputtext-sm mb-2"
                    options={ target.is_file.get() ? [JobMode.FullRefreshMode] : Object.values(JobMode) }
                    value={ replication.config.defaults.mode.get() }
                    onChange={(e)=>{ replication.config.defaults.mode.set(e.target.value) }}
                    tooltip={jobModeDescription(replication.config.defaults.mode.get())}
                    tooltipOptions={{position: 'left', style: {maxWidth: '300px'}}}
                  />
                  <br />
              </div>
            </div>
          </div>
            
          <div className="col-6">
            <div className="card p-fluid">
              <h5>Default Schedule</h5>
              <JobSchedule schedule={replication.config.defaults.schedule} />
            </div>
          </div>
            
          {
            target.is_database.get() ?
            <div className="col-6">
              <div className="card p-fluid">
                <h5>Add New Columns?</h5>
                <div className="field">
                  <Checkbox
                    inputId='options-new-columns'
                    tooltip="Whether to auto add in destination table newly added source stream columns"
                    tooltipOptions={{position: 'bottom'}}
                    onChange={async (e) => {
                      replication.config.defaults.target_options.set(
                        opts => {
                          if(!opts) opts = {}
                          opts.add_new_columns = e.checked
                          return opts
                        }
                      )
                    }}
                    checked={ replication.config.defaults.target_options.get()?.add_new_columns }
                  />
                    <br />
                </div>
              </div>
            </div>
            :
            null
          }
            
          <div className="col-12">
            { dialogFooter() }
          </div>
        </div>
      </>
    )
  }

  const SourceTargetForm = () => {
    return (
      <div className="grid">
          <div id='replication-source-form' className="col-6">
            <div className="flex justify-content-center">
              <label htmlFor="source-connections"><h4 >Source</h4></label>
            </div>
            <ListBox
              id='source-connections'
              value={replication.source_id.get()}
              options={orderConns(Object.values(connections.get()).filter(c => c.is_database || c.is_file || c.is_airbyte || c.is_file))}
              onChange={(e) => { 
                source.set(new Connection(jsonClone(connections.get()[e.value])))
                replication.source_id.set(e.value)
              }}
              filter={false}
              multiple={false}
              optionLabel="name"
              optionValue="id"
              listStyle={{ minHeight: '250px', maxHeight: '250px' }}
              itemTemplate={listBoxTemplate}
              tooltip="Select a source Database, File or API Connection"
              tooltipOptions={{ position: 'left' }}
            />
          </div>

          <div id='replication-target-form' className="col-6">
            <div className="flex justify-content-center">
              <label htmlFor="target-connections"><h4 >Target</h4></label>
            </div>
            <ListBox
              id='target-connections'
              value={replication.target_id.get()}
              options={orderConns(Object.values(connections.get()).filter(c => c.is_writable))}
              onChange={(e) => { 
                target.set(new Connection(jsonClone(connections.get()[e.value])))
                replication.target_id.set(e.value)
              }}
              filter={false}
              multiple={false}
              optionLabel="name"
              optionValue="id"
              listStyle={{ minHeight: '250px', maxHeight: '250px' }}
              itemTemplate={listBoxTemplate}
              tooltip="Select a target Database or File Connection"
              tooltipOptions={{ position: 'right' }}
            />
          </div>
          
          <div className="col-12">
            { dialogFooter() }
          </div>
      </div>
    )
  }

  const validateStep = async () => {
    if (step.get() === 1) {
      if(sling.isDemoUser) return toastInfo('Want to create replications?', 'Please go to https://slingdata.io and sign up to create all the replications you want!', 6000)

      loading.set(true)

      // check if connection is supported
      let connSpecs = sling.state.settings.app.connection_specs.get()
      if(!connSpecs[source.type.get()] && blankConnSpec(source.type.get()).kind === ConnKind.Unknown) {
        loading.set(false)
        return toastError('Source conn not supported')
      }
      
      if(!connSpecs[target.type.get()] && blankConnSpec(target.type.get()).kind === ConnKind.Unknown) {
        loading.set(false)
        return toastError('Target conn not supported')
      }

      // check if replication from source to target exists
      let where = ['source_eq', source.id.get(), 'target_eq' , target.id.get()]
      let records = await CRUD().replications.List({ where })
      if (records.length === 1) {
        toastInfo('Existing replication found. Redirecting...')
        hide()
        history.push(`/replication?rid=${records[0].id}`)
        return
      }

      // set title
      title.set(`${source.name.get()} -> ${target.name.get()}`)

      // set name pattern
      let { object_pattern, mode } = getDefaults(source.get(), target.get())
      replication.config.defaults.mode.set(mode)
      replication.config.defaults.object.set(object_pattern)

      if(source.is_file.get()) {
        replication.config.defaults.source_options.set(DefaultSourceOptionsFile as SourceOptions)
        replication.config.defaults.update_key.set(slingLoadedAtColumn)
      }
      if(target.is_file.get()) {
        replication.config.defaults.target_options.set(DefaultTargetOptionsFile as TargetOptions)
      } else if(target.is_database.get()) {
        replication.config.defaults.target_options.set(DefaultTargetOptionsDB as TargetOptions)
      }


      loading.set(false)
      step.set(v => v + 1)
      rudderTrack('replication', 'new-replication-choose-connections', sling.rudderProperties({'source_type': source.type.get(), 'target_type': target.type.get()}))
    } else if (step.get() === 2) {
      loading.set(true)
      let id = await createReplication()
      if (id) {
        rudderTrack('replication', 'replication-save', sling.rudderProperties({'replication_id': id}))
        hide()
        history.push(`/replication?rid=${id}`)
      } else {
        loading.set(false)
      }
    }
  }

  const createReplication = async () => {
    if(source.get().is_database) {
      let schemaRecords = await getSchemas(source.get())
      for (let rec of schemaRecords) {
        source.schemata[rec.schema_name].set([])
        sling.state.connections[source.id.get()].schemata[rec.schema_name].set([])
      }
    }

    // set conn names
    if(!isMounted.current) return
    let id = await sling.saveReplication(replication)
    await sling.loadReplications()

    // update default notifications
    if(id && await sling.loadSettings('project')) {
      sling.state.settings.project.replication_notifications.set(
        rn => {
          rn = rn || {}
          rn[id as number] = { 
            email: true,
            slack: sling.state.settings.project.slack.get()?.webhook_url !== undefined,
            msteams: sling.state.settings.project.msteams.get()?.webhook_url !== undefined,
            on_failure: true,
          }
          return rn
        }
      )
      sling.saveSettings('project', sling.state.settings.project.get())
    }

    return id
  }

  const header = () => {
    return <>
      <div className="flex justify-content-center">
        {t(title.get())}
      </div>
    </>
  }

  const dialogFooter = () => (
    <div className="flex align-items-center justify-content-center">

      <Button label={t('Cancel')} onClick={() => hide()} icon="pi pi-times" className="p-button-secondary p-mr-4" style={{marginRight: '10px'}}/>

      <Button label={step.get() === 1 ? t('Next') : t('Create')} icon={loading.get()? 'pi pi-spin pi-spinner':'pi pi-check'} onClick={() => validateStep()} disabled={ !selectedSourceAndTarget()} className="p-button-success p-ml-4"/>
    </div>
  )
  
  return <>
    <Dialog
      header={header()}
      visible={props.show.get()}
      style={{width: '40vw', maxWidth: '550px'}}
      modal={true}
      closeOnEscape={true}
      onHide={() => props.show.set(false)}
    >
      {
        step.get() === 1 ?
          SourceTargetForm() :
          SettingsForm()
        }
    </Dialog>
  </>
};

export const ReplicationActiveSwitch: React.FC<{replication: Replication, tooltipPosition: 'top' | 'bottom' | 'left' | 'right', refresh?: State<number>}> = (props) => {
  return <>
    <InputSwitch
      disabled={!sling.userAtLeastPower}
      checked={ props.replication.active }
      tooltip={ props.replication.active ? 'Turn Schedule Off' : 'Turn Schedule On'}
      tooltipOptions={{position: props.tooltipPosition}}
      onChange={async (e) => { 
        if(sling.isDemoUser) return toastInfo('Want to toggle schedules?', 'Please go to https://slingdata.io and sign up to toggle all the schedules you want!', 6000)
        let replicationId = props.replication.id
        if(replicationId) {
          let replication = sling.state.replications[replicationId]
          replication.active.set(e.value)

          await sling.saveReplication(replication)
          await sling.loadReplications()
          props.refresh?.set(v => v + 1)
        }
      }}
    />
  </>
}