import _ from "lodash";
import { ObjectAny, ObjectString, RecordsData } from "./interfaces";
import { Toast, ToastMessage } from 'primereact/toast';
import { FormEvent, RefObject, useEffect, useRef, useState } from "react";
import { State } from "@hookstate/core";
import { Variable } from "../core/store";
import { rudderstack } from "..";
import { sling } from "../App";
const crypto = require('crypto')

export const alpha  = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const alphanumeric  = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export const serialize = function(obj: ObjectString) : string {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const dict = function(arr : Array<{ [key: string]: string }>, key: string) {
  let obj : { [key: string]: string | object; } = {}
  for (let item of arr) {
    obj[item[key]] = item
  }
  return obj
}

export const post_form = function(path : string, params: ObjectString, method='post') {

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export const new_ts_id = function(prefix='') {
  return `${prefix}${Date.now()}.${rand_str(alpha, 3)}`
}

export const rand_str = function(characters : string, length : number) {
  var result           = '';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const snake_to_camel = function(str : string) {
  return str.replace(
  /([-_][a-z])/g,
  (group) => group.toUpperCase()
                  .replace('-', '')
                  .replace('_', '')
  )
}

export const clean_title = function(str : string) {
  return str.toUpperCase().replace(" ", "_")
}

export const title_case = function(str : string) {
  return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
}

export const data_req_to_records = function(data: RecordsData, lower=false){
  let records : any[] = []
  if(!data?.rows || !data?.headers || !Object.keys(data)?.length || !data.rows.length || !data.headers.length) return records
  if(lower) data.headers = data.headers.map(v => v.toLowerCase())

  for (let row of data.rows) {
    let rec : ObjectAny = {}
    for (let i = 0; i < data.headers.length; i++) rec[data.headers[i]] = row[i]
    records.push(rec)
  }
  return records
}

export const split_schema_table = function(schema_table: string) {
  if(schema_table.includes('.')) {
    let arr = schema_table.split('.')
    return {schema: arr[0], table: arr[1]}
  }
  return {schema: '', table: schema_table}
}

export const filter_dt = function(orig_data : ObjectAny[], query : string) {
  if(!query) return orig_data
  let queries = query.toLowerCase().split(',')
  return orig_data.filter((r) => {
    let found = queries.map(() => false)
    for (const k in r) {
      for (let i = 0; i < queries.length; i++) {
        if(`${r[k]}`.toLowerCase().includes(queries[i])) found[i] = true
      }
    }
    return found.every((v) => v)
  })
}

export const do_filter = _.debounce((args: any) => { 
  args.dt.loading = true
  args.dt.data = args.filter_dt(args.dt.orig_data, args.query)
  args.dt.loading = false
}, 500)

type resolution = 'second' | 'minute' | 'hour' | 'day'
export const get_duration = function(secs : number, resolution : resolution ='second') {
  let neg = ''
  if(secs == null || isNaN(secs)) return '-'

  if (secs < 0) neg = 'n'

  secs = Math.abs(secs)
  if (secs < 60) return `${secs}s` 
  if (secs < 3600) {
    let mins = Math.floor(secs/60)
    secs = secs - mins*60
    return `${neg}${mins}m${secs}s`
  }

  if (secs < 3600*24) {
    let hours = Math.floor(secs/3600)
    secs = secs - hours*3600
    let mins = Math.floor(secs/60)
    secs = secs - mins*60
    return `${neg}${hours}h${mins}m`
  }

  let days = Math.floor(secs/3600/24)
  secs = secs - days*3600*24
  let hours = Math.floor(secs/3600)
  secs = secs - hours*3600
  let mins = Math.floor(secs/60)
  secs = secs - mins*60
  return `${neg}${days}d${hours}h`
}

export function jsonClone<T = any>(val: any) { 
  if (IsValid(val)) {
    return JSON.parse(JSON.stringify(val)) as T
  }
  return {} as T
}

export function jsonCloneKeys<T = any>(obj: any) { 
  if (IsValid(obj)) {
    let data : ObjectAny = {}
    for (let key of Object.keys(obj)) {
      data[key] = obj[key]
      JSON.stringify(data)
    }
    return JSON.parse(JSON.stringify(data)) as T
  }
  return {} as T
}

export const clearTooltips = function() {
  var elements = document.getElementsByClassName('p-tooltip')
  for(var e of elements as any) e.parentNode.removeChild(e);
}

export const calc_duration = function(date1: Date, date2: Date) {
  if(!date1) return '?'
  if(!date2) date2 = new Date()
  try {
    let secs = Math.floor((date2.getTime() - date1.getTime())/1000)
    return get_duration(secs)
  } catch(error) {
    console.error(error)
    return '-'
  }
}

export const relative_duration = function(date: Date|undefined, past_allowed=true) {
  if(!date) return ''
  let dur = calc_duration(date, new Date())
  if(dur === '-') return dur

  if(dur.startsWith('n')) {
    dur = dur.replace('n', '')
    return `in ${dur}`
  }
  if(!past_allowed) return '-'
  return `${dur} ago`
}

export const number_with_commas = function(x: number) {
  if(!x) return x
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export interface Funcs { 
  toast: (msg: ToastMessage) => void
  toastError: (summary: string, detail: string|null) => void
  toastSuccess: (summary: string, detail: string|null) => void
  toastInfo: (summary: string, detail: string|null) => void
}

export const genFuncs = (toast: RefObject<Toast>): Funcs => {
  return {
    toastError: (summary: string, detail: string|null) => doToast(toast, {
      severity:  'error',
      summary: `${summary}`,
      detail: `${detail}`,
    }),
    toastSuccess: (summary: string, detail: string|null) => doToast(toast, {
      severity:  'success',
      summary: `${summary}`,
      detail: `${detail}`,
    }),
    toastInfo: (summary: string, detail: string|null) => doToast(toast, {
      severity:  'info',
      summary: `${summary}`,
      detail: `${detail}`,
    }),
    toast: (msg: ToastMessage) => doToast(toast, {
      severity:  msg.severity,
      summary: `${msg.summary}`,
      detail: `${msg.detail}`,
      life: msg.life,
    })  
  }
}

export const doToast = (toast: any, msg: ToastMessage, duration=3000) => {
  if(toast === null || toast.current === null) return console.error('toast is null')
  msg.life = msg.severity === 'error' ? 9000 : duration;
  (toast.current as Toast).show(msg);
}

export const toastError = (summary: string, detail: any='') => {
  if(detail.toString().includes('Not logged in')) return
  console.error(detail)
  if(detail.response && detail.response.data && detail.response.data.error) {
    detail = detail.response.data.error
  }
  doToast(window.toast, {
    severity:  'error',
    summary: `${summary}`,
    detail: `${detail}`,
  })

  // Rudderstack Tracking
  let data = {
    title: document.title,
    summary: `${summary}`,
    detail: `${detail}`,
  }
  rudderstack.track('error', sling.rudderProperties(data))
}

export const toastContent = (msg: ToastMessage, duration=5000) => doToast(window.toast,msg, duration)

export const toastSuccess = (summary: string, detail: string='') => doToast(window.toast, {
  severity:  'success',
  summary: `${summary}`,
  detail: `${detail}`,
})

export const toastInfo = (summary: string, detail: string='', duration=3000) => doToast(window.toast, {
  severity:  'info',
  summary: `${summary}`,
  detail: `${detail}`,
}, duration)

export const inputOnChange = (e: FormEvent<HTMLInputElement>, setFunc: React.Dispatch<any>) => setFunc((e as React.ChangeEvent<HTMLInputElement>).target.value)

export function useEffectAsync(asyncFn: any, onSuccess: any) {
  useEffect(() => {
    let isMounted = true;
    asyncFn().then((data: any) => {
      if (isMounted) onSuccess(data);
    });
    return () => { isMounted = false };
  }, []); // eslint-disable-line
}

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false };
  }, []);

  return isMounted;
}

export function IsValid(obj: any) {
  return Object.keys(obj || {}).length !== 0
}

export function IsValidDate(obj: any) {
  return Object.keys(obj).length !== 0 && obj.toLocaleString() !== 'Invalid Date'
}

export function copyToClipboard(text: string, toast='Copied to clipboard') {
  var textField = document.createElement('textarea')
  textField.value = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  if(toast) toastInfo(toast)
}

export const md5 = (text: string) : string => {
  return crypto.createHash('md5').update(text).digest('hex')
}


export const setFilter = _.debounce(
  (filter: State<string>, newVal: string) => filter.set(newVal), 400
)


export const setFilterRefresh = _.debounce(
  (filter: State<string> | Variable<string>, newVal: string, refresh: (force: boolean) => Promise<void>) => {
    filter.set(newVal)
    refresh(false)
  }, 400
)

// parseFilterString returns the filter string split into tokens
// where words inside quotes represent one token
// filterStr = 'hello dear brother' => ['hello', 'dear', 'brother']
// filterStr = 'hello "dear brother"' => ['hello', '"dear brother"']
export let parseFilterString = (filterStr: string) => { 
  let filters : string[] = [];
  let inQuote = false;
  let token = ""
  for (let i = 0; i < filterStr.length; i++) {
    const char = filterStr[i];
    if (char === '"') inQuote = !inQuote 
    token = token + char
    if ((!inQuote && char === ' ') || i === filterStr.length - 1) {
      token = token.trim()
      if(token.length > 0) filters.push(token)
      token = ''
    }
  }
  return filters
}

export const filterAndMatched = (row: any[], filters: string[]) => { 
  filters = filters.filter(v => v.trim() !== '')
  if(filters.length === 0) return true
  let include = filters.map(v => false)
  for(let val of row) {
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i].toLowerCase().trim()
      if (filter.startsWith('"') && filter.endsWith('"')) {
        if (`${val}`.toLowerCase() === filter.replaceAll('"', '')) include[i] = true
      } else {
        if (`${val}`.toLowerCase().includes(filter)) include[i] = true
      }
    }
    if(include.every(v => v === true)) { return true }
  }
  return include.every(v => v === true)
} 

export const itemsFiltered = <T>(origItems: T[], filter: string, newItem : (v: any) => T) => {
  let items : T[] = []
  let filters = parseFilterString(filter)
  for(let item of origItems) {
    item = newItem(jsonClone(item))
    if(filterAndMatched(Object.values(item), filters)) items.push(item)
  }
  return items
}

export function delete_cookie(name: string, domain?: any) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  if(domain) document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${domain}`
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

// intervalSecs is the interval to wait to allow trigger
// onBlur is whether to trigger when blurring the window (un-focusing)
export const useWindowFocus = (intervalSecs = 60, onBlur = false) => {
  const [windowFocus, setwindowFocus] = useState(true);
  const lastRefreshed = useRef<number>((new Date()).getTime()/1000)

  const intervalPassed = () => {
    const currTime = (new Date()).getTime()/1000
    if((currTime - lastRefreshed.current) > intervalSecs) {
      lastRefreshed.current = currTime
      return true
    }
    return false
  }

  useEffect(() => {
    function setTrue() {
      if(intervalPassed()) setwindowFocus(true);
    }
    function setFalse() {
      if(intervalPassed()) setwindowFocus(false);
    }

    window.addEventListener('focus', setTrue);
    if(onBlur) window.addEventListener('blur', setFalse);

    return () => {
      window.removeEventListener('focus', setTrue);
      if(onBlur) window.removeEventListener('blur', setFalse);
    };
  }, []); // eslint-disable-line

  return windowFocus;
}

// flattenObj(obj, '')
export const flattenObj = (obj: any, parent: string, res : any = {}) => {
  for(let key in obj){
    let propName = parent ? parent + '.' + key : key;
    if(typeof obj[key] == 'object'){
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}

export function download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const searchString = (string: string, pattern: RegExp) => {
    let result = [];

    const matches = string.match(new RegExp(pattern.source, pattern.flags));

    if(matches)
      for (let i = 0; i < matches.length; i++) {
          result.push(new RegExp(pattern.source, pattern.flags).exec(matches[i]));
      }

    return result;
};

 /**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return undefined
}