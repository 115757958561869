import { State } from "@hookstate/core";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Slider } from "primereact/slider";
import * as React from "react";
import { sling } from "../App";
import { useHookState } from "../core/store";

export const interval_map : { [key: number]: {label: string; schedule: string}; } = {
  0: {label: 'No schedule', schedule: ''},
  1: {label: 'Every 1 min', schedule: "@every 1m"},
  2: {label: 'Every 5 min', schedule: "@every 5m"},
  3: {label: 'Every 15 min', schedule: "@every 15m"},
  4: {label: 'Every 30 min', schedule: "@every 30m"},
  5: {label: 'Every hour', schedule: "@every 1h"},
  6: {label: 'Every 2 hours', schedule: "@every 2h"},
  7: {label: 'Every 6 hours', schedule: "@every 6h"},
  8: {label: 'Every 12 hours', schedule: "@every 12h"},
  9: {label: 'Every day', schedule: "@every 24h"},
  10: {label: 'Every 2 day', schedule: "@every 48h"},
  11: {label: 'Every 3 day', schedule: "@every 72h"},
  12: {label: 'Every week', schedule: "@every 168h"},
}

export const JobSchedule: React.FC<{ schedule: State<string | undefined>} > = (props) => {
  const mode = useHookState<'interval'|'cron'>('interval')
  const interval = useHookState(4)
  const options = [{name:'Interval', code:'interval'},{name:'Cron', code:'cron'}]

  React.useEffect(() => {
    if(!props.schedule.get()) props.schedule.set('')
    
    // parse interval
    for(let k of Object.keys(interval_map)) {
      if(interval_map[parseInt(k)].schedule === props.schedule.get()) {
        interval.set(parseInt(k))
        mode.set('interval')
        return
      }
    }
    mode.set('cron')
  }, [props.schedule.get()]); // eslint-disable-line react-hooks/exhaustive-deps

  const setInterval = (val: number) => {
    if(!(val in interval_map)) return
    interval.set(val)
    props.schedule.set(interval_map[val].schedule)
  }

  const setCronExpr = (val: string) => {
    // validate the crone expr?
    props.schedule.set(val)
  }

  return (
    <>
      <SelectButton
        id="schedule-select-button"
        value={mode.get()}
        onChange={(e) => mode.set(e.value)}
        options={options}
        optionLabel="name"
        optionValue="code"
        className="mb-2 p-inputtext-sm"
        tooltip={
          sling.state.project.is_free.get() ?
          `In order to schedule tasks, you'll need to sign up for a paid plan`
          : undefined
        }
        tooltipOptions={{position: 'right', style: {maxWidth: '200px'}}}
      />

      { mode.get() === 'interval' ?
        <>
          <label htmlFor="interval" className="mb-2">Interval: {interval_map[interval.get()].label }</label>
          <Slider
            id="interval"
            v-model="single.schedule.interval"
            value={interval.get()}
            onChange={(e) => setInterval(e.value as number)}
            step={1}
            min={0}
            max={Object.keys(interval_map).length}
            className="mb-1 mt-3"
            style={{paddingBottom: 4.25}}
            disabled={ sling.state.project.is_free.get() }
          />
        </>
        :
        <>
          {/* <label htmlFor="schedule" className="mb-2">Cron Expression</label> */}
          <InputText
            id="schedule"
            className="p-inputtext-sm mb-1"
            placeholder="8 0 * * *"
            value={props.schedule.get()}
            onChange={(e) => setCronExpr((e.target as HTMLInputElement).value)}
            disabled={ sling.state.project.is_free.get() }
            type="text" />
        </>
      }
    </>
  );
};