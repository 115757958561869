import { ObjectAny } from "../utilities/interfaces"
import { jsonClone } from "../utilities/methods";
import { Connection, ConnKind, ConnType } from "./connection";
import { DefaultSourceOptionsFile, DefaultTargetOptionsDB, DefaultTargetOptionsFile, getJobType, JobMode, JobType, SourceOptions, TargetOptions } from "./job";

export type ReplicationMap = { [key: number]: Replication; }

export const slingLoadedAtColumn = "_sling_loaded_at"

export const DefaultReplicationSettings : ReplicationSettings = {
  retries: 1,
  retry_delay: 30,
}

export interface ReplicationSettings {
  region?: string,
  retries: number,
  retry_delay: number,
}

export interface ReplicationStreamConfig {
  mode?: JobMode;
  columns?: string[];
  object?: string;
  primary_key?: string[];
  update_key?: string;
  sql?: string;
  schedule?: string;
  source_options?: SourceOptions;
  target_options?: TargetOptions;
  disabled?: boolean;
}

export interface ReplicationStream {
  primary_key?: string[];
  update_key?: string;
}

export type ReplicationStreamMap = { [key: string]: ReplicationStreamConfig; }

export interface ReplicationConfig {
  source: string;
  target: string;
  defaults: ReplicationStreamConfig;
  streams: ReplicationStreamMap;
}

export class Replication {
  id?: number
  source_id: string
  target_id: string
  active: boolean
  status: string
  config: ReplicationConfig
  settings: ReplicationSettings
  source_name?: string // for filter
  target_name?: string // for filter

  constructor(data: ObjectAny = {}) {
    this.id = data.id || undefined
    this.source_id = data.source_id
    this.target_id = data.target_id
    this.active = data.active || false
    this.status = data.status || 'OK'
    this.config = data.config || {
      defaults: {
        mode: JobMode.FullRefreshMode,
        // object: '',
        schedule: '',
        source_options: {},
        target_options: {},
      },
      streams: {},
    }
    
    if(!this.config.streams) this.config.streams = {}

    this.settings = data.settings || DefaultReplicationSettings
    
    // for filter
    this.source_name = data.source_name
    this.target_name = data.target_name

    // trim config
    this.trimConfig()

    // FIXME: temp fix for deprecated object_name prop
    // this.config.defaults.object = this.config.defaults.object || (this.config.defaults as any).object_name || ''
    // for(let name of Object.keys(this.config.streams || {})) {
    //   this.config.streams[name].object = this.config.streams[name].object || (this.config.streams[name] as any).object_name || ''
    // }
  }

  trimConfig() {
    //DefaultSourceOptionsFile
    if(this.config.defaults.source_options?.compression === DefaultSourceOptionsFile.compression)
      delete this.config.defaults.source_options.compression
    if(this.config.defaults.source_options?.datetime_format === DefaultSourceOptionsFile.datetime_format)
      delete this.config.defaults.source_options.datetime_format
    if(this.config.defaults.source_options?.delimiter === DefaultSourceOptionsFile.delimiter)
      delete this.config.defaults.source_options.delimiter
    if(this.config.defaults.source_options?.empty_as_null === DefaultSourceOptionsFile.empty_as_null)
      delete this.config.defaults.source_options.empty_as_null
    if(this.config.defaults.source_options?.header === DefaultSourceOptionsFile.header)
      delete this.config.defaults.source_options.header
    if(this.config.defaults.source_options?.skip_blank_lines === DefaultSourceOptionsFile.skip_blank_lines)
      delete this.config.defaults.source_options.skip_blank_lines
    if(this.config.defaults.source_options?.trim_space  === DefaultSourceOptionsFile.trim_space)
      delete this.config.defaults.source_options.trim_space

    // DefaultTargetOptionsFile
    if(this.config.defaults.target_options?.use_bulk === DefaultTargetOptionsFile.use_bulk)
      delete this.config.defaults.target_options.use_bulk
    if(this.config.defaults.target_options?.header === DefaultTargetOptionsFile.header)
      delete this.config.defaults.target_options.header
    if(this.config.defaults.target_options?.compression === DefaultTargetOptionsFile.compression)
      delete this.config.defaults.target_options.compression
    if(this.config.defaults.target_options?.datetime_format === DefaultTargetOptionsFile.datetime_format)
      delete this.config.defaults.target_options.datetime_format
    if(this.config.defaults.target_options?.delimiter === DefaultTargetOptionsFile.delimiter)
      delete this.config.defaults.target_options.delimiter

    // DefaultTargetOptionsDB
    if(this.config.defaults.target_options?.use_bulk === DefaultTargetOptionsDB.use_bulk)
      delete this.config.defaults.target_options.use_bulk
    if(this.config.defaults.target_options?.add_new_columns === DefaultTargetOptionsDB.add_new_columns)
      delete this.config.defaults.target_options.add_new_columns
  }

  get schedule() {
    return this.config?.defaults?.schedule || '-'
  }

  get last_synch() {
    return this.config?.defaults?.schedule || '-'
  }

  get streams_cnt() {
    let active_streams : string[] = []
    for(let key of Object.keys(this.config.streams || {})) {
      let stream = this.config.streams[key]
      if(stream.disabled) continue
      active_streams = active_streams.concat([key])
    }
    
    return active_streams.length
    // return streams.length
  }

  schema_streams (schema: string) {
    let streams = Object.keys(this.config.streams || {}).filter(key => {
      return `${key}`.startsWith(schema + '.')
    })
    return streams
  }

  schema_selected = (schema: string) => {
    return this.schema_streams(schema)
                .map(k => this.config.streams[k])
                .some(sc => !sc.disabled)
  }

  get_stream_config = (key: string) => {
    return this.apply_defaults(this.config.streams[key] || {})
  }

  apply_defaults = (config: ReplicationStreamConfig) => {
    let stream_config = (config || {}) as ObjectAny
    let defaults = this.config.defaults as ObjectAny
    
    for(let key of Object.keys(this.config.defaults)) {
      if(!(key in stream_config)) {
        stream_config[key] = defaults[key]
      }
    }

    return stream_config as ReplicationStreamConfig
  }

  payload() {
    return {
      id: this.id,
      source_id: this.source_id,
      target_id: this.target_id,
      active: this.active,
      config: this.config,
      settings: this.settings,
    }
  }
}

export const getDefaults = (source: Connection, target: Connection) => {
  let object_pattern = ``
  let jobType = getJobType(jsonClone<Connection>(source), jsonClone<Connection>(target))
  let mode = JobMode.FullRefreshMode
  
  switch (jobType) {
    case JobType.DbToDb:
      object_pattern = `{target_schema}.{source_name}_{stream_schema}_{stream_table}`
      if(source.type === ConnType.DbBigTable)
        object_pattern = `{target_schema}.{source_name}_{stream_table}`
      break; 
    case JobType.FileToDB:
      object_pattern = `{target_schema}.{source_name}_{stream_file_path}`
      break; 
    case JobType.APIToDb:
      object_pattern = `{target_schema}.{source_name}_{stream_name}`
      mode = JobMode.FullRefreshMode
      break; 
    case JobType.DbToFile:
    case JobType.APIToFile:     
    case JobType.FileToFile: 
      let prefix = `${target.type}://`
      if ([ConnType.FileS3, ConnType.FileGoogle].includes(target.type)) {
        prefix = `${target.type}://{target_bucket}`
      } else if (target.type === ConnType.FileAzure) {
        prefix = `https://{target_account}.blob.core.windows.net/{target_container}`
      } else if (target.type === ConnType.FileLocal) {
        prefix = `file://{root_folder}`
      }

      if(source.kind === ConnKind.Database) {
        object_pattern = `${prefix}/{source_name}/{stream_schema}/{stream_table}/{run_timestamp}.csv`
      } else if(source.kind === ConnKind.API || source.kind === ConnKind.Airbyte) {
        object_pattern = `${prefix}/{source_name}/{stream_name}/{run_timestamp}.csv`
      } else if(source.kind === ConnKind.File) {
        object_pattern = `${prefix}/{source_name}/{stream_file_path}/{run_timestamp}.csv`
      }
      mode = JobMode.FullRefreshMode
      break; 
    default:
      break;
  }
  return { object_pattern, mode }
}