import { State } from "@hookstate/core";
import _ from "lodash";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import * as React from "react";
import { sling, useQuery } from "../App";
import { Settings } from "../core/settings";
import { useHookState } from "../core/store";
import { copyToClipboard, jsonClone, relative_duration, toastError, toastInfo, toastSuccess, useWindowDimensions } from "../utilities/methods";
import { t } from "../utilities/translator";
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { connRef } from "../core/connection";
import { Replication } from "../core/replication";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Plan, Project } from "../core/project";
import { confirmDialog } from "primereact/confirmdialog";
import { Route } from "../core/api/routes";
import { User } from "../core/user";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { RegionMap, WorkerHeartbeat, WorkerStatus, Workr } from "../core/worker";
import { Message, MessageType } from "../core/api/ws";
import { ObjectAny } from "../utilities/interfaces";
import { RegionPanel } from "../components/SetupPanels";
import { rudderstack } from "..";
import { Dialog } from "primereact/dialog";

interface Props {
  loading: State<boolean>;
  settings: State<Settings>
}

export const WorkspaceSettingsView: React.FC<Props> = (props) => {

  const loading = useHookState(false)
  const settings = useHookState(sling.state.settings)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const query = useQuery()

  ///////////////////////////  HOOKS  ///////////////////////////
  React.useEffect(() => { 
    document.title = 'Sling - Settings'
    rudderstack.page(document.title, '', sling.rudderProperties())
    refresh()

    // check deactivate
    let deactivate = query.get('deactivate')
    if(deactivate === 'true') deactivateConfirm(loading)
  }, []); // eslint-disable-line

  ///////////////////////////  EFFECTS  ///////////////////////////
  const refresh = async () => {
    loading.set(true)
    sling.loadSettings('user')
    await sling.loadSettings('project')
    loading.set(false)
  }

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  ///////////////////////////  JSX  ///////////////////////////
  return (
    <div
      id="notifications-panel"
      style={{
        position: 'relative',
        // marginLeft: '100px',
        // marginRight: '100px',
      }}
    >
      <h2 style={{ textAlign: "center" }}> SETTINGS </h2>
      <TabView
        style={{ textAlign: "center" }}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Profile">
            <ProfilePanel />
        </TabPanel>
        <TabPanel header="Workspace" disabled={ !sling.userAtLeastAdmin }>
            <WorkspacePanel />
        </TabPanel>
        <TabPanel header="Workers" disabled={ !sling.userAtLeastAdmin || !sling.state.project.get().is_self_hosted}>
            <WorkersPanel/>
        </TabPanel>
        <TabPanel header="Notifications" disabled={ !sling.userAtLeastAdmin }>
            <NotificationsPanel settings={ settings } loading={ loading }/>
        </TabPanel>
        <TabPanel header="Email">
            <EmailPanel settings={ settings } loading={ loading }/>
        </TabPanel>
        <TabPanel header="Slack" disabled={ !sling.userAtLeastAdmin }>
            <SlackPanel settings={ settings } loading={ loading }/>
        </TabPanel>
        <TabPanel header="Deactivate" disabled={ !sling.userAtLeastAdmin }>
            <DeactivatePanel/>
        </TabPanel>
      </TabView>
    </div>
  )
};

const ProfilePanel: React.FC<{}> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const loading = useHookState(false)
  const apiKey = useHookState('')
  const userName = useHookState(sling.state.user.name)
  
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    rudderstack.page(document.title, 'ProfilePanel', sling.rudderProperties())
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const saveUser = async () => {
    let name = userName.get().trim()
    if(name.length < 4) return toastError('Value is too short')
    let user = new User(jsonClone(sling.state.user.get()))
    user.name = name
    if(await sling.saveUser(user)) {
      toastSuccess('Successfully updated.')
    }
  }
  
  const sendResetPasswordEmail = async () => {
    const response = await sling.api.Post(Route.ResetPassword, {})
    if(response.error) {
      return toastError('Could not send password reset email', 'Are you using a 3rd-Party/Social Login?')
    }
    toastSuccess('Sent!', 'Please check your email. Make sure to log out to change it.')
  }
  
  const getNewApiKey = async () => {
    const response = await sling.api.Get(Route.RouteAPIKey, {})
    if(response.error) {
      return toastError('Could not get new API key', response.error)
    }
    apiKey.set(response.data.api_key)
  }
  ///////////////////////////  JSX  ///////////////////////////
  return (
    <div
      className="grid"
      style={{
        maxHeight: `${window.innerHeight - 270}px`,
        overflowY: 'scroll',
      }}
      >
      <div className="col-3"/>
      <div className="col-6">
        <div className="card">
          <h3>User</h3>
          <h5> Email </h5>


          <Tooltip
            target='#profile-email'
            style={{maxWidth: '200px'}}
            position='left'
          >
            If you would like to change your email, have an Admin add the new email as a user and remove this user.
          </Tooltip>
          <p id="profile-email"> 
            <InputText
              type="text"
              placeholder="email"
              disabled
              value={ sling.state.user.email.get() }
              style={{width: '100%'}}
            />
          </p>
          <p></p>

          <h5> Name </h5>
          <p>
            <InputText
              id="profile-name"
              type="text"
              placeholder="My Name"
              disabled={ sling.isDemoUser }
              value={ userName.get() }
              onChange={(e) => userName.set(`${(e.target as HTMLInputElement).value}`)}
              style={{width: '100%'}}
            />
          </p>

          <p>
            <Button
              disabled={ sling.isDemoUser }
              label={'Save'}
              onClick={async () => {
                loading.set(true)
                await saveUser()
                loading.set(false)
              }}
              icon={loading.get()? 'pi pi-spin pi-spinner':''}
              className="p-button-primary p-mr-2"/>
          </p>
        </div>
      </div>
      <div className="col-3"/>

      <div className="col-3"/>
      <div className="col-6">
        <div className="card">
          <h3>Change Password</h3>
          <p> Click the button below to send an email to change your password.
          </p>
          <p></p>

          <p>
            <Button
              disabled={ sling.isDemoUser }
              label={'Send'}
              onClick={async () => {
                loading.set(true)
                await sendResetPasswordEmail()
                loading.set(false)
              }}
              icon={loading.get()? 'pi pi-spin pi-spinner':''}
              className="p-button-primary p-mr-2"/>
          </p>
        </div>
      </div>
      <div className="col-3"/>

      <div className="col-3"/>
      <div className="col-6">
        <div className="card">
          <h3>API Key</h3>
          <p> Click the button below to regenerate your API key. The new key will be displayed.
          </p>
          <p>Use it to interface with the API directly to deploy replications using the <a href="https://docs.slingdata.io/sling-cli/getting-started">Sling CLI</a></p>

          <p>
            <Button
              disabled={ sling.isDemoUser }
              label={'Regenerate'}
              onClick={async () => {
                loading.set(true)
                await getNewApiKey()
                loading.set(false)
              }}
              icon={loading.get()? 'pi pi-spin pi-spinner':'pi pi-refresh'}
              className="p-button-warning p-mr-2"/>
          </p>
        </div>
      </div>
      <Dialog
        visible={!!apiKey.get()}
        onHide={() => apiKey.set('')}
        header={'New API Key'}
      >
        <p>See <a href="https://docs.slingdata.io/sling-cli/getting-started">here</a> for instructions on how to deploy replications from the command line using YAML files.</p>
        <div className="code-block">
          <code>
            <i><p style={{margin: 0, color: 'grey'}}># On Mac / Linux</p></i>
            <p style={{margin: 0}}>export SLING_PROJECT={sling.state.project.id.get()}</p>
            <p style={{margin: 0}}>export SLING_API_KEY={apiKey.get()}      </p>
            <br/>
            <i><p style={{margin: 0, color: 'grey'}}># On Windows Powershell</p></i>
            <p style={{margin: 0}}>set SLING_PROJECT {sling.state.project.id.get()}</p>
            <p style={{margin: 0}}>set SLING_API_KEY {apiKey.get()}       </p>
          </code>
        </div>
      </Dialog>
      <div className="col-3"/>

    </div>
  )
}

const WorkersPanel: React.FC<{}> = (props) => {
  const workers = useHookState<Workr[]>([])
  const loading = useHookState(false)
  ///////////////////////////  HOOKS  ///////////////////////////
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    refresh()
    rudderstack.page(document.title, 'WorkersPanel', sling.rudderProperties())
  }, []); // eslint-disable-line

  React.useEffect(() => {
    sling.wsCallbacks.worker_view = async (msg: Message) => {
      if(msg.type === MessageType.WorkerHeartbeat) {
        let whb = msg.data as WorkerHeartbeat
        for (let i = 0; i < workers.keys.length; i++) {
          const worker = workers[i]
          if(whb.worker_id === worker.id.get()) {
            worker.set(w => {
              w.last_heartbeat = whb.timestamp
              w.active_executions = whb.active_executions
              w.cpu_pct = whb.cpu_pct
              w.ram_pct = whb.ram_pct
              return w
            })
            if(worker.status.get() !== WorkerStatus.Online) refresh()
          }
        }
      }
    }
    return () => {
      delete sling.wsCallbacks.worker_view
    }
  }, [])  // eslint-disable-line
  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const refresh = async () => {
    loading.set(true)
    await sling.loadWorkers()
    workers.set(jsonClone(Object.values(sling.state.workers.get()))
      .map((v: any) => new Workr(v)))
    loading.set(false)
  }
  ///////////////////////////  JSX  ///////////////////////////

  const statusBody = (worker: Workr, column: ColumnBodyOptions) => {
    return <span className={'status ' + worker.status}> {worker.status} </span>
  }

  const lastHeartbeatBody = (worker: Workr, column: ColumnBodyOptions) => {
    let delta = -1
    let color = 'red'
    let last_heartbeat_string = '~'
    if(worker.last_heartbeat) {
      let last_heartbeat = new Date(worker.last_heartbeat * 1000)
      delta = (new Date().getTime() - last_heartbeat.getTime()) / 1000
      last_heartbeat_string = worker.last_heartbeat_date
      if(delta <= 20 && worker.status === WorkerStatus.Online) color = 'green'
      else if(delta > 60*60) last_heartbeat_string = relative_duration(last_heartbeat)
    }
    return <span> 
      <i
        className="pi pi-circle-fill mr-2"
        style={{'fontSize': '1em', color}}
      />
      { last_heartbeat_string }
    </span>
  }

  const actionBody = (worker: Workr, column: ColumnBodyOptions) => {
    let stale_heartbeat = true
    if(worker.last_heartbeat) {
      let last_heartbeat = new Date(worker.last_heartbeat * 1000)
      let delta = (new Date().getTime() - last_heartbeat.getTime()) / 1000
      stale_heartbeat = delta > 20
    }
    return <div>
            <Button
              type="button"
              disabled={!sling.userAtLeastAdmin}
              icon="pi pi-copy"
              className="p-button-info p-button-sm small-button"
              tooltip="Copy Token"
              tooltipOptions={{position: 'top'}}
              style={{ marginRight: '.5em' }}
              onClick={async () => {
                if(!stale_heartbeat) return toastInfo("Worker is already Online", "Please access worker machine to obtain the token. If you want to add a new worker, click the green button below", 5000)
                let resp = await sling.api.Post(Route.Workers, {"worker_id": worker.id})
                if(resp.error) return toastError('Could not get worker token', resp.error)
                copyToClipboard(resp.data.token as string, "Token copied to clipboard")
              }}
            />

            <Button
              type="button"
              disabled={!sling.userAtLeastAdmin}
              icon="pi pi-times"
              className="p-button-danger p-button-sm small-button"
              tooltip="Remove Worker"
              tooltipOptions={{position: 'top'}}
              style={{ marginRight: '.5em' }}
              onClick={async () => {
                if(!stale_heartbeat) return toastInfo('Cannot Remove Worker', "Please permanently shutdown worker before removing from cloud. If worker is restarted with the token, it will be auto-added again.", 9000)
                let resp = await sling.api.Delete(Route.Workers)
                if(resp.error) toastError('Could not remove worker', resp.error)
              }}
            />
      </div>
  }

  return <div
      className="grid"
      style={{
        paddingTop: '10px',
        maxHeight: `${window.innerHeight - 270}px`,
        width: '100%',
        overflowY: 'scroll',
      }}
      >
        <div className="col-12">
          <div className="card">
            <div>
              <DataTable
                value={workers.get()}
                loading={loading.get()}
                responsiveLayout="scroll"
                scrollable scrollHeight={`${450}px`}
                emptyMessage='No workers found'
                dataKey="id"
              >
                <Column header="ID" field="id" headerStyle={{minWidth: '15em'}} bodyStyle={{minWidth: '15em', textAlign:"center"}} sortable />
                <Column header="Hostname" field="hostname" headerStyle={{minWidth: '15em'}} bodyStyle={{minWidth: '15em', textAlign:"center"}} sortable />
                <Column header="Public IP" field="public_ip" headerStyle={{minWidth: '10em'}} bodyStyle={{minWidth: '10em', textAlign:"center"}} sortable />
                <Column header="Status" body={statusBody} headerStyle={{minWidth: '10em'}} bodyStyle={{minWidth: '10em'}} sortable className="flex justify-content-center" />
                <Column header="Last Heartbeat" body={lastHeartbeatBody} headerStyle={{minWidth: '10em'}} bodyStyle={{minWidth: '10em'}} className="flex justify-content-center" sortable />
                <Column header="Version" field="version" headerStyle={{minWidth: '7em'}} bodyStyle={{minWidth: '7em', textAlign:"center"}} sortable />
                <Column body={actionBody} headerStyle={{maxWidth: '9em'}} bodyStyle={{maxWidth: '9em'}} className="flex justify-content-center" header="Actions" />
              </DataTable>
            </div>
          </div>
        </div>
        <div className="col-5"/>
        <div className="col-2">
          <Button
            label='Add new Worker'
            disabled={!sling.userAtLeastAdmin || sling.isDemoUser}
            onClick={async () => {
              // loading.set(true)
              let resp = await sling.api.Post(Route.Workers)
              if(resp.error) return toastError('Could not get new worker token', resp.error)
              copyToClipboard(resp.data.token as string, "")
              toastInfo('New worker token copied to clipboard', `Use the token when launching the new worker, it will automatically show up here. See docs for more details (link below button)`, 10000)
              // toastInfo('Adding a new Worker', `Please email support@slingdata.io`)
              // loading.set(false)
            }}
            icon={loading.get()? 'pi pi-spin pi-spinner':''}
            className="p-button-success p-mr-2"/>

            <p className="mt-2 ml-3">See <a href="https://docs.slingdata.io/sling-cloud/self-hosted-workers" target="_blank" rel="noopener noreferrer">docs</a> for help.</p>
        </div>
        <div className="col-5"/>
      </div>
}


const WorkspacePanel: React.FC<{}> = (props) => {
  const loading = useHookState(false)

  ///////////////////////////  HOOKS  ///////////////////////////
  const workspace = useHookState<Project>(sling.state.project)
  const users = useHookState<User[]>([])
  const plan = useHookState(sling.state.project.plan)
  const showRegionPanel = useHookState(false)
  
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    getUsers()
    rudderstack.page(document.title, 'WorkspacePanel', sling.rudderProperties())
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const getUsers = async () => {
    loading.set(true)
    let data = await sling.loadUsers()
    users.set(_.orderBy(data, ['name'], ['asc']))
    loading.set(false)
  }
  
  const saveWorkspace = async () => {
    let name = workspace.name.get().trim()
    let organization = workspace.organization.get().trim()
    if(name.length < 4) return toastError('Name is too short')
    if(organization.length < 4) return toastError('Organization is too short')
    
    sling.state.project.set(
      proj => {
        proj.name = name
        proj.organization = organization
        return proj
      }
    )

    if(await sling.saveProject(sling.state.project.get())) {
      toastSuccess('Successfully updated.')
    }
  }

  ///////////////////////////  JSX  ///////////////////////////
  return (
    <div
      className="grid"
      style={{
        maxHeight: `${window.innerHeight - 270}px`,
        overflowY: 'scroll',
      }}
      >

      <div className="col-3"/>
      <div className="col-6">
        <div className="card">
          <h5> Name </h5>
          <p>
            <InputText
              id="workspace-name"
              disabled={ sling.isDemoUser }
              type="text"
              placeholder="My Project Name"
              value={ workspace.name.get() }
              onChange={(e) => workspace.name.set(`${(e.target as HTMLInputElement).value}`)}
              style={{width: '100%'}}
            />
          </p>

          <h5> Organization </h5>
          <p>
            <InputText
              id="organization-name"
              disabled={ sling.isDemoUser }
              type="text"
              placeholder="Company Name"
              value={ workspace.organization.get() }
              onChange={(e) => workspace.organization.set(`${(e.target as HTMLInputElement).value}`)}
              style={{width: '100%'}}
            />
          </p>

          <h5> Workspace Owner </h5>
          <p>
            <Dropdown
              id='organization-owner'
              disabled={ sling.isDemoUser }
              placeholder="select Role"
              value={ workspace.owner_email.get()}
              options={ users.get().filter(u => u.is_admin).map(u => u.email) }
              onChange={(e) => { workspace.owner_email.set(e.target.value) }}
              style={{width: '200px'}}
            />
          </p>

          <p>
            <Button
              label={'Save'}
              disabled={ sling.isDemoUser }
              onClick={async () => {
                loading.set(true)
                await saveWorkspace()
                loading.set(false)
              }}
              icon={loading.get()? 'pi pi-spin pi-spinner':''}
              className="p-button-primary p-mr-2"/>
          </p>
        </div>
      </div>
      <div className="col-3"/>

      {
        plan.get() === Plan.SelfHosted || plan.get() === Plan.SelfHostedFree?
        null
        :
        <>
          <RegionPanel show={showRegionPanel}/>
          <div className="col-3"/>
          <div className="col-6">
            <div className="card">
              <h3>Change Workspace Region</h3>
              <p> Click the button below to select a new region.
              </p>
              <p>
                <strong>Current Region: </strong>
                {RegionMap[sling.state.project.region.get()]}
              </p>

              <p>
                <Button
                  disabled={ sling.isDemoUser }
                  label={'Change Region'}
                  onClick={async () => {
                    showRegionPanel.set(true)
                  }}
                  icon={loading.get()? 'pi pi-spin pi-spinner':''}
                  className="p-button-primary p-mr-2"/>
              </p>
            </div>
          </div>
          <div className="col-3"/>
        </>
      }

    </div>
  )
}

const DeactivatePanel: React.FC<{}> = (props) => {
  const loading = useHookState(false)

  ///////////////////////////  HOOKS  ///////////////////////////
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    rudderstack.page(document.title, 'DeactivatePanel', sling.rudderProperties())
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  
  ///////////////////////////  JSX  ///////////////////////////
  return (
    <div
      className="grid"
      style={{
        maxHeight: `${window.innerHeight - 270}px`,
        overflowY: 'scroll',
      }}
      >
      
      <div className="col-3"/>
      <div className="col-6">
        <div className="card">
          <h3>Delete Workspace</h3>
          <p> Click the button below to permanently delete the workspace.
          </p>
          <p></p>

          <p>
            <Button
              label={'Delete'}
              disabled={!sling.userAtLeastAdmin || sling.isDemoUser}
              onClick={async () => {
                // check if sub is cancelled
                let resp = await sling.api.Get(Route.Subscription, {})
                if (resp.error) return toastError("Error encountered", resp.error)

                if(!`${resp.data.plan}`.includes('free') && !resp.data.cancel_at_period_end) {
                  // paid plan, go to portal to cancel
                  let data : ObjectAny = {
                    return_url: window.location.href+'?deactivate=true',
                  }
                  let resp = await sling.api.Post(Route.StripeCustomerPortal, data)
                  if(resp.error) return toastError("Error encountered", resp.error)
                  if(resp.data?.url) return window.location.assign(resp.data.url)
                  return
                }

                deactivateConfirm(loading)
              }}
              icon={loading.get()? 'pi pi-spin pi-spinner':''}
              className="p-button-danger p-mr-2"/>
          </p>
        </div>
      </div>
      <div className="col-3"/>
    </div>
  )
}

const deactivateConfirm = (loading: State<boolean>) => {
  confirmDialog({
    message: `Are you sure you want to permanently delete this workspace? You'll be logged out. Please login again to access other workspaces (if any)`,
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    style: {maxWidth: "400px"},
    accept: async () => {
      loading.set(true)
      await sling.api.Delete(Route.Projects, {id: sling.state.project.id.get()})
      sling.logout()
    },
  })
}

const EmailPanel: React.FC<Props> = (props) => {
  const loading = props.loading

  ///////////////////////////  HOOKS  ///////////////////////////
  const emailOnFailure = useHookState(props.settings?.user?.notify?.get() || false)
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    rudderstack.page(document.title, 'EmailPanel', sling.rudderProperties())
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const testEmail = async () => {
    // test
    if(await sling.testSettings('email', {})) {
      toastSuccess(`Message sent to Email`, 'Check SPAM folder if nothing is received.')
    }
  }
  ///////////////////////////  JSX  ///////////////////////////
    return (
      <div
        className="grid"
        style={{
          maxHeight: `${window.innerHeight - 270}px`,
          overflowY: 'scroll',
        }}
        >
        <div className="col-3"/>
        <div className="col-6">
          <div className="card">
            <h4>Email Me on Failures</h4>
            <p> { sling.state.user.email.get() } </p>
            <InputSwitch
              checked={ emailOnFailure.get() }
              disabled={ sling.isDemoUser }
              tooltip={ 'Notify by Email according to Notifications set by an Admin user' }
              tooltipOptions={{position: 'right'}}
              className='mb-3'
              onChange={async (e) => { 
                emailOnFailure.set(e.target.value)
                if(await sling.saveSettings('user', { notify: emailOnFailure.get() })) {
                  sling.state.settings.user.notify.set(emailOnFailure.get())
                  toastSuccess(`Saved`)
                }
              }}
            />
            {
              emailOnFailure.get() ?
              <p>
                <Button
                  disabled={ sling.isDemoUser }
                  label={t('Test')}
                  onClick={async () => {
                    loading.set(true)
                    await testEmail()
                    loading.set(false)
                  }}
                  icon={loading.get()? 'pi pi-spin pi-spinner':''}
                  className="p-button-primary p-mr-2"/>
              </p>
              :
              null
            }
          </div>
        </div>
        <div className="col-3"/>
      </div>
    )
}


const SlackPanel: React.FC<Props> = (props) => {
  const loading = props.loading
  const projectSettings = useHookState(sling.state.settings.project)

  ///////////////////////////  HOOKS  ///////////////////////////


  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    rudderstack.page(document.title, 'SlackPanel', sling.rudderProperties())
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const validateSlackSettings = () => {
    const slack = projectSettings.slack
    try {
      if(!slack.webhook_url.get()) throw new Error(`Invalid Slack Webhook URL`)
      if(!slack.webhook_url.get().startsWith('https://hooks.slack.com/services')) throw new Error(`Invalid Slack Webhook URL`)
    } catch(error) {
      toastError('Validation Failure', error)
      return false
    }
    return true
  }

  const saveTestSlack = async () => {
    if(!validateSlackSettings()) { return }
    
    // save
    if(await sling.saveSettings('project', projectSettings.get() )) {
      sling.state.settings.project.set(jsonClone(projectSettings.get()))
    } else {
      return
    }

    // test
    let testData = {
      settings: projectSettings.get(),
    }
    if(await sling.testSettings('slack-webhook', testData)) {
      toastSuccess(`Message sent to Slack`)
    }
  }
  ///////////////////////////  JSX  ///////////////////////////

    return (
      <div
        className="grid"
        style={{
          maxHeight: `${window.innerHeight - 270}px`,
          overflowY: 'scroll',
        }}
        >
        <div className="col-3"/>
        <div className="col-6">
          <div className="card">
            <h4>Slack Webhook Configuration</h4>

            <p>Click the button below to create a new Slack App. Once the App is created, create a new "Incoming Webhook". Once that is created, copy/paste the URL below.
            </p>
            
            <p>
              <Button
                disabled={ sling.isDemoUser }
                label={t('Create Slack App')}
                onClick={() =>  window.open('https://api.slack.com/apps','_blank') }
                className="p-button-info"/>
            </p>

            <h5>Slack Incoming Webhook</h5>
            <p>
              <InputText
                id="webhook_url"
                disabled={ sling.isDemoUser }
                type="text"
                placeholder="https://hooks.slack.com/services/XXXXXXXXX/XXXXX/XxxxxXXXXXXXx"
                value={ projectSettings.slack.webhook_url.get() }
                onChange={(e) => projectSettings.slack.webhook_url.set(`${(e.target as HTMLInputElement).value}`)}
                style={{width: '100%'}}
              />
            </p>

            <p>
              <Button
                label={t('Save & Test')}
                disabled={ sling.isDemoUser }
                onClick={async () => {
                  loading.set(true)
                  await saveTestSlack()
                  loading.set(false)
                }}
                icon={loading.get()? 'pi pi-spin pi-spinner':''}
                className="p-button-primary p-mr-2"/>
            </p>
          </div>
        </div>
        <div className="col-3"/>
      </div>

    )
}

const NotificationsPanel: React.FC<Props> = (props) => {
  const projectSettings = useHookState(sling.state.settings.project)
  const loading = useHookState(props.loading)

  ///////////////////////////  HOOKS  ///////////////////////////
  const { height } = useWindowDimensions();
  const replications = useHookState(sling.state.replications)
  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => { 
    rudderstack.page(document.title, 'NotificationsPanel', sling.rudderProperties())
  }, []); // eslint-disable-line

  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const replicationsSorted = () => {
    let replics = Object.values(replications.get())
    replics = _.orderBy(replics, ['config.source', 'config.target'], ['asc', 'asc'])
    return replics
  }

  ///////////////////////////  JSX  ///////////////////////////

  const nameBody = (rec: Replication) => {
    const source = connRef(rec.source_id)
    const target = connRef(rec.target_id)
    return (
      <span>
        <Link to={`/replication?rid=${rec.id}`}>
          {`${source.name} -> ${target.name}`}
        </Link>
      </span>
    );
  }

  const subscriptionBody = (replication: Replication, column: ColumnBodyOptions) => {
    const is_email = column.field.startsWith('email_')
    const is_slack = column.field.startsWith('slack')
    const on_failure = column.field.endsWith('on_failure')

    const notifSettings = projectSettings.replication_notifications[replication.id as number]
    return (
      <span>
        <InputSwitch
          disabled={ sling.isDemoUser }
          checked={ (is_email ? notifSettings?.email?.get() : is_slack ? notifSettings?.slack?.get() : false)  || false }
          tooltip={ is_email ? 'Notify subscribed users by Email' : is_slack ? 'Notify on Slack' : '' }
          tooltipOptions={{position: 'top'}}
          onChange={async (e) => { 
            projectSettings.replication_notifications.set(
              rn => {
                if(!rn) rn = {}
                let item = rn[replication.id as number] || {}
                if(is_email) item.email = e.target.value
                if(is_slack) item.slack = e.target.value
                if(on_failure) item.on_failure = e.target.value
                rn[replication.id as number] = item
                return rn
              }
            )
            // save
            if(await sling.saveSettings('project', projectSettings.get())) {
              sling.state.settings.project.set(jsonClone(projectSettings.get()))
            }
          }}
        />
      </span>
    );
  }

  return (
    <div
      className="grid"
      style={{
        maxHeight: `${window.innerHeight - 270}px`,
        overflowY: 'scroll',
      }}
      >
      <div className="col-12">
        <div className="card">
          <div>
            <DataTable
              value={replicationsSorted()}
              loading={loading.get()}
              responsiveLayout="scroll"
              scrollable scrollHeight={`${height - 150}px`}
              dataKey="id"
            >
              {/* <Column expander style={{ width: '3em' }} /> */}
              <Column header="Name" body={nameBody} headerStyle={{minWidth: '30em', textAlign: 'center'}} bodyStyle={{minWidth: '30em', textAlign:"center"}} sortable />
              <Column body={subscriptionBody} field='email_on_failure' className="flex justify-content-center" header="Email On Failure" style={{ textAlign: 'center'}} sortable />
              <Column body={subscriptionBody} field='slack_on_failure' className="flex justify-content-center" header="Slack On Failure" style={{ textAlign: 'center'}} sortable />
              {/* <Column body={subscriptionBody} field='on_linger' className="flex justify-content-center" header="On Linger" style={{ textAlign: 'center'}} sortable /> */}
              {/* <Column body={subscriptionBody} field='on_empty' className="flex justify-content-center" header="On Empty" style={{ textAlign: 'center'}} sortable /> */}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}