import { ObjectAny } from "../utilities/interfaces"

export enum Role {
	Master = "master",
	Admin = "admin",
	Power = "power",
	Spectator = "spectator",
}

export class User {
  id: string
  name: string
  email: string
  role: Role
  provider: string
  blocked?: boolean
  last_login?: number
  def_project_id: string
  created_dt?: string

  constructor(data: ObjectAny = {}) {
    this.id = data.id
    this.name = data.name
    this.email = data.email
    this.blocked = data.blocked || false
    this.role = data.role
    this.provider = data.provider
    this.last_login = data.last_login || 0
    this.def_project_id = data.def_project_id
    this.created_dt = data.created_dt
  }
  get is_spectator() { return this.role === Role.Spectator }
  get is_power() { return this.role === Role.Power }
  get is_admin() { return this.role === Role.Admin }
  get is_master() { return this.role === Role.Master }

  get last_login_date() {
    if(this.last_login) return new Date(this.last_login * 1000)
    return undefined
  }
  
  get last_login_date_str() { return this.last_login_date?.toLocaleString() }

  payload() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      role: this.role,
      blocked: this.blocked,
      def_project_id: this.def_project_id,
    }
  }
}