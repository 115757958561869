import { none, State } from "@hookstate/core";
import { Mutex } from "async-mutex";
import { Chart as ChartJs } from 'primereact/chart';
import _ from "lodash";
import { Button } from "primereact/button";
import { ColumnBodyOptions, Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { sling, useQuery } from "../App";
import { Message, MessageBatcher, MessageType } from "../core/api/ws";
import { Execution, ExecutionHeartbeat, toGB, getStreamId } from "../core/execution";
import { Replication } from "../core/replication";
import { getDurationChartOptions, VolumeRecord, DurationRecord } from "../core/stats";
import { useHookState, useVariable } from "../core/store";
import { ObjectAny } from "../utilities/interfaces";
import { useWindowFocus, jsonClone, itemsFiltered, toastInfo, useIsMounted } from "../utilities/methods";
import { processWsMessageHistory, nameBodyTooltip, dateBody, durationBody, rowsBody } from "../views/HistoryView";
import { ExecutionDialog } from "./ExecutionDialog";
import { ReplicationJobDialog } from "./ReplicationJobDialog";
import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import { isDemo, isProd } from "../core/api/http";
import { rudderstack, rudderTrack } from "..";

interface Props {
  loading: State<boolean>;
  refresh: State<number>;
  replication: State<Replication>
}

export const ReplicationPanelStatus: React.FC<Props> = (props) => {
  const query = useQuery()
  const history = useHistory()
  const replication = props.replication
  const loading = useHookState(props.loading)
  const live = useHookState(true)
  const filter = useHookState('');
  const executions = useHookState<Execution[]>([])
  const currExecution = useHookState(new Execution())
  const showExecution = useHookState(false)
  const volumeChartRef = React.useRef(null)
  const durationChartRef = React.useRef(null)
  const jobDialog = useHookState({show: false, name: ''})
  const batcher = React.useRef(new MessageBatcher())
  const mutex = React.useRef(new Mutex())
  const windowFocus = useWindowFocus(60*10)
  const isMounted = useIsMounted()
  const refreshDebounce = React.useRef<any>(null)

  const volumeChartOptions = useVariable<ObjectAny>({
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
          legend: {
              labels: {
                  color: '#495057'
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          },
          y: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          }
      }
  }) 
  const volumeChartData = useVariable<ObjectAny>({
        labels: [],
        datasets: [
            {
                label: 'Total bytes (GB)',
                backgroundColor: '#42A5F5',
                data: []
            },
            // {
            //     label: 'My Second dataset',
            //     backgroundColor: '#FFA726',
            //     data: [28, 48, 40, 19, 86, 27, 90]
            // }
        ]
    })
  const durationChartOptions = useVariable<ObjectAny>(getDurationChartOptions(false)) 
  const durationChartSeries = useVariable<any[]>([])

  React.useEffect(() => {
    let data = { replication_id: replication.id.get() }
    rudderstack.page(document.title, 'ReplicationPanelStatus', sling.rudderProperties(data))
    // set debounce
    refreshDebounce.current = _.debounce(() => refreshExecutions(), 400)
  }, []); // eslint-disable-line

  React.useEffect(() => { 
    // check query params 
    let exec_id = query.get('eid')
    if(exec_id) {
      showExecutionDialog(parseInt(exec_id))
      sling.state.temp.exec_id.set(none)
      history.push(`replication?rid=${replication.id.get()}`) // clear query params
      return
    }

    refreshDebounce.current()
    
    if(live.get()) {
      sling.wsCallbacks.history = processWsMessage
      return () => {
        delete sling.wsCallbacks.history
      }
    }
  }, [replication.get(), live.get()]); // eslint-disable-line

  React.useEffect(() => { 
    if(windowFocus && live.get()) {
      // disabled for now, excessive CPU
      // reconnect if window is refocused
      // sling.connectWebsocket().then(
      //   reconnected => {
      //     if(reconnected) refreshDebounce.current()
      //   }
      // )
      refreshDebounce.current()
    } 
  }, [windowFocus]); // eslint-disable-line
  
  const processWsMessage = async (msg: Message) => {
    const process = async () => {
      if(!isMounted.current) return
      await processWsMessageHistory(executions, batcher.current.drain(500), replication.id.get())
    }

    batcher.current.add(msg)
    mutex.current.runExclusive(process)

    // takes care of remnant messages
    setTimeout(() => { mutex.current.runExclusive(process) }, 500);

    if(msg.type === MessageType.ExecutionHeartbeat) {
      let ehb = msg.data as ExecutionHeartbeat
      if (ehb.end_time) refreshCharts()
    }
  }

  const refreshExecutions = async () => {
    loading.set(true)
    refreshCharts()
    await mutex.current.runExclusive(async () => {
      let execs = await sling.loadExecutions({
        replication_id: replication.id.get(),
        where: ['is_latest', 'true'],
        last: 200,
      })
      execs = _.orderBy(execs, ['id'], ['desc'])
      if(isMounted.current) executions.set(execs)
    })
    if(!isMounted.current) return
    loading.set(false)
    setTimeout(() => {
      sling.connectWebsocket() // reconnect in case of disconnect
    }, 200);
  }

  const showExecutionDialog = async (id: number) => {
    let execs = await sling.loadExecutions({ids: [id]})
    if(execs.length === 0) return
    currExecution.set(new Execution(jsonClone(execs[0])))
    showExecution.set(true)
  }

  const refreshCharts = async () => {
    let promiseVolume = sling.getStats('volume', 'day', 7, replication.id.get()) as Promise<VolumeRecord[]>
    promiseVolume.then(volRecords => {
      if(!isMounted.current) return
      volumeChartData.set({
          labels: volRecords.map(r => new Date(r.date*1000).toISOString().split('T')[0]),
          datasets: [
              {
                  label: 'Total bytes (GB)',
                  backgroundColor: '#42A5F5',
                  data: volRecords.map(r => toGB(r.total_bytes))
              },
          ]
      })

      const volumeChart : any = volumeChartRef.current
      volumeChart?.refresh()
    })

    //////////////////////////////////////////////////////

    let promiseDuration = sling.getStats('duration', 'minute', 60, replication.id.get()) as Promise<DurationRecord[]>
    promiseDuration.then(durationRecords => {
      let task_duration_map : ObjectAny = {}
      for(let record of durationRecords) {
        if(!(record.task_name in task_duration_map)) {
          task_duration_map[record.task_name] = []
        }
        task_duration_map[record.task_name].push({
          id: record.id,
          runs: [
            record.start_time * 1000,
            record.end_time * 1000,
          ],
        })
      }
      
      durationChartSeries.set(Object.keys(task_duration_map)
      .map(task_name => {
        return {
          name: task_name,
          data: task_duration_map[task_name].map((data: any) => {
            return {
              x: 'Run',
              y: data.runs,
              onClick: () => showExecutionDialog(data.id),
            }
          })
        }
      }))
      const durationChart : any = durationChartRef.current

      let hasData = Object.keys(task_duration_map).length > 0
      durationChartOptions.set(getDurationChartOptions(hasData))
      durationChart?.render()
    })
  }


  const executionsFiltered = () => {
    return itemsFiltered<Execution>(
      Object.values(executions.get()),
      filter.get(),
      (v) => new Execution(v)
    )
  }

  const header = () => {
    return (
      <div className="grid">
        <div className="col-6">
          <p style={{fontSize: '20px'}}>Replication Streams List</p>
        </div>
        <div className="col-6 flex justify-content-end">
          <div style={{
            paddingTop: '5px',
            paddingLeft: '7px',
            paddingRight: '7px',
          }}>
            <InputSwitch
              checked={ live.get() }
              tooltip="Live Mode (Auto-Refresh)"
              tooltipOptions={{position: 'left'}}
              onChange={async (e) => { live.set(e.value) }}
            />
          </div>
          <div className="p-input-icon-left">
            <i className="pi pi-search"></i>
            <InputText
              type="search"
              className="p-inputtext-sm"
              value={filter.get()}
              onChange={(e) => filter.set(e.target.value)}
              onKeyDown={(e) => {
                if(e.key === 'Escape') { filter.set('') } 
              }}
              placeholder="Search"
              size={15}
            />
            <Button
              icon="pi pi-refresh"
              className="p-button-info p-button-sm"
              style={{marginLeft: '-40px'}}
              onClick={(e) => {
                refreshExecutions()
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  const statusBody = (execution: Execution, column: ColumnBodyOptions) => {
    return <span className={'status ' + execution.status}> {execution.status} </span>
  }

  const nameBody = (execution: Execution, column: ColumnBodyOptions) => {
    const cell_id = `exec-name-${execution.id}`

    return <>
      {nameBodyTooltip(execution, cell_id)}
      <a
        href={window.location.href}
        onClick={() => {
          jobDialog.name.set(execution.job_name)
          jobDialog.show.set(true)
        }}
      >
        <span id={cell_id} style={{overflowX: "scroll"}}> {execution.job_name} </span>
      </a>
    </>
  }

  const actionTemplate = (execution: Execution, column: any) => {
    return <div>
          {
            execution.is_running() ?
            <Button
              type="button"
              disabled={!sling.userAtLeastPower}
              icon="pi pi-times"
              className="p-button-danger p-button-sm small-button"
              tooltip="Terminate Execution"
              tooltipOptions={{position: 'top'}}
              style={{ marginRight: '.5em' }}
              onClick={async () => {
                await sling.terminateExecution(execution.id)
                refreshExecutions()
                rudderTrack('replication', 'task-terminate', sling.rudderProperties({execution_id: execution, stream_id: execution.stream_id}))
              }}
            />
            :
            <Button
              type="button"
              disabled={!sling.userAtLeastPower}
              icon="pi pi-play"
              className="p-button-success p-button-sm small-button"
              tooltip="Execute"
              tooltipOptions={{position: 'top'}}
              style={{ marginRight: '.9em' }}
              onClick={async (e) => {
                let stream_id = getStreamId(
                  replication.source_id.get(), 
                  replication.target_id.get(),
                  execution.job_name,
                )
                let debug = e.shiftKey ? ((isProd || isDemo) ? 'LOW': 'TRACE') : ''
                if(await sling.executeJob(stream_id, debug)) {
                  toastInfo(`Triggered "${execution.job_name}"`)
                }
                rudderTrack('replication', 'task-execute', sling.rudderProperties({stream_id,job_name: execution.job_name}))
              }}
            />
          }
          <Button
            type="button"
            icon="pi pi-eye"
            className="p-button-info p-button-sm small-button"
            tooltip="View Log"
            tooltipOptions={{position: 'top'}}
            style={{ marginRight: '.5em' }}
            onClick={async () => { showExecutionDialog(execution.id) }}
          />
      </div>;
  }

  return (
    <>
      {
        showExecution.get() ?
        <ExecutionDialog
          show={showExecution}
          execution={currExecution}
        />
        :
        null
      }

      {
        jobDialog.show.get() && replication.id.get() !== undefined ?
        <ReplicationJobDialog
          show={jobDialog.show}
          name={jobDialog.name}
          detached={true}
          replication={sling.state.replications[replication.id.get() as number]}
        />
        : null
      }
      
      <div
        className="grid"
        style={{
          maxHeight: `${window.innerHeight - 270}px`,
          overflowY: 'scroll',
        }}
      >
        <div className="col-12 md:col-12 lg:col-6 xl:col-6" style={{marginBottom: '-15px', paddingTop: 0}}>
          <div className="card" style={{
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 15,
          }}>
            <Chart
              ref={durationChartRef}
              type="rangeBar"
              height='175px'
              series={durationChartSeries.get()}
              options={durationChartOptions.get()}
            />
          </div>
        </div>

        <div className="col-12 md:col-12 lg:col-6 xl:col-6" style={{marginBottom: '-15px', paddingTop: 0}}>
          <div className="card" style={{
            paddingLeft: 15,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 15,
          }}>
            <ChartJs
              ref={volumeChartRef}
              type="bar"
              height='170px'
              data={volumeChartData.get()}
              options={volumeChartOptions.get()}
            />
          </div>
        </div>
      
      {/* TODO: HISTORY of EXECUTIONS, REUSE */}
      <div className="col-12" style={{paddingBottom: 0, paddingTop: 0}}>
        <div className="card">
          <DataTable
            className="history-table"
            value={executionsFiltered()} 
            header={ header()}
            loading={loading.get()}
            responsiveLayout="scroll"
            scrollable scrollHeight={`${window.innerHeight - 585}px`}
            dataKey="id"
            emptyMessage="No records found."
            style={{fontSize: '12px'}}
            lazy
          >
              <Column body={nameBody} className="flex justify-content-center column-wrap" field="job_name" header="Name" sortable filter />
              <Column body={dateBody} className="flex justify-content-center" field="start_date" header="Start Time" sortable/>
              <Column body={dateBody} className="flex justify-content-center" field="end_date" header="End Time" sortable />
              <Column body={durationBody} headerStyle={{maxWidth: '8em'}} bodyStyle={{maxWidth: '8em'}} className="flex justify-content-center" field="duration-simple" header="Duration" />
              <Column body={rowsBody} headerStyle={{maxWidth: '7em'}} bodyStyle={{maxWidth: '7em'}} className="flex justify-content-center" field="rows" header="Rows" />
              <Column body={statusBody} field="status" className="flex justify-content-center" header="Status" headerStyle={{maxWidth: '8em'}} bodyStyle={{maxWidth: '8em'}} sortable filter/>
              <Column body={actionTemplate} headerStyle={{maxWidth: '9em'}} bodyStyle={{maxWidth: '9em'}} className="flex justify-content-center" header="Actions" />
          </DataTable>
        </div>
      </div>
      </div>
    </>
  )
}