import { State } from "@hookstate/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";
import { OverlayPanel } from "primereact/overlaypanel";
import * as React from "react";
import { sling } from "../App";
import { Project } from "../core/project";
import { useHookState } from "../core/store";
import { jsonClone, toastError } from "../utilities/methods";

interface Props {
  show: State<boolean>
}

export const WorkspaceChooser: React.FC<Props> = (props) => {
  ///////////////////////////  HOOKS  ///////////////////////////
  const show = useHookState(props.show)
  const loading = useHookState(false)
  const projectSelected = useHookState(new Project(jsonClone(sling.state.project.get())))
  const projects = useHookState<Project[]>([])
  const newProject = {
    ref: React.useRef<OverlayPanel>(null),
    name: useHookState(''),
  }

  ///////////////////////////  EFFECTS  ///////////////////////////
  React.useEffect(() => {
    if(show.get()) loadProjects()
  }, [show.get()])  // eslint-disable-line
  
  ///////////////////////////  FUNCTIONS  ///////////////////////////

  const loadProjects = async () => {
    loading.set(true)
    let data = await sling.loadProjects()
    projects.set(data.sort((a, b) => { return a.name < b.name ? -1 : 1}))
    loading.set(false)
  }

  ///////////////////////////  JSX  ///////////////////////////


  const ProjectDialog = <OverlayPanel
    ref={newProject.ref}
    showCloseIcon
    dismissable>

    <p className="mb-1"><strong>Workspace Name</strong></p>
    <p>
    <InputText
      id='new-project-name'
      type="text"
      placeholder="New Project"
      value={newProject.name.get()}
      onInput={(e:any) => { newProject.name.set(e.target.value as string) }}
      style={{width: '200px'}}
    /></p>

    <p className="mb-1"><strong>Workspace Owner</strong></p>
    <p>
    <InputText
      id='new-project-owner_email'
      type="text"
      value={sling.state.user.email.get()}
      disabled={true}
      style={{width: '200px'}}
    /></p>

    <Button
      label="Submit"
      style={{ width: '100%' }}
      onClick={async (e) => {
        if(newProject.name.get().length < 4) return toastError(`Invalid Name, empty or too short`)
        let project_id = await sling.createProject(newProject.name.get())
        if(!project_id) return
        sling.setProject(project_id)
      }}
    />
  </OverlayPanel>
  
  const footer = () => {
    return <div style={{textAlign: 'center'}}>
        <Button
          label="OK"
          icon={loading.get()? 'pi pi-spin pi-spinner': "pi pi-check"}
          className=""
          onClick={async () => { sling.setProject(projectSelected.id.get()) }} 
        />

        <Button
          label='New'
          disabled={ sling.isDemoUser }
          icon={loading.get()? 'pi pi-spin pi-spinner': "pi pi-plus"}
          tooltip="Create new Sling Workspace. You will be the owner of that workspace."
          tooltipOptions={{style: {maxWidth: '250px'}}}
          className=' p-button-success mt-2'
          onClick={async (e) => { newProject.ref.current?.toggle(e) }} 
        />
    </div>
  }

  return (
    <Dialog
      id='workspace-chooser'
      header="Select Workspace"
      visible={show.get()}
      footer={footer()} 
      onHide={() => show.set(false)}
    >
      {ProjectDialog}

      <ListBox 
        value={projectSelected.get()}
        options={projects.get()} 
        optionLabel='name'
        onChange={(e) => { projectSelected.set(new Project(jsonClone(e.value)))} }
        listStyle={{fontFamily:'monospace'}}
        style={{width: '20rem', minHeight: '150px'}}
      />
    </Dialog>
  )
};