import React from "react";
import { useHookState } from "../core/store";
import { Button } from "primereact/button";
import { State } from "@hookstate/core";
import { ConnDialog } from "./ConnectionDialog";
import { blankConnSpec, Connection, ConnKind, orderConns } from "../core/connection";
import { sling, useQuery } from "../App";
import { itemsFiltered, jsonClone, toastError, toastSuccess, useWindowDimensions } from "../utilities/methods";
import { InputText } from "primereact/inputtext";
import { rudderstack, rudderTrack } from "..";

interface Props {}

export const ConnectionsView: React.FC<Props> = (props) => {

  const showDialog = useHookState(false);
  const currConn = useHookState<Connection>(new Connection());
  const doRefresh = useHookState(0); // a way to trigger refresh in child
  const connections = useHookState(sling.state.connections)
  const query = useQuery()
  const connSpecs = useHookState(sling.state.settings.app.connection_specs)
  const loading = useHookState(false);
  const filter = useHookState('');
  const { height } = useWindowDimensions();

  // Load initial data
  React.useEffect(() => { 
    document.title = 'Sling - Connections'
    rudderstack.page(document.title, '', sling.rudderProperties())
  }, []); // eslint-disable-line

  React.useEffect(() => { 
    if(!showDialog.get()) {
      doRefresh.set(v => v+1)
    }
  }, [showDialog.get()]); // eslint-disable-line

  React.useEffect(() => { 
    let newConn = query.get('newConn')
    if (newConn && connSpecs.get() && connSpecs.keys.length && sling.userAtLeastAdmin) {
      showDialog.set(true)
    }
  }, [connSpecs.get()]); // eslint-disable-line

  const connectionsFiltered = () => {
    return itemsFiltered<Connection>(
      Object.values(connections.get()),
      filter.get(),
      (v) => new Connection(v)
    )
  }

  return (
    <>
      { showDialog.get() ? <ConnDialog show={showDialog} conn={currConn}/>  : null }
      <div className="grid">
        {/* HEADER */}
        <div className="col-4 justify-content-start">
          <Button
            id='add-new-connection'
            icon='pi pi-plus'
            label='New Connection'
            className="p-button-success p-button-sm"
            disabled={ !sling.userAtLeastAdmin }
            onClick={async (e) => { 
              showDialog.set(true)
            }}
          />
        </div>
        <div className="col-4">
          <h2 style={{ textAlign: "center", marginBottom: 0 }}> {'CONNECTIONS'}</h2>
        </div>
        <div className="col-4 flex justify-content-end">
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                className="p-inputtext-sm"
                value={filter.get()}
                onChange={(e) => filter.set(e.target.value)}
                onKeyDown={(e) => {
                  if(e.key === 'Escape') { filter.set('') } 
                }}
                placeholder="Search"
                size={15}
              />
              <Button
                icon={loading.get() ? 'pi pi-spin pi-spinner' :"pi pi-refresh"}
                className="p-button-info p-button-sm"
                style={{marginLeft: '-40px'}}
                onClick={async (e) => { 
                  loading.set(true)
                  await sling.loadConnections() 
                  loading.set(false)
                }}
              />
          </span>
        </div>
        
        {/* ITEMS */}
        <div className="grid" style={{
          paddingTop: '10px',
          maxHeight: `${height - 260}px`,
          overflowY: 'scroll',
          width: "100%"
        }}>
          {
            orderConns(connectionsFiltered()).map(conn => {
              return <div key={conn.id} className="col-12 md:col-6 lg:col-4 xl:col-4">
                <CardConnection currConn={currConn} show={showDialog} conn={conn}/>
              </div>
            })
          }
          {
            connections.keys.length === 0 ?
            <div className="col-12 md:col-6 lg:col-4 xl:col-4">
              <CardConnectionNew show={showDialog}/>
            </div>
            :
            null
          }
          <div className="col-12 md:col-6 lg:col-4 xl:col-4"><br/></div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-4"><br/></div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-4"><br/></div>
          <div className="col-12 md:col-6 lg:col-4 xl:col-4"><br/></div>
        </div>
      </div>
    </>
  );
};


const CardConnection: React.FC<{ currConn: State<Connection>, conn: Connection, show: State<boolean> }> = (props) => {
  const loadingTest = useHookState(false)
  let connSpec = sling.state.settings.app.connection_specs.get()[props.conn.type]
  if (!connSpec) connSpec = blankConnSpec(props.conn.type)
  
  return (
    <>
      <div className="card mb-0" style={{width: '100%'}}>
        <div className="flex justify-content-between mb-3">
          <div className="flex align-items-center" style={{overflowX: 'hidden'}}>
            <img
              src={'assets/connections/' + connSpec.icon}
              alt={connSpec.title}
              height="40px"
              width="40px"
              style={{ display: 'inline-block', margin: '2px 0 2px 2px' }}
            />
            <div style={{margin: '0 0 0 15px'}}>
              <span className="block font-medium text-xl mb-3"> {props.conn.name} </span>
              <div className="text-600 font-medium text-l">{ connSpec?.title}</div>
            </div>
          </div>
          <div
            className="flex align-items-center justify-content-center border-round"
          >

            {
              sling.state.project.get().is_self_hosted || !sling.userAtLeastAdmin ?
              <Button
                id={`test-connection-${props.conn.name.toLowerCase()}`}
                disabled={ !sling.userAtLeastPower }
                tooltip='Test Connectivity'
                icon={loadingTest.get() ? 'pi pi-spin pi-spinner' : "pi pi-arrow-circle-right"}
                onClick={async (e) => {
                  if(connSpec.kind === ConnKind.Unknown) return toastError('This connection is not currently supported')
                  loadingTest.set(true)
                  let result = await sling.testConnection(props.conn)
                  if (result.success) {
                    toastSuccess('Connection test successful!')
                  } else {
                    toastError(`Error testing connection ${props.conn.name}`, result.error)
                  }
                  rudderTrack('connection', 'connection-test', sling.rudderProperties({type: props.conn.type, 'success': result.success}))
                  loadingTest.set(false)
                }}
              />
              :
              <Button
                id={`edit-connection-${props.conn.name.toLowerCase()}`}
                disabled={ !sling.userAtLeastAdmin }
                className='ml-1'
                icon="pi pi-pencil"
                  onClick={(e) => {
                  props.currConn.set(new Connection(jsonClone(props.conn)))
                  props.show.set(true)
                }}
              />
            }
          </div>
        </div>
        <div style={{ margin: '0 0 0 58px' }}>
          <span className="text-500 font-medium">Status: </span>
          {
            connSpec.kind === ConnKind.Unknown ?
              <span className="text-800 text-orange-500 font-medium">Unsupported</span> :
            props.conn.connectivity || sling.state.project.get().is_self_hosted ?
              <span className="text-800 text-green-500 font-medium">OK</span> :
              <span className="text-800 text-orange-500 font-medium">Unsuccessful</span>
          }
        </div>
      </div>
    </>
  )
}


const CardConnectionNew: React.FC<{show: State<boolean> }> = (props) => {
  return (
    <>
      <div className="card mb-0">
        <div className="flex justify-content-between mb-3" style={{paddingBottom: '11px'}}>
          <div className="flex align-items-center">
            <span className="block font-medium text-xl">
            <i>  ADD NEW CONNECTION</i> </span>
          </div>
          <div
            className="flex align-items-center justify-content-center border-round"
          >
            <Button
              disabled={ !sling.userAtLeastAdmin }
              className="p-button-success"
              icon="pi pi-plus"
              onClick={(e) => {
                if(sling.userAtLeastAdmin) props.show.set(true)
              }}
            />
          </div>
        </div>
        <br />
      </div>
    </>
  )
}