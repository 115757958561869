import { sling } from "../App";
import { ObjectString, ObjectAny } from "../utilities/interfaces";
import { flattenObj, jsonClone } from "../utilities/methods";
import { ColRecord } from "./schemata";


export interface ConnSettings {}

export enum ConnKind {
  Database = "database",
  File = "file",
  API = "api",
  Airbyte = "airbyte",
  Unknown = 'unknown',
}

export enum ConnType {
  FileLocal = "file",
  FileHDFS = "hdfs",
  FileS3 = "s3",
  FileAzure = "azure",
  FileGoogle = "gs",
  FileSftp = "sftp",
  FileHTTP = "http",

  DbPostgres = "postgres",
  DbRedshift = "redshift",
  DbMySQL = "mysql",
  DbOracle = "oracle",
  DbBigQuery = "bigquery",
  DbBigTable = "bigtable",
  DbSnowflake = "snowflake",
  Clickhouse = "clickhouse",
  DbSQLite = "sqlite",
  DbSQLServer = "sqlserver",
  DbAzure = "azuresql",
  DbAzureDWH = "azuredwh",
}

export type ConnectionMap = { [key: string]: Connection; }

// database: schema name to table names
// storage: folder path to file names
// api: object names
export type ConnectionSchemata = { [key: string]: string[] }

// a cache of object columns: object key to columnReco
export type ConnectionColumns = { [key: string]: ColRecord[] }

export class Connection {
  id: string;
  name: string;
  type: ConnType;
  kind: ConnKind;
  // quote: string;
  connectivity: boolean;
  data: ObjectString;
  updated_dt: number
  schemata: ConnectionSchemata
  columns: ConnectionColumns
  settings: ConnSettings

  schemas: string[]
  folder: string

  constructor(data: ObjectAny = {}) {
    this.id = data.id;
    this.type = data.type || undefined;
    this.kind = data.kind || null;
    // this.quote = data.quote || '';
    this.connectivity = data.connectivity || false;
    this.name = data.name || "";
    this.schemas = data.schemas || []
    this.folder = data.folder || "";
    this.updated_dt = new Date(Date.parse(data.updated_dt)).getTime() / 1000
    this.schemata = data.schemata || {}
    this.columns = data.columns || {}
    this.settings = data.settings || {}
    this.data = flattenObj(data.data || {}, '');
  }

  get is_self_hosted() {
	  // self hosted conns ID is a MD5, which is 32 chars
    return this.id?.length === 32 || false
  }

  get is_cloud() {
	  // cloud conns ID is a uuid, which is 36 chars
    return this.id?.length === 36 || false
  }

  payload() {
    let data: ObjectAny = {}
    for (let key of Object.keys(this.data)) {
      if (key === 'name') { continue }
      if (key === 'url') { continue }
      if (key.includes('.')) {
        let key_arr = key.split('.')
        for (let i = 0; i < key_arr.length; i++) {
          const key_part = key_arr[i];
          if(i === 0) {
            if(!(key_part in data)) data[key_part] = {}
          } else if(i === 1) {
            if(key_arr.length === 2) data[key_arr[i-1]][key_part] = this.data[key]
            else if(!(key_part in data[key_arr[i-1]])) data[key_arr[i-1]][key_part] = {}
          } else if(i === 2) {
            data[key_arr[i-2]][key_arr[i-1]][key_part] = this.data[key]
          }
        }
        continue
      }
      data[key] = this.data[key]
    }
    return {
      id: this.id,
      name: this.data.name || this.name,
      data: data,
      type: this.type,
      connectivity: this.connectivity,
      schemata: this.schemata,
      settings: this.settings,
    }
  }

  get is_database() { return this.kind === ConnKind.Database }
  get is_file() { return this.kind === ConnKind.File }
  get is_api() { return this.kind === ConnKind.API }
  get is_airbyte() { return this.kind === ConnKind.Airbyte }
  get is_writable() { return (this.is_database || this.is_file) && this.type !== ConnType.DbBigTable && this.type !== ConnType.DbSQLite }
  get url() { return this.data.url }

  get quote_char() {
    let q = '"'
    if(this.type === ConnType.DbBigQuery) q = '`'
    if(this.type === ConnType.DbMySQL) q = '`'
    if(this.type === ConnType.DbBigTable) q = ''
    return q
  }

  parseTableName = (text: string) : Table => {
    let table = {
      name: '',
      schema: '',
      database: '',
      sql: '',
      dialect: this.type
    }

    let quote = this.quote_char

    let defCaseUpper = this.type === ConnType.DbOracle || this.type === ConnType.DbSnowflake

    let inQuote = false
    let words : string[] = []
    let word = ""

    let addWord = () => {
      if(word === ""){
        return
      }
      words.push(word)
      word = ""
    }

    for (let c of text) {
      switch (c) {
      case quote:
        if(inQuote) {
          addWord()
        }
        inQuote = !inQuote
        continue
      case ".":
        if(!inQuote){
          addWord()
          continue
        }
      case " ": // eslint-disable-line
      case "\n": // eslint-disable-line
      case "\t": // eslint-disable-line
      case "\r": // eslint-disable-line
      case "(": // eslint-disable-line
      case ")": // eslint-disable-line
      case "-": // eslint-disable-line
      case "'":
        if(!inQuote) {
          table.sql = text.trim()
          return table
        }
        break;
      }

      if(inQuote) {
        word = word + c
      } else {
        word = word + (defCaseUpper ? c.toUpperCase() : c)
      }
    }

    if(inQuote) {
      throw new Error("unterminated qualifier quote")
    } else if(word !== "") {
      addWord()
    }

    if(words.length === 0) {
      throw  new Error("invalid table name")
    } else if(words.length === 1) {
      table.name = words[0]
    } else if(words.length === 2) {
      table.schema = words[0]
      table.name = words[1]
    } else if(words.length === 3) {
      table.database = words[0]
      table.schema = words[1]
      table.name = words[2]
    } else {
      table.sql = text.trim()
    }

    return table
  }

  // add quote when needed
  make_key_identifier(schema: string, table: string) {
    let quote = this.quote_char
    let defCaseUpper = this.type === ConnType.DbOracle || this.type === ConnType.DbSnowflake

    const make_identifier = (word: string) => {
      let add_quotes = false
      for (let c of word) {
        if(/^\d+$/.test(c) || c === "_") continue
        if(defCaseUpper && c === c.toLowerCase()) add_quotes = true
        if(!defCaseUpper && c === c.toUpperCase()) add_quotes = true
      }
      if(add_quotes) word = quote + word + quote
      return word
    }
    schema = make_identifier(schema)
    table = make_identifier(table)
    return `${schema}.${table}`
  }
}

export const blankConnSpec = (type = ''): ConnSpec => {
  let kind = ConnKind.Unknown
  if(type === 'local') kind = ConnKind.File
  return {
      key: type as ConnType,
      kind: kind,
      title: type,
      icon: 'local.png',
      form: [],
      required: [],
      properties: {},
    }
}

export interface ConnSpec {
  key: ConnType
  title: string
  kind: ConnKind
  icon: string
  documentation?: string
  form: string[][]
  required: string[]
  properties: { [key: string]: SpecProp; }
  read_only?: boolean
}

export interface SpecProp {
  key: string
  options: string[]
  type: string
  format: string
  title: string
  description: string
  default: any
  secret: boolean
  ref_url?: string
}

export type ConnSpecs = { [key: string]: ConnSpec; }

export const get_conn_quote = (conn_type: string) => {
  let mapping: ObjectString = {
    postgres: '"',
    redshift: '"',
    azuresql: '"',
    sqlserver: '"',
    mysql: '`',
    oracle: '"',
    bigquery: '',
  }
  return mapping[conn_type]
}

export const connRef = (conn_id: string): Connection => {
  if (sling.state.connections.keys.includes(conn_id)) {
    return sling.state.connections[conn_id].get()
  }
  // if(conn_name) toastError('Error', new Error('Unable to find connection: ' + conn_name))
  return new Connection()
}

export const orderConns = (conns: Connection[]) => {
  conns.sort((a, b) => {
    if (a.name < b.name) { return -1 }
    else return 1
  })
  return conns.map((c) => new Connection(jsonClone(c)))
}

export const getPrefix = (conn: Connection) => {
  let prefix = ''
  if ([ConnType.FileS3, ConnType.FileGoogle, ConnType.FileLocal].includes(conn.type)) {
    let bucket = conn.data['bucket'] || conn.data['bucket'] || ''
    prefix = `${conn.type}://${bucket}/`
  } else if (conn.type === ConnType.FileAzure) {
    let account = conn.data['account'] || ''
    let container = conn.data['container'] || ''
    prefix = `https://${account}.blob.core.windows.net/${container}/`
  }
  return prefix
}

export interface Table {
  name: string;
  schema: string;
  database: string;
  sql: string;
  dialect: ConnType;
}