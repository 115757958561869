
import { open } from '@tauri-apps/api/shell'
import { listen, EventName, EventCallback } from '@tauri-apps/api/event'

export const getClientMethods = async () => { 
  let isTauri = false
  try {
    const unlisten = await listen('tauri://update-status', event => {
      console.log(event)
    })
    isTauri = true
    unlisten()
  } catch (error) {
    
  }
  return new ClientMethods(isTauri)
}

/**
 * correctly run client methods that handle Tauri and browser ops
 */
export class ClientMethods {
  isTauri: boolean
  
  constructor(isTauri: boolean) {
    this.isTauri = isTauri
  }

  open(path: string) { 
    if (this.isTauri) open(path)
    else window.open(path, '_blank')?.focus()
  }

  async listen(event: EventName, handler: EventCallback<unknown>) { 
    if (this.isTauri) return await listen(event, handler)
  }
}
