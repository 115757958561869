import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker';
import { isDemo, isProd, isStaging } from './core/api/http';
import TagManager from 'react-gtm-module'
import * as rudderstack from "rudder-sdk-js";
import { ObjectAny } from './utilities/interfaces';

TagManager.initialize({
  gtmId: isDemo ? 'GTM-5D6R5TZ' : isProd ? 'GTM-T3KTL75' : '',
})

Sentry.init({
  dsn: "https://692bb84c8fbf4c41906e95378c085aef@o881232.ingest.sentry.io/6591577",
  integrations: [new BrowserTracing()],
  environment: isStaging ? 'staging' : isDemo ? 'demo' : isProd ? 'production' : 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

rudderstack.load("2HzQiv5fpzX6rrYgkBvK2sUVjqh","https://liveflarccszw.dataplane.rudderstack.com")

export { rudderstack };

ReactDOM.render(
  <>
    <App></App>
  </>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const rudderTrack = (category: string, event: string, data : ObjectAny) => {
  data['event_name'] = event
  rudderstack.track('track_' + category, data)
}