import _ from "lodash";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import * as React from "react";
import { rudderstack } from "..";
import { sling } from "../App";
import { HttpResponse } from "../core/api/http";
import { Route } from "../core/api/routes";
import { toBytes, toGB } from "../core/execution";
import { Plan, Status } from "../core/project";
import { UsageRecord } from "../core/stats";
import { useHookState } from "../core/store";
import { User } from "../core/user";
import { ObjectAny } from "../utilities/interfaces";
import { toastError } from "../utilities/methods";

interface Props {}

interface ItemRow {
  name: string;
  amount: number;
}

interface InvoiceRow {
  year_month: string;
  amount: number;
  url: string;
}

export const PlanBillingView: React.FC<Props> = (props) => {

  const users = useHookState<User[]>([])
  const usageRecord = useHookState<UsageRecord>({week_bytes: 0, month_bytes: 0, usage_bytes:0})
  const currentItems = useHookState<ItemRow[]>([])
  const pastInvoices = useHookState<InvoiceRow[]>([])
  const loading = useHookState(false)
  const amountDue = useHookState(0)
  const projectedAmountDue = useHookState(0)
  const newOrganization = {
    ref: React.useRef<OverlayPanel>(null),
    organization: useHookState(sling.state.project.organization),
    domain: useHookState(sling.state.project.domain),
    owner_email: useHookState(sling.state.project.owner_email),
  }

  ///////////////////////////  HOOKS  ///////////////////////////
  React.useEffect(() => { 
    document.title = 'Sling - Plan & Billing'
    rudderstack.page(document.title, '', sling.rudderProperties())
    getUsers()
    getCurrentItems()
    getPastInvoices()
  }, []); // eslint-disable-line


  ///////////////////////////  EFFECTS  ///////////////////////////
  ///////////////////////////  FUNCTIONS  ///////////////////////////
  const getUsers = async () => {
    loading.set(true)
    let data = await sling.loadUsers()
    users.set(_.orderBy(data, ['name'], ['asc']))
    loading.set(false)
  }

  const getUsage = async () => {
    usageRecord.set(await sling.getStats('usage', 'day', 1) as UsageRecord)
  }

  const getCurrentItems = async () => {
    await getUsage()
    if(sling.state.settings.app.plans.keys.length === 0)
      await sling.loadSettings('app')
    
    let project = sling.state.project.get()
    let usage_gbs = Math.round(toGB(usageRecord.usage_bytes.get() || 0) * 1000) / 1000
    let month_gbs = Math.round(toGB(usageRecord.month_bytes.get() || 0) * 1000) / 1000
    let plans = sling.state.settings.app.plans.get()
    let plan_details = plans[project.plan]
    if(!plan_details) return


    if(project.plan === Plan.CloudFree) {
      currentItems.set([
        {name: '1 x Free Plan Base (at $0.00 / month)', amount: 0},
        {name:`${month_gbs} GB × Free Plan Usage (at $0 / GB)`, amount: 0},
      ])
    } else if(project.plan === Plan.CloudStandard) {
      let base_amount = plan_details.base_product.unit_price
      let per_gb = plan_details.usage_product.unit_price
      usage_gbs = (usage_gbs > plan_details.package_gb) ? (usage_gbs - plan_details.package_gb) : 0
      let usage_amount = Math.round(usage_gbs * per_gb * 100) / 100
      currentItems.set([
        { name: `1 x Standard Plan [${plan_details.package_gb} GB] (at $${base_amount}.00 / month)`, amount: base_amount },
        { name:`${Math.floor(usage_gbs)} GB × Supplemental Usage (at $${per_gb} / GB)`, amount: usage_amount },
      ])
      amountDue.set(currentItems.get().map(i => i.amount)?.reduce((a, b) => a+b, 0))

      if(project.sub_period_start && project.sub_period_end) {
        let now = Math.floor(new Date().getTime() / 1000)
        if(now < project.sub_period_start) return
        let rate = usage_gbs / (now - project.sub_period_start)
        let projected_gbs = rate * (project.sub_period_end - project.sub_period_start)
        projected_gbs = (projected_gbs > plan_details.package_gb) ? (projected_gbs - plan_details.package_gb) : 0
        let amounts = [
          base_amount,
          Math.round(projected_gbs * per_gb * 100) / 100,
        ]
        projectedAmountDue.set(amounts.reduce((a, b) => a+b, 0))
      }
    }
  }

  const getPastInvoices = () => {
    // pastInvoices.set([
    //   {year_month: 'June 2022', amount: 16.06, url: 'https://slingdata.io' },
    //   {year_month: 'May 2022', amount: 17.76, url: 'https://slingdata.io' },
    // ])
  }

  ///////////////////////////  JSX  ///////////////////////////


  const goStripe = async () => {
    let project = sling.state.project
    let data : ObjectAny = {
      success_url: window.location.href,
      cancel_url: window.location.href,
      return_url: window.location.href,
      customer_name: project.organization.get(),
      customer_email: project.owner_email.get(),
    }

    let response : HttpResponse
    if(!project.stripe_customer_id.get()) {
      response = await sling.api.Post(Route.StripeCreateCustomer, data)
      if(response.error) return toastError("Error encountered", response.error)
      project.stripe_customer_id.set(response.data.customer_id)
      data.customer_id = response.data.customer_id
    }

    if(project.plan.get() === Plan.CloudFree) {
      data.plan = Plan.CloudStandard
      response = await sling.api.Post(Route.StripeCreateCheckoutSession, data)
      if(response.error) return toastError("Error encountered", response.error)
      if(response.data?.url) return window.location.assign(response.data.url)
    }

    if(project.plan.get() === Plan.SelfHostedFree) {
      data.plan = Plan.SelfHosted
      response = await sling.api.Post(Route.StripeCreateCheckoutSession, data)
      if(response.error) return toastError("Error encountered", response.error)
      if(response.data?.url) return window.location.assign(response.data.url)
    }

    response = await sling.api.Post(Route.StripeCustomerPortal, data)
    if(response.error) return toastError("Error encountered", response.error)
    if(response.data?.url) return window.location.assign(response.data.url)
  }


  const OrganizationDialog = <OverlayPanel
    ref={newOrganization.ref}
    showCloseIcon
    dismissable>

    <p><strong>What is your Organization's name?</strong></p>
    <p>
      <InputText
        id='organization-name'
        type="text"
        placeholder="My Company"
        value={newOrganization.organization.get()}
        onInput={(e:any) => { 
          newOrganization.organization.set(e.target.value as string) 
        }}
        style={{width: '100%'}}
      />
    </p>

    {/* <p><strong>What is your Organization's Domain?</strong></p>
    <p>
      <InputText
        id='organization-domain'
        type="text"
        placeholder="company.com"
        value={ newOrganization.domain.get() }
        onInput={(e:any) => {
          let domain = e.target.value as string
          newOrganization.domain.set(domain.trim())
        }}
        style={{width: '100%'}}
      />
    </p> */}

    <p><strong>Who owns this workspace (email)?</strong></p>
    <p>
      <Dropdown
        id='organization-owner'
        placeholder="select Role"
        value={ newOrganization.owner_email.get()}
        options={ users.get().filter(u => u.is_admin).map(u => u.email) }
        onChange={(e) => { newOrganization.owner_email.set(e.target.value) }}
        style={{width: '100%'}}
      />
    </p>

    <Button
      label="OK"
      className="ml-2"
      onClick={async (e) => {
        // if(!(/^\S+\.\S+$/.test(newOrganization.domain.get()))) return toastError(`Invalid Domain`)
        if(!(/^\S+@\S+\.\S+$/.test(newOrganization.owner_email.get()))) return toastError(`Invalid Email`)
        if(newOrganization.organization.get().length < 4) return toastError('Name is too short')
        
        sling.state.project.set(
          proj => {
            proj.organization = newOrganization.organization.get()
            proj.domain = newOrganization.domain.get()
            return proj
          }
        )

        if(!await sling.saveProject(sling.state.project.get())) {
          return toastError(`Error updating Project`)
        }
        newOrganization.ref.current?.hide()
        goStripe()
      }}
    />
  </OverlayPanel>


  return (
    <div
      id="notifications-panel"
      className="grid"
    >
      {OrganizationDialog}
      {/* HEADER */}
      <div className="col-4 justify-content-start">
      </div>
      <div className="col-4">
        <h2 style={{ textAlign: "center", marginBottom: 0 }}> {'PLAN & BILLING'}</h2>
      </div>
      <div className="col-4 flex justify-content-end"></div>

    <div
      className="grid"
      style={{
        paddingTop: '10px',
        maxHeight: `${window.innerHeight - 270}px`,
        width: '100%',
        overflowY: 'scroll',
      }}
      >
        <div className="col-1"/>
        <div className="col-5">
          <div className="card">
            <div>
              <h4>Plan</h4>

              <div className="plan-table-row">
                <div className="grid">
                  <div className="col">Account Status:</div>
                  <div className="col" style={{ fontWeight: 'bold' }}>
                    <span 
                      className="status"
                      style={{
                        // color: '#c63737',
                        color: sling.state.project.status.get() === Status.OK ? 'green' : 'red',
                      }}
                    >
                      { sling.state.project.status.get().replace('-', ' ') }
                    </span>
                  </div>
                </div>
              </div>

              <div className="plan-table-row">
                <div className="grid">
                  <div className="col">Current Plan:</div>
                  <div className="col" style={{ fontWeight: 'bold' }}>
                    <span className="status" style={{ color: 'blue' }}>
                      { sling.state.project.plan.get() }
                    </span>
                  </div>
                </div>
              </div>

              {
                sling.state.project.plan.get() === Plan.CloudFree ?
                <div className="plan-table-row">
                  <div className="grid">
                    <div className="col"> Free Weekly Usage:</div>
                    <div className="col" style={{ fontWeight: 'bold' }}>
                      <span 
                        className="status"
                        style={{ 
                          color: toGB(usageRecord.week_bytes.get()) > sling.state.settings.app.free_weekly_gb_limit.get() ? 'red' : 'grey'
                        }}
                      >
                        { toBytes(usageRecord.week_bytes.get()) } / { sling.state.settings.app.free_weekly_gb_limit.get() } GB
                        ({ Math.round(100.0 * toGB(usageRecord.week_bytes.get()) / sling.state.settings.app.free_weekly_gb_limit.get()) }%)
                      </span>
                    </div>
                  </div>
                </div>
                :
                null
              }

              <p
                className="flex justify-content-center"
                style={{
                  paddingTop: '10px',
                }}
              >
                <Button
                  id='manage-billing'
                  icon='pi pi-credit-card'
                  disabled={!sling.userAtLeastAdmin}
                  tooltipOptions={{ position: 'right' }}
                  label={ sling.state.project.plan.get() === Plan.CloudFree ? 'Upgrade Plan' : 'Manage Plan & Billing' }
                  className="p-button-success"
                  onClick={async (e) => { 
                    if(!newOrganization.organization.get()) {
                      newOrganization.ref.current?.toggle(e)
                    } else {
                      goStripe()
                    }
                  }}
                />

              </p>
            </div>

            <div 
              style={{
                marginTop: '50px',
              }}
            >
              <h4>Current Usage ({new Date().toLocaleString('en-us',{month:'long', year:'numeric'})})</h4>

              <div className="plan-table-row">
                <div className="grid" style={{ fontWeight: 'bold' }}>
                  <div className="col-10">
                    <strong>Item</strong>
                  </div>
                  <div className="col-2">
                    <strong>Amount</strong>
                  </div>
                </div>
              </div>

              {
                currentItems.get().map(
                  item => {
                    return (
                      <div className="plan-table-row">
                        <div className="grid">
                          <div className="col-10">{ item.name }</div>
                          <div className="col-2" style={{ fontFamily: 'monospace' }}>
                            { new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(item.amount) }
                          </div>
                        </div>
                      </div>
                    )
                  }
                )
              }

              <div className="plan-table-row">
                <div className="grid" style={{ fontWeight: 'bold' }}>
                  <div className="col-10">
                    <strong>Amount Due</strong>
                    <span> (preview)</span>
                  </div>
                  <div className="col-2" style={{ fontFamily: 'monospace' }}>
                    { new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(amountDue.get()) }
                  </div>
                </div>
              </div>
              { 
                projectedAmountDue.get() > 0 && amountDue.get() !== projectedAmountDue.get() ?
                <div className="plan-table-row">
                  <div className="grid" style={{ fontWeight: 'bold' }}>
                    <div className="col-10">
                      <strong>Projected</strong>
                      <span> (preview)</span>
                    </div>
                    <div className="col-2" style={{ fontFamily: 'monospace' }}>
                      { new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(projectedAmountDue.get()) }
                    </div>
                  </div>
                </div>
               : null
              }

            </div>
          </div>
        </div>

        <div className="col-5">
          <div className="card">
            <div>
              <h4>Past Invoices</h4>
              {
                pastInvoices.length ?
                pastInvoices.get().map(
                  inv => {
                    return (
                      <div className="plan-table-row">
                        <div className="grid">
                          <div className="col-1">
                            <i className="pi pi-check"></i>
                          </div>
                          <div className="col-6">{ inv.year_month }</div>
                          <div className="col-3">
                            <a href={ inv.url } target="_blank" rel="noopener noreferrer">
                              <span className="mr-2">View</span>
                              <i className="pi pi-external-link"></i>
                            </a>
                          </div>
                          <div className="col-1">
                          </div>
                        </div>
                      </div>
                    )
                  }
                )
                :
                  <div className="plan-table-row">
                    <div className="grid">
                      <div className="col-3">
                      </div>
                      <div className="col-6">
                      <i>No existing invoices</i> 
                      </div>
                      <div className="col-3">
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="col-1"/>
      </div>
    </div>
  )
};
