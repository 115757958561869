import { ObjectAny, ObjectString } from "../utilities/interfaces"
import { alphanumeric, rand_str, toastError } from "../utilities/methods"
import { Connection, ConnKind, connRef, ConnType } from "./connection"
import { ExecStatus, Execution } from "./execution"

export type JobMap = { [key: number]: Job; }

export enum JobType {
  APIToDb = "api-db",
  APIToFile = "api-file",
  ConnTest = "conn-test",
  DbToDb = "db-db",
  FileToDB = "file-db",
  DbToFile = "db-file",
  FileToFile = "file-file",
  DbSQL = "db-sql",
  DbDbt = "db-dbt",
}

export interface SourceOptions {
  trim_space?: boolean,
  empty_as_null?: boolean,
  header?: boolean,
  flatten?: boolean,
  compression?: Compression,
  format?: Format,
  null_if?: string,
  datetime_format?: string,
  skip_blank_lines?: boolean,
  delimiter?: string,
  max_decimals?: number,
  jmespath?: string,
}

export enum Compression {
  Auto = 'auto',
  Gzip = 'gzip',
  Zstd = 'zstd',
}

export enum Format {
  Auto = 'auto',
  Json = 'json',
  JsonLines = 'jsonlines',
  Csv = 'csv',
}

export const DefaultSourceOptionsFile = {
  trim_space: false,
  empty_as_null: true,
  header: true,
  flatten: false,
  compression: Compression.Auto,
  format: Format.Auto,
  datetime_format: 'auto',
  jmespath: '',
  delimiter: ',',
  skip_blank_lines: true,
}


export interface TargetOptions {
  header?: boolean,
  compression?: Compression,
  format?: Format,
  concurrency?: number,
  datetime_format?: string,
  delimiter?: string,
  file_max_rows?: number,
  file_max_bytes?: number,
  max_decimals?: number,
  table_ddl?: string,
  table_tmp?: string,
  pre_sql?: string,
  post_sql?: string,
  use_bulk?: boolean,
  add_new_columns?: boolean,
  adjust_column_type?: boolean,
}

export const DefaultTargetOptionsFile = {
  header: true,
  format: Format.Csv,
  compression: Compression.Gzip,
  datetime_format: 'auto',
  delimiter: ',',
  use_bulk: true,
}

export const GenTmpTable = (tgt_table: string): string  => {
  return (tgt_table.length > 24 ? tgt_table.slice(0,24): tgt_table) + '_tmp' + rand_str(alphanumeric, 2)
}

export const DefaultTargetOptionsDB = {
  use_bulk: true,
  add_new_columns: true,
}



export enum JobMode {
  FullRefreshMode = "full-refresh",
  IncrementalMode = "incremental",
  // AppendMode = "append",
  TruncateMode = "truncate",
  SnapshotMode = "snapshot",
}

export const jobModeDescription = (jm : JobMode | undefined) => {
  if(jm === JobMode.FullRefreshMode) 
    return 'Drops the table on the target system (if it exists) and inserts the full dataset. If no table exists, it will be created'
  if(jm === JobMode.IncrementalMode) 
    return 'Synch only new data using selected keys. If no table exists, it will be created'
  // if(jm === JobMode.AppendMode) 
  //   return 'Only inserts data. If keys are selected, it will only insert new data. If not, the full data will be inserted each run'
  if(jm === JobMode.TruncateMode) 
    return 'Truncate the table on the target system (if it exists) and inserts the full dataset. If no table exists, it will be created'
  if(jm === JobMode.SnapshotMode) 
    return 'Each time the task is ran, the full dataset is inserted with a new loaded_at date. This allows you to view the dataset at a snapshot point in history.'
  return ''
}

export const filterMode = (tgt_kind: ConnKind, mode: JobMode) : boolean => {
  if(tgt_kind === ConnKind.File) {
    return [JobMode.FullRefreshMode].includes(mode)
  }
  return true
}

export interface JobConfig {
  source: {
    conn: string;
    stream?: string;
    primary_key?: string[];
    update_key?: string;
    limit?: number;
    data?: ObjectString;
    options: SourceOptions;
  };

  target: {
    conn: string;
    object?: string;
    data?: ObjectString;
    options: TargetOptions;
  };

  mode: JobMode;
}

export interface JobSettings {
  columns: string[],
  tags?: string[],
  region?: string,
  retries: number,
  retry_delay: number,
  watermark_date?: Date,
  files_ingested?: string[],
}

export const DefaultJobSettings : JobSettings = {
  columns: [],
  tags: [],
  retries: 2,
  retry_delay: 30,
}

export interface JobStats {
  total_execs: number;
  total_bytes: number;
  total_rows: number;
  successful_execs: number;
  successful_duration: number;
  recent_execs: any[][]  // status;duration;rows;epoch
}

export interface JobParams {
  id?: number;
  name: string;
  active: boolean;
  schedule?: string;
  config: JobConfig;
  type: JobType;
  settings?: JobSettings;
  stats?: JobStats;
  data?: ObjectAny;
}

export class Job implements JobParams {
  id?: number;
  replication_id: number;
  name: string;
  active: boolean;
  schedule?: string;
  config: JobConfig;
  type: JobType;
  settings?: JobSettings;
  stats?: JobStats;
  updated_dt: Date
  last_execution_time: number
  next_execution_time: number
  data: ObjectAny;

  constructor(data: ObjectAny = {} as JobParams) {
    this.id = data.id || undefined;
    this.replication_id = data.replication_id || undefined;
    this.name = data.name || "";
    this.active = data.active || false;
    this.schedule = data.schedule || '';
    this.config = data.config || {source:{}, target: {}};
    this.type = data.type || getJobType(this.config.source.conn, this.config.target.conn)
    this.settings = data.settings || DefaultJobSettings;
    this.stats = (data.stats || {}) as JobStats;
    this.updated_dt = new Date(Date.parse(data.updated_dt))
    this.last_execution_time = data.last_execution_time
    this.next_execution_time = data.next_execution_time

    this.data = data.data || {
      group: `${data.src_conn}-${data.tgt_conn}`,
      src_conn: data.src_conn,
      tgt_conn: data.tgt_conn,
      // last/current executions
      start_time: data.last_execution_start_time,
      end_time: data.last_execution_end_time,
      duration: data.last_execution_duration,
      status: data.last_execution_status,
      rows: data.last_execution_rows,
      bytes: data.last_execution_bytes,
    };
  }
  get last_execution() {
    return new Execution({
      start_time: this.data.start_time as number,
      end_time: this.data.end_time as number,
      duration: this.data.duration as number,
      status: this.data.status as ExecStatus,
      rows: (this.data.rows || 0) as number,
      bytes: (this.data.bytes || 0) as number,
    })
  }
  get next_execution_date() {
    if(this.next_execution_time) return new Date(this.next_execution_time * 1000)
    return undefined
  }
  get next_execution_date_str() { return this.next_execution_date?.toLocaleString() }
  get source() { return connRef(this.config.source?.conn) }
  get target() { return connRef(this.config.target?.conn) }
  get src_conn() { return this.config.source?.conn }
  get tgt_conn() { return this.config.target?.conn }
  get tgt_name() { return this.config.target?.object }
  get mode() { return this.config.mode }
  get src_stream() {
    // can be a file path, api object or a db table
    let source = connRef(this.config.source.conn)
    if(source?.is_database) return this.config.source?.stream
    else if (source?.is_file) return this.config.source?.stream
    else if (source?.is_api) return this.config.source?.stream
    return ''
  }
  get tgt_object() {
    // can be a file path or a db table
    let target = connRef(this.config.target.conn)
    if(target?.is_database) return this.config.target?.object
    else if (target?.is_file) return this.config.target?.object
    return undefined
  }
  get avg_duration() {
    let successful_duration = this.stats?.successful_duration || 0
    let successful_execs = this.stats?.successful_execs || 0
    if(successful_execs === 0) return 0
    return Math.round(successful_duration / successful_execs)
  }
  get success_rate() {
    let successful_execs = this.stats?.successful_execs || 0
    let total_execs = this.stats?.total_execs || 0
    if(total_execs === 0) return '-'
    return `${Math.round(successful_execs / total_execs * 100 * 10) / 10}%`
  }
}

export const getJobType = (source: Connection|string, target: Connection|string) : JobType => {
  let s : ConnKind
  let t : ConnKind 

  if(source?.hasOwnProperty('kind')) { 
    s = (source as Connection).kind 
  } else { 
    s = connRef(source as string)?.kind 
  }

  if(target?.hasOwnProperty('kind')) { 
    t = (target as Connection).kind 
  } else { 
    t = connRef(target as string)?.kind 
  }

  if(s === ConnKind.Database && t === ConnKind.Database) {
    return JobType.DbToDb
  }
  if(s === ConnKind.Database && t === ConnKind.File) {
    return JobType.DbToFile
  }
  if(s === ConnKind.File && t === ConnKind.Database) {
    return JobType.FileToDB
  }
  if(s === ConnKind.File && t === ConnKind.File) {
    return JobType.FileToFile
  }
  if((s === ConnKind.Airbyte || s === ConnKind.API) && t === ConnKind.File) {
    return JobType.APIToFile
  }
  if((s === ConnKind.Airbyte || s === ConnKind.API) && t === ConnKind.Database) {
    return JobType.APIToDb
  }
  return '' as JobType
}

export const validateJob = (job: Job) => {
    const fail = (reason: string) => {
      toastError(`Validation Failure: ${job.name}`, reason)
      return false
    }
    
    if(!job.name.trim()) { return fail('Need to specify Name') }
    if(!job.tgt_conn) { return fail('Need to specify Connection') }

    if(!job.src_conn) { return fail('Need to specify Source Connection') }
    if(!job.tgt_name) { return fail('Need to specify Target Name') }
    if(job.target.kind === ConnKind.Database) {
      if(!job.mode) { return fail('Need to specify Load Mode') }
    }
    if(job.target.kind === ConnKind.Database) {
      if(job.config.mode === JobMode.IncrementalMode){
        if(!job.config.source.primary_key || !job.config.source.update_key) {
          return fail("For incremental mode, need to select Primary Key and Update Key")
        }
        if(job.source.kind === ConnKind.Database &&
          !job.config.source.stream?.toLowerCase().includes('{incremental_where_cond}')) {
            return fail("For incremental loading, need to include where clause placeholder {incremental_where_cond}. e.g: select * from my_table where col2='A' AND {incremental_where_cond}")
        }
      }
      if(job.target.type === ConnType.DbOracle && job.tgt_name && job.tgt_name.length > 30) {
        return fail(`Target table name '${job.tgt_name}' is too long for Oracle (max is 30 characters)`)
      }
    }
    if (job.source.kind === ConnKind.File) {
      let src_str = job.config.source.stream
      let prefix = job.source.data.url+'/'
      if(!src_str?.startsWith(prefix)) return fail(`Source file must start with '${prefix}'`)
    }
    if (job.target.kind === ConnKind.File) {
      let tgt_obj = job.config.target.object
      let prefix = job.target.data.url+'/'
      if(!tgt_obj?.startsWith(prefix)) return fail(`Target name must start with '${prefix}'`)
    }

    return true
  }